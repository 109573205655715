.promotions {

  .ant-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 82vh;
  }

  .ant-list [class*="ant-col-"] .ant-list-item {
    width: auto;
  }

  &--set-width .ant-col-12 {
    //width: auto;
    max-width: 50%;
    max-height: 50%;
    & .ant-list-item {
      &, & .ant-list-item-content {
        &, & .ant-card-link {
          &, & .promotion {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}


.promotions .ant-col-12 .ant-list-item {
  padding: 0 2px;
  margin-bottom: 4px;
}
@media screen and (min-width: @screen-xl) {
  .promotions .ant-col-12 .ant-list-item {
    padding: 0 8px;
    margin-bottom: 16px;
  }
}

.promotion {
  min-height: 12rem;

  .ant-card-meta-title {
    white-space: normal;
  }

  .ant-card-meta-description,
  .read-more {
    font-size: @font-size-sm;
    line-height: 1.2857;
  }

  .read-more {
    margin-top: 12/@px;
    font-weight: @font-weight-medium;
    text-transform: uppercase;
    color: @active-color;
  }

  &-custom {
    .ant-card-body {
      padding: 0;
    }
    .ant-card-meta {
      margin: 0;
    }
  }

  &__item {
    &--loading,
    &--error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__card-link {
    transition: background-color 200ms ease;
    &:hover, &:active, &:focus {
      color: white;
      text-decoration: none;
    }
    &:focus {
      background-color: rgba(0,0,0,0.25);
    }

    &:hover .promotion__more-link,
    &:focus .promotion__more-link,
    & .promotion__more-link:hover {
      text-decoration: underline;
    }
  }

  .ant-card-body,
  .ant-card-meta,
  .ant-card-meta-detail,
  .ant-card-meta-description,
  &__card-link,
  &__item,
  &__item:not(&__item--loading):not(&__item--error) > * {
    height: 100%;
  }

  &--centered {
    .ant-card-body,
    .ant-card-meta,
    .ant-card-meta-detail,
    .ant-card-meta-description {
      height: 100%;
    }
  }
}
