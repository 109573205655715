.ant-card {
  &,
  &-head-title {
    color: inherit;
  }

  &-head {
    padding: 0 18/@px;
  }

  &-body {
    padding: 18/@px;
  }

  &-head {
    border: 0;

    & + .ant-card-body {
      padding-top: 0;
    }
  }

  &-head-title {
    padding-top: 16/@px;
    padding-bottom: 10/@px;

    h2 {
      font-size: @font-size-h3;
      font-weight: 500;
    }
  }

  &-meta-title,
  &-meta-description {
    color: inherit;
  }

  &-link {

    &,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  // Dark theme

  &-dark {
    color: #fff;
    background: rgba(19, 33, 58, 0.39);
  }
}
