.ant-collapse {
  background: transparent;
  border-radius: 0;
  border: 0;

  &-content {
    font-size: 16/@px;
  }

  & > .ant-collapse-item {
    border-bottom-color: @body-background;

    & > .ant-collapse-header {
      padding-top: 15/@px;
      padding-bottom: 15/@px;
      font-size: @font-size-h3;
    }

    &.ant-collapse-no-arrow {

      .ant-collapse-header {
        position: relative;
        padding-left: 53/@px;

        &:before {
          .icon();
          content: @icon-arrow;
          display: block;
          position: absolute;
          left: @content-margin-sm;
          width: 24/@px;
          height: 24/@px;
          font-size: 24/@px;
          transform: rotate(90deg);
        }

        &[aria-expanded="true"]:before {
          transform: rotate(-90deg);
        }

      }

    }

    &--invisible-header {
      background: transparent;

      & > .ant-collapse-header {
        .sr-only();
      }

      .ant-collapse-content {
        background: transparent;
        color: #fff;
        border: 0;

        & > .ant-collapse-content-box {
          padding: @content-margin-base;
          padding-left: 53/@px;
          line-height: 1.375;
        }
      }

    }
  }

  .ant-collapse-item:last-child {
    &,
    .ant-collapse-content {
      border-radius: 0;
    }
  }

}
