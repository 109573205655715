@import "roboto.css";
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
@font-face {
  font-family: "Chinese Quote";
  src: local("PingFang SC"), local("SimSun");
  unicode-range: U+2018, U+2019, U+201c, U+201d;
}
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  background-color: #234982;
}
[tabindex="-1"]:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: inherit;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:focus {
  text-decoration: underline;
  text-decoration-skip: ink;
}
a:hover {
  color: #b6daff;
}
a:active {
  color: #d9cccc;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: underline;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  background: #1f3e6f;
  color: #fff;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon svg {
  display: block;
}
.anticon:before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon-spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0%);
  }
}
@keyframes antMoveRightOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating],
[ant-click-animating-without-extra-node] {
  position: relative;
}
[ant-click-animating-without-extra-node]:after,
.ant-click-animating-node {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: inherit;
  border: 0 solid #1f3e6f;
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  display: block;
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes antSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
}
@keyframes antSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}
@keyframes antSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }
}
@keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}
@keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
}
@keyframes antSlideRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
}
@keyframes antSlideRightOut {
  0% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
}
.swing-enter,
.swing-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  animation-name: antSwingIn;
  animation-play-state: running;
}
@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}
@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes antZoomUpIn {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
}
@keyframes antZoomUpOut {
  0% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8);
  }
}
@keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
}
@keyframes antZoomRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}
@keyframes antZoomRightOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
}
@keyframes antZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}
@keyframes antZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8);
  }
}
.ant-motion-collapse {
  overflow: hidden;
}
.ant-motion-collapse-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding: 8px 15px 8px 37px;
  border-radius: 4px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert-icon {
  top: 7.6425px;
  left: 16px;
  position: absolute;
  font-size: 3rem;
}
.ant-alert-description {
  font-size: 1rem;
  line-height: 22px;
  display: none;
}
.ant-alert-success {
  border: 1px solid #bcd6c1;
  background-color: #e1f0e3;
}
.ant-alert-success .ant-alert-icon {
  color: #46b063;
}
.ant-alert-info {
  border: 1px solid #f0f9ff;
  background-color: #f0faff;
}
.ant-alert-info .ant-alert-icon {
  color: #b6daff;
}
.ant-alert-warning {
  border: 1px solid #ffe58f;
  background-color: #fffbe6;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  border: 1px solid #e6866e;
  background-color: #ffece6;
}
.ant-alert-error .ant-alert-icon {
  color: #c01300;
}
.ant-alert-close-icon {
  font-size: 0.875rem;
  position: absolute;
  right: 16px;
  top: 8px;
  line-height: 22px;
  overflow: hidden;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: #404040;
}
.ant-alert-close-text {
  position: absolute;
  right: 16px;
}
.ant-alert-with-description {
  padding: 15px 15px 15px 64px;
  position: relative;
  border-radius: 4px;
  color: #fff;
  line-height: 1.285;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 14px;
  left: 24px;
  font-size: 26px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 1rem;
}
.ant-alert-with-description .ant-alert-message {
  font-size: 1.312rem;
  color: inherit;
  display: block;
  margin-bottom: 4px;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-close {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transform-origin: 50% 0;
}
.ant-alert-slide-up-leave {
  animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
.ant-alert-banner {
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
}
@keyframes antAlertSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
.ant-anchor {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  background-color: #fff;
  overflow: auto;
  padding-left: 4px;
  margin-left: -4px;
}
.ant-anchor-ink {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}
.ant-anchor-ink:before {
  content: ' ';
  position: relative;
  width: 2px;
  height: 100%;
  display: block;
  background-color: #e8e8e8;
  margin: 0 auto;
}
.ant-anchor-ink-ball {
  display: none;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 2px solid #1f3e6f;
  background-color: #fff;
  left: 50%;
  transition: top 0.3s ease-in-out;
  transform: translateX(-50%);
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 8px 0 8px 16px;
  line-height: 1;
}
.ant-anchor-link-title {
  display: block;
  position: relative;
  transition: all 0.3s;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1f3e6f;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-auto-complete {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  line-height: 2.75rem;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-left: 12px;
  margin-right: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  background: transparent;
  border-width: 1px;
  line-height: 1.285;
  height: 2.75rem;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 40px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 24px;
}
.ant-avatar {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  text-align: center;
  background: #ccc;
  color: #fff;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar > * {
  line-height: 32px;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg > * {
  line-height: 40px;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm > * {
  line-height: 24px;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 4px;
}
.ant-avatar > img {
  width: 100%;
  height: 100%;
  display: block;
}
.ant-back-top {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  position: fixed;
  right: 100px;
  bottom: 50px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.45);
  color: #fff;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}
.ant-back-top-content:hover {
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  margin: 12px auto;
  width: 14px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  color: unset;
}
.ant-badge-count {
  top: -10px;
  height: 20px;
  border-radius: 10px;
  min-width: 20px;
  background: #e64230;
  color: #fff;
  line-height: 20px;
  text-align: center;
  padding: 0 6px;
  font-size: 0.875rem;
  font-weight: normal;
  white-space: nowrap;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  top: -3px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background: #e64230;
  z-index: 10;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot {
  position: absolute;
  right: 0;
  transform: translateX(50%);
  transform-origin: 100%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.ant-badge-status-success {
  background-color: #46b063;
}
.ant-badge-status-processing {
  background-color: #b6daff;
  position: relative;
}
.ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #b6daff;
  content: '';
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #c01300;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-text {
  color: #fff;
  font-size: 1rem;
  margin-left: 8px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  top: auto;
  display: block;
  position: relative;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 20px;
}
.ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
}
@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0) translateX(50%);
  }
  100% {
    transform: scale(1) translateX(50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translateX(50%);
  }
  100% {
    opacity: 0;
    transform: scale(0) translateX(50%);
  }
}
.ant-breadcrumb {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #fff;
  font-size: 1.125rem;
}
.ant-breadcrumb .anticon {
  font-size: 1.5rem;
}
.ant-breadcrumb a {
  color: #fff;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #b6daff;
}
.ant-breadcrumb > span:last-child {
  color: #fff;
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: #fff;
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-btn {
  line-height: 1.285;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 1rem;
  border-radius: 4px;
  height: 2.75rem;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  transition: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 4px;
  height: 3.625rem;
}
.ant-btn-sm {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  height: 1.625rem;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child:after,
.ant-btn:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn:active,
.ant-btn.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child:after,
.ant-btn.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn.disabled > a:only-child:after,
.ant-btn[disabled] > a:only-child:after,
.ant-btn.disabled:hover > a:only-child:after,
.ant-btn[disabled]:hover > a:only-child:after,
.ant-btn.disabled:focus > a:only-child:after,
.ant-btn[disabled]:focus > a:only-child:after,
.ant-btn.disabled:active > a:only-child:after,
.ant-btn[disabled]:active > a:only-child:after,
.ant-btn.disabled.active > a:only-child:after,
.ant-btn[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: #fff;
  text-decoration: none;
}
.ant-btn > i,
.ant-btn > span {
  pointer-events: none;
}
.ant-btn-primary {
  color: #13213a;
  background-color: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #13213a;
  background-color: #ffe2a8;
  border-color: #ffe2a8;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child:after,
.ant-btn-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #13213a;
  background-color: #d6a760;
  border-color: #d6a760;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child:after,
.ant-btn-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-primary[disabled] > a:only-child:after,
.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #37547d;
  border-left-color: #37547d;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #fdcf7e;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #37547d;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #fdcf7e;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #37547d;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #fdcf7e;
}
.ant-btn-ghost {
  color: #fff;
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #37547d;
  background-color: transparent;
  border-color: #37547d;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child:after,
.ant-btn-ghost:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #11254a;
  background-color: transparent;
  border-color: #11254a;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child:after,
.ant-btn-ghost.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost.disabled > a:only-child:after,
.ant-btn-ghost[disabled] > a:only-child:after,
.ant-btn-ghost.disabled:hover > a:only-child:after,
.ant-btn-ghost[disabled]:hover > a:only-child:after,
.ant-btn-ghost.disabled:focus > a:only-child:after,
.ant-btn-ghost[disabled]:focus > a:only-child:after,
.ant-btn-ghost.disabled:active > a:only-child:after,
.ant-btn-ghost[disabled]:active > a:only-child:after,
.ant-btn-ghost.disabled.active > a:only-child:after,
.ant-btn-ghost[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-dashed {
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child:after,
.ant-btn-dashed:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child:after,
.ant-btn-dashed.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed.disabled > a:only-child:after,
.ant-btn-dashed[disabled] > a:only-child:after,
.ant-btn-dashed.disabled:hover > a:only-child:after,
.ant-btn-dashed[disabled]:hover > a:only-child:after,
.ant-btn-dashed.disabled:focus > a:only-child:after,
.ant-btn-dashed[disabled]:focus > a:only-child:after,
.ant-btn-dashed.disabled:active > a:only-child:after,
.ant-btn-dashed[disabled]:active > a:only-child:after,
.ant-btn-dashed.disabled.active > a:only-child:after,
.ant-btn-dashed[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-danger {
  color: #fff;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-danger:hover {
  color: #13213a;
  background-color: #fff0f0;
  border-color: #fff0f0;
}
.ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-danger:focus {
  color: #fff0f0;
  background-color: #fff;
  border-color: #fff0f0;
}
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #13213a;
  background-color: #d9cccc;
  border-color: #d9cccc;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child:after,
.ant-btn-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-danger[disabled] > a:only-child:after,
.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  width: 2.75rem;
  padding: 0;
  font-size: 3rem;
  border-radius: 50%;
  height: 2.75rem;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  width: 3.625rem;
  padding: 0;
  font-size: 3.312rem;
  border-radius: 50%;
  height: 3.625rem;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  width: 1.625rem;
  padding: 0;
  font-size: 1rem;
  border-radius: 50%;
  height: 1.625rem;
}
.ant-btn:before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #fff;
  opacity: 0.35;
  content: '';
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn.ant-btn-loading:before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
  pointer-events: none;
  position: relative;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
  line-height: 0.75rem;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 0;
  height: 3.625rem;
  line-height: 1.625rem;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 0;
  height: 1.625rem;
  line-height: -0.375rem;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 1rem;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}
.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #fdcf7e;
  background-color: transparent;
  border-color: #fdcf7e;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ffe2a8;
  background-color: transparent;
  border-color: #ffe2a8;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #d6a760;
  background-color: transparent;
  border-color: #d6a760;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #fff0f0;
  background-color: transparent;
  border-color: #fff0f0;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9cccc;
  background-color: transparent;
  border-color: #d9cccc;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-two-chinese-chars:first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-right: -0.34em;
}
.ant-btn-block {
  width: 100%;
}
a.ant-btn {
  line-height: 0.75rem;
}
a.ant-btn-lg {
  line-height: 1.625rem;
}
a.ant-btn-sm {
  line-height: -0.375rem;
}
.ant-fullcalendar {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  border-top: 1px solid #d9d9d9;
}
.ant-fullcalendar-month-select {
  margin-left: 5px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  line-height: 20px;
  padding: 0 10px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
  height: 256px;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  border-spacing: 0;
  margin-bottom: 0;
}
.ant-fullcalendar-column-header {
  line-height: 18px;
  padding: 0;
  width: 33px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  margin: 0 auto;
  color: #fff;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  padding: 0;
  background: transparent;
  line-height: 24px;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  background: #1f3e6f;
  color: #fff;
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #1f3e6f inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  background: #1f3e6f;
  color: #fff;
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-month-panel-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -9px;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 2.75rem;
  line-height: 0.75rem;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  text-align: left;
  margin: 0 4px;
  display: block;
  color: #fff;
  height: 116px;
  padding: 4px 8px;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #969da3;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  text-align: right;
  padding-right: 12px;
  padding-bottom: 5px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  text-align: right;
  background: transparent;
  width: auto;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: #fff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: #1f3e6f;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #a5abb0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #1f3e6f;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  height: 88px;
  overflow-y: auto;
  position: static;
  width: auto;
  left: auto;
  bottom: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  width: auto;
  cursor: not-allowed;
}
.ant-card {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-radius: 2px;
  position: relative;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  border-color: rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 24px;
  border-radius: 2px 2px 0 0;
  zoom: 1;
  margin-bottom: -1px;
  min-height: 48px;
  font-size: 1.312rem;
  color: inherit;
  font-weight: 500;
}
.ant-card-head:before,
.ant-card-head:after {
  content: "";
  display: table;
}
.ant-card-head:after {
  clear: both;
}
.ant-card-head:before,
.ant-card-head:after {
  content: "";
  display: table;
}
.ant-card-head:after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
}
.ant-card-head-title {
  padding: 16px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  flex: 1;
}
.ant-card-head .ant-tabs {
  margin-bottom: -17px;
  clear: both;
  font-size: 1rem;
  color: #fff;
  font-weight: normal;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  padding: 17.5px 0;
  font-size: 1rem;
  color: #fff;
  font-weight: normal;
  text-align: right;
  margin-left: auto;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body:before,
.ant-card-body:after {
  content: "";
  display: table;
}
.ant-card-body:after {
  clear: both;
}
.ant-card-body:before,
.ant-card-body:after {
  content: "";
  display: table;
}
.ant-card-body:after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  border-radius: 0;
  border: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  width: 33.33%;
  float: left;
  padding: 24px;
  transition: all 0.3s;
}
.ant-card-grid:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs .ant-card-head-title {
  padding-bottom: 0;
  min-height: 32px;
}
.ant-card-contain-tabs .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  width: 100%;
  display: block;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  border-top: 1px solid #e8e8e8;
  background: #fafafa;
  zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-card-actions:before,
.ant-card-actions:after {
  content: "";
  display: table;
}
.ant-card-actions:after {
  clear: both;
}
.ant-card-actions:before,
.ant-card-actions:after {
  content: "";
  display: table;
}
.ant-card-actions:after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  text-align: center;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-actions > li > span {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  min-width: 32px;
  position: relative;
}
.ant-card-actions > li > span:hover {
  color: #1f3e6f;
  transition: color 0.3s;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li > span a {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  display: inline-block;
  width: 100%;
}
.ant-card-actions > li > span a:hover {
  color: #1f3e6f;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-wider-padding .ant-card-head {
  padding: 0 32px;
}
.ant-card-wider-padding .ant-card-body {
  padding: 24px 32px;
}
.ant-card-padding-transition .ant-card-head,
.ant-card-padding-transition .ant-card-body {
  transition: padding 0.3s;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 1rem;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta:before,
.ant-card-meta:after {
  content: "";
  display: table;
}
.ant-card-meta:after {
  clear: both;
}
.ant-card-meta:before,
.ant-card-meta:after {
  content: "";
  display: table;
}
.ant-card-meta:after {
  clear: both;
}
.ant-card-meta-avatar {
  padding-right: 16px;
  float: left;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  font-size: 1.312rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
  font-weight: 500;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading .ant-card-body {
  user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  border-radius: 2px;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  animation: card-loading 1.4s ease infinite;
  background-size: 600% 600%;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-carousel {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.ant-carousel .slick-track:before,
.ant-carousel .slick-track:after {
  content: "";
  display: table;
}
.ant-carousel .slick-track:after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: 0;
  outline: none;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.ant-carousel .slick-prev:hover:before,
.ant-carousel .slick-next:hover:before,
.ant-carousel .slick-prev:focus:before,
.ant-carousel .slick-next:focus:before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled:before,
.ant-carousel .slick-next.slick-disabled:before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev:before {
  content: "←";
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next:before {
  content: "→";
}
.ant-carousel .slick-dots {
  position: absolute;
  bottom: 12px;
  list-style: none;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin: 0 2px;
  padding: 0;
}
.ant-carousel .slick-dots li button {
  border: 0;
  cursor: pointer;
  background: #fff;
  opacity: 0.3;
  display: block;
  width: 16px;
  height: 3px;
  border-radius: 1px;
  outline: none;
  font-size: 0;
  color: transparent;
  transition: all 0.5s;
  padding: 0;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  width: 3px;
  bottom: auto;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-cascader {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-cascader-input.ant-input {
  background-color: transparent !important;
  cursor: pointer;
  width: 100%;
  position: static;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  cursor: not-allowed;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  left: 0;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 12px;
}
.ant-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  right: 12px;
  z-index: 2;
  background: #fff;
  top: 50%;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 12px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: -6px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.25);
  transition: transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  transform: rotate(180deg);
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  font-size: 1rem;
  background: #fff;
  position: absolute;
  z-index: 1050;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 180px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-cascader-menu:last-child {
  border-right-color: transparent;
  margin-right: -1px;
  border-radius: 0 4px 4px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 4px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #b6daff;
}
.ant-cascader-menu-item-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #f5f5f5;
  font-weight: 600;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  right: 12px;
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  font-size: 0.875rem;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #e64230;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1f3e6f;
}
.ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #1f3e6f;
  content: '';
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}
.ant-checkbox:hover:after,
.ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}
.ant-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  opacity: 0;
}
.ant-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  content: ' ';
  transform: translate(-50%, -50%) scale(1);
  border: 0;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: #1f3e6f;
  opacity: 1;
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  opacity: 1;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1f3e6f;
  border-color: #1f3e6f;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  animation-name: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}
.ant-checkbox-disabled .ant-checkbox-inner:after {
  animation-name: none;
  border-color: #f5f5f5;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-checkbox-group {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-collapse {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #13213a;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 22px;
  padding: 12px 0 12px 40px;
  color: inherit;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  position: absolute;
  display: inline-block;
  line-height: 46px;
  vertical-align: top;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow svg {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow:before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow svg {
  transform: rotate(0);
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: #fff;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] .anticon-right svg {
  transform: rotate(90deg);
}
.ant-collapse-borderless {
  background-color: #fff;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-picker-container {
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  position: absolute;
  z-index: 1050;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #1f3e6f;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 12px;
  top: 50%;
  margin-top: -7px;
  line-height: 14px;
  font-size: 0.875rem;
  transition: all 0.3s;
  user-select: none;
}
.ant-calendar-picker-clear {
  opacity: 0;
  z-index: 1;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  pointer-events: none;
  cursor: pointer;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  font-family: "anticon";
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: 1;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  outline: none;
  width: 280px;
  border: 1px solid #fff;
  list-style: none;
  font-size: 1rem;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  line-height: 1.285;
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  border: 0;
  width: 100%;
  cursor: auto;
  outline: 0;
  height: 22px;
  color: #fff;
  background: transparent;
}
.ant-calendar-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  user-select: none;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-header a:hover {
  color: #b6daff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  padding: 0 2px;
  font-weight: 500;
  display: inline-block;
  color: inherit;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
}
.ant-calendar-header .ant-calendar-prev-century-btn:after,
.ant-calendar-header .ant-calendar-prev-decade-btn:after,
.ant-calendar-header .ant-calendar-prev-year-btn:after {
  content: '«';
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
}
.ant-calendar-header .ant-calendar-next-century-btn:after,
.ant-calendar-header .ant-calendar-next-decade-btn:after,
.ant-calendar-header .ant-calendar-next-year-btn:after {
  content: '»';
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
}
.ant-calendar-header .ant-calendar-prev-month-btn:after {
  content: '‹';
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
}
.ant-calendar-header .ant-calendar-next-month-btn:after {
  content: '›';
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  border: 0;
  text-align: center;
}
.ant-calendar-calendar-table {
  border-spacing: 0;
  margin-bottom: 0;
}
.ant-calendar-column-header {
  line-height: 18px;
  width: 33px;
  padding: 6px 0;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  padding: 3px 0;
  height: 30px;
}
.ant-calendar-date {
  display: block;
  margin: 0 auto;
  color: #fff;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  line-height: 22px;
  border: 1px solid transparent;
  padding: 0;
  background: transparent;
  text-align: center;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
}
.ant-calendar-date:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #37547d;
}
.ant-calendar-today .ant-calendar-date {
  border-color: #1f3e6f;
  font-weight: bold;
  color: #1f3e6f;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #d2d8e2;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  background: #1f3e6f;
  color: #fff;
  border: 1px solid transparent;
}
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #1f3e6f;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f5f5f5;
  border-radius: 0;
  width: auto;
  border: 1px solid transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  margin-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
  content: " ";
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  border-top: 1px solid #e8e8e8;
  line-height: 38px;
  padding: 0 12px;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  text-align: center;
  display: block;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  text-align: center;
  margin: 0 0 0 8px;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  display: none;
  position: absolute;
  right: 5px;
  text-indent: -76px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn:after {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  line-height: 1;
  width: 20px;
  text-indent: 43px;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover:after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  height: 2.75rem;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: #13213a;
  background-color: #fdcf7e;
  border-color: #fdcf7e;
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  height: 1.625rem;
  line-height: -0.375rem;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  transition: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 4px;
  height: 3.625rem;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  height: 1.625rem;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #13213a;
  background-color: #ffe2a8;
  border-color: #ffe2a8;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #13213a;
  background-color: #d6a760;
  border-color: #d6a760;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar .ant-calendar-ok-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar .ant-calendar-ok-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-range-picker-input {
  background-color: transparent;
  border: 0;
  height: 99%;
  outline: 0;
  width: 44%;
  text-align: center;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.45);
  width: 10px;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.ant-calendar-range-part {
  width: 50%;
  position: relative;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  width: 20px;
  margin-left: -132px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -118px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  margin-left: -12px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.3s;
  height: 24px;
  border: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  border-radius: 0;
  position: relative;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell:before {
  content: '';
  display: block;
  background: #b6daff;
  border-radius: 0;
  border: 0;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  right: 0;
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  height: 207px;
  width: 100%;
  top: 68px;
  z-index: 2;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  padding-top: 40px;
  height: 100%;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  margin: 8px 12px;
  height: 22px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  width: 100%;
  top: 40px;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  z-index: 1050;
  position: absolute;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 1rem;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  float: left;
  font-size: 1rem;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  height: 226px;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 206px;
}
.ant-calendar-time-picker-select li {
  padding-left: 32px;
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  width: 100%;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s ease;
}
.ant-calendar-time-picker-select li:last-child:after {
  content: '';
  height: 202px;
  display: block;
}
.ant-calendar-time-picker-select li:hover {
  background: #b6daff;
}
li.ant-calendar-time-picker-select-option-selected {
  background: #f5f5f5;
  font-weight: bold;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  padding: 0 2px;
  font-weight: 500;
  display: inline-block;
  color: inherit;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}
.ant-calendar-month-panel > div {
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  user-select: none;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-header a:hover {
  color: #b6daff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  padding: 0 2px;
  font-weight: 500;
  display: inline-block;
  color: inherit;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
  content: '«';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after {
  content: '»';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
  content: '‹';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:after {
  content: '›';
}
.ant-calendar-month-panel-body {
  height: calc(100% - 40px);
}
.ant-calendar-month-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f5f5f5;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  background: transparent;
  text-align: center;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}
.ant-calendar-year-panel > div {
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  user-select: none;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-header a:hover {
  color: #b6daff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  padding: 0 2px;
  font-weight: 500;
  display: inline-block;
  color: inherit;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:after {
  content: '«';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after {
  content: '»';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
  content: '‹';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
  content: '›';
}
.ant-calendar-year-panel-body {
  height: calc(100% - 40px);
}
.ant-calendar-year-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  background: transparent;
  text-align: center;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  user-select: none;
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  user-select: none;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-header a:hover {
  color: #b6daff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  padding: 0 2px;
  font-weight: 500;
  display: inline-block;
  color: inherit;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
  content: '«';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:after {
  content: '»';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:after {
  content: '‹';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:after {
  content: '›';
}
.ant-calendar-decade-panel-body {
  height: calc(100% - 40px);
}
.ant-calendar-decade-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  text-align: center;
  white-space: nowrap;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  background: transparent;
  text-align: center;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #b6daff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  background: #1f3e6f;
  color: #fff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  user-select: none;
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  transition: all 0.3s;
  cursor: pointer;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #a5abb0;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background: #969da3;
  font-weight: bold;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  background: transparent;
  color: #fff;
}
.ant-divider {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #e8e8e8;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 8px;
  display: inline-block;
  height: 0.9em;
  width: 1px;
  vertical-align: middle;
  position: relative;
  top: -0.06em;
}
.ant-divider-horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  clear: both;
}
.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: inherit;
  font-size: 1.312rem;
  margin: 16px 0;
}
.ant-divider-horizontal.ant-divider-with-text:before,
.ant-divider-horizontal.ant-divider-with-text-left:before,
.ant-divider-horizontal.ant-divider-with-text-right:before,
.ant-divider-horizontal.ant-divider-with-text:after,
.ant-divider-horizontal.ant-divider-with-text-left:after,
.ant-divider-horizontal.ant-divider-with-text-right:after {
  content: '';
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left:before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left:after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right:before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right:after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-top: 1px dashed #e8e8e8;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:after {
  border-style: dashed none none;
}
.ant-drawer {
  position: fixed;
  top: 0;
  width: 0%;
  height: 100%;
  z-index: 1000;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
.ant-drawer-content-wrapper {
  position: fixed;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-top,
.ant-drawer-bottom {
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0.3;
  height: 100%;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: none;
}
.ant-drawer-title {
  margin: 0;
  font-size: 1.312rem;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
}
.ant-drawer-content {
  position: relative;
  background-color: #fff;
  border: 0;
  background-clip: padding-box;
  z-index: 1;
}
.ant-drawer-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
}
.ant-drawer-close-x {
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 1.312rem;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: #444;
  text-decoration: none;
}
.ant-drawer-header {
  padding: 16px 24px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: #fff;
  border-bottom: 1px solid #e8e8e8;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 1rem;
  line-height: 1.285;
  word-wrap: break-word;
}
.ant-drawer-mask {
  position: fixed;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.65);
  filter: alpha(opacity=50);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-open-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
.ant-dropdown {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 0.875rem;
}
.ant-dropdown-wrap .anticon-down:before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down:before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  outline: none;
  position: relative;
  list-style-type: none;
  padding: 4px 0;
  margin: 0;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
}
.ant-dropdown-menu-item-group-title {
  color: rgba(0, 0, 0, 0.45);
  padding: 5px 12px;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 5px 12px;
  margin: 0;
  clear: both;
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  line-height: 22px;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: #fff;
  display: block;
  padding: 5px 12px;
  margin: -5px -12px;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > a:focus,
.ant-dropdown-menu-submenu-title > a:focus {
  text-decoration: none;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1f3e6f;
  background-color: #b6daff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #b6daff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  overflow: hidden;
  background-color: #e8e8e8;
  line-height: 0;
  margin: 4px 0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-style: normal;
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 0.875rem;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  top: 0;
  left: 100%;
  position: absolute;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
.ant-dropdown-link .anticon:not(.anticon-ellipsis) {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
:root .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
  font-size: 0.875rem;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-dropdown-button .anticon:not(.anticon-ellipsis) {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon:not(.anticon-ellipsis) {
  font-size: 0.875rem;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #13213a;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  background: #1f3e6f;
  color: #fff;
}
.ant-form {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-form legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 1.312rem;
  line-height: inherit;
  color: rgba(0, 0, 0, 0.45);
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 1rem;
}
.ant-form input[type="search"] {
  box-sizing: border-box;
}
.ant-form input[type="radio"],
.ant-form input[type="checkbox"] {
  line-height: normal;
}
.ant-form input[type="file"] {
  display: block;
}
.ant-form input[type="range"] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type="file"]:focus,
.ant-form input[type="radio"]:focus,
.ant-form input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
}
.ant-form-item-required:before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 1rem;
  color: #e64230;
}
.ant-form-hide-required-mark .ant-form-item-required:before {
  display: none;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled {
  cursor: not-allowed;
}
.ant-radio-inline.disabled,
.ant-radio-vertical.disabled,
.ant-checkbox-inline.disabled,
.ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
.ant-radio.disabled label,
.ant-checkbox.disabled label {
  cursor: not-allowed;
}
.ant-form-item {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  vertical-align: top;
  font-size: 1rem;
}
.ant-form-item-control > .ant-form-item:last-child,
.ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
  margin-bottom: -24px;
}
.ant-form-item-control {
  line-height: 39.9999px;
  position: relative;
  zoom: 1;
}
.ant-form-item-control:before,
.ant-form-item-control:after {
  content: "";
  display: table;
}
.ant-form-item-control:after {
  clear: both;
}
.ant-form-item-control:before,
.ant-form-item-control:after {
  content: "";
  display: table;
}
.ant-form-item-control:after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 39.34px;
}
.ant-form-item-label {
  text-align: right;
  vertical-align: middle;
  line-height: 39.9999px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.ant-form-item-label label {
  color: inherit;
}
.ant-form-item-label label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-no-colon .ant-form-item-label label:after {
  content: " ";
}
.ant-form-explain,
.ant-form-extra {
  color: rgba(0, 0, 0, 0.45);
  line-height: 1.285;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -16.625rem;
  clear: both;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 24px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form textarea.ant-input {
  height: auto;
}
form .ant-upload {
  background: transparent;
}
form input[type="radio"],
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  margin-left: 8px;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.ant-input-group-wrap .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group-wrap .ant-select-selection:hover {
  border-color: #d9d9d9;
}
.ant-input-group-wrap .ant-select-selection--single {
  margin-left: -1px;
  height: 40px;
  background-color: #eee;
}
.ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-left: 8px;
  padding-right: 25px;
  line-height: 30px;
}
.ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: left;
  line-height: 1.285;
}
.ant-form-vertical .ant-form-item-label label:after,
.ant-col-24.ant-form-item-label label:after,
.ant-col-xl-24.ant-form-item-label label:after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.285;
}
.ant-form-vertical .ant-form-explain,
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-form-item-label label:after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-col-xs-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-col-sm-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-col-md-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-col-lg-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.285;
  }
  .ant-col-xl-24.ant-form-item-label label:after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: middle;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.ant-form-inline .ant-form-explain {
  position: absolute;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  visibility: visible;
  pointer-events: none;
  width: 2.75rem;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  z-index: 1;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  animation-name: diffZoomIn1 !important;
  color: #46b063;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px !important;
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px !important;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
  background-color: transparent;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px !important;
}
.has-warning .ant-calendar-picker-icon:after,
.has-warning .ant-time-picker-icon:after,
.has-warning .ant-picker-icon:after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px !important;
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #c01300;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: #c01300;
}
.has-error .ant-input:focus {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #c01300;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-input-prefix {
  color: #c01300;
}
.has-error .ant-input-group-addon {
  color: #c01300;
  border-color: #c01300;
  background-color: transparent;
}
.has-error .has-feedback {
  color: #c01300;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #c01300;
  animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #c01300;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #c01300;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon:after,
.has-error .ant-time-picker-icon:after,
.has-error .ant-picker-icon:after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #c01300;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #c01300;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #c01300;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #c01300;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #cc3721;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(192, 19, 0, 0.2);
  border-right-width: 1px !important;
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #1f3e6f;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 39.34px;
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes antShowHelpOut {
  to {
    opacity: 0;
    transform: translateY(-5px);
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.ant-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row:before,
.ant-row:after {
  content: "";
  display: table;
}
.ant-row:after {
  clear: both;
}
.ant-row:before,
.ant-row:after {
  content: "";
  display: table;
}
.ant-row:after {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex:before,
.ant-row-flex:after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  display: block;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  float: left;
  flex: 0 0 auto;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  float: left;
  flex: 0 0 auto;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    float: left;
    flex: 0 0 auto;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    float: left;
    flex: 0 0 auto;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    float: left;
    flex: 0 0 auto;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    float: left;
    flex: 0 0 auto;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    float: left;
    flex: 0 0 auto;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}
.ant-input {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-input:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-input-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-group {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}
.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  padding: 0 11px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #fff;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  background-color: inherit;
  margin: -1px;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1f3e6f;
}
.ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: left;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact:before,
.ant-input-group.ant-input-group-compact:after {
  content: "";
  display: table;
}
.ant-input-group.ant-input-group-compact:after {
  clear: both;
}
.ant-input-group.ant-input-group-compact:before,
.ant-input-group.ant-input-group-compact:after {
  content: "";
  display: table;
}
.ant-input-group.ant-input-group-compact:after {
  clear: both;
}
.ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-right-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}
.ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input {
  border-right-width: 0;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-right-width: 0;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}
.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.ant-input-affix-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: static;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  color: #fff;
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.285;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: #333;
}
.ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
  right: 12px;
}
.ant-input-search > .ant-input-suffix > .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-search > .ant-input-suffix > .ant-input-search-button > .anticon-search {
  font-size: 1.312rem;
}
.ant-input-search.ant-input-search-enter-button > .ant-input {
  padding-right: 46px;
}
.ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  right: 0;
}
.ant-input-number {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-variant: tabular-nums;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  transition: all 0.3s;
  margin: 0;
  padding: 0;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 90px;
}
.ant-input-number::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #585f6d;
}
.ant-input-number::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-input-number:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-input-number-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-input-number-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-input-number-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-input-number-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-input-number-handler {
  text-align: center;
  line-height: 0;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  position: relative;
  transition: all 0.1s linear;
  display: block;
  width: 100%;
  font-weight: bold;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #37547d;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 12px;
  user-select: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: all 0.1s linear;
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: block;
}
.ant-input-number-handler-up-inner:before,
.ant-input-number-handler-down-inner:before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
:root .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-down-inner {
  font-size: 0.875rem;
}
.ant-input-number:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-input-number-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-input-number-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  text-align: left;
  outline: 0;
  -moz-appearance: textfield;
  height: 0.75rem;
  transition: all 0.3s linear;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  padding: 0 11px;
}
.ant-input-number-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 1.312rem;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  border-left: 1px solid #d9d9d9;
  width: 22px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  border-radius: 0 4px 4px 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
  top: 0;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-layout {
  display: flex;
  flex-direction: column;
  flex: auto;
  background: transparent;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  background: #13213a;
  padding: 0;
  height: 4.375rem;
  line-height: 4.375rem;
}
.ant-layout-footer {
  background: transparent;
  padding: 24px 50px;
  color: #fff;
  font-size: 1rem;
}
.ant-layout-content {
  flex: auto;
}
.ant-layout-sider {
  transition: all 0.2s;
  position: relative;
  background: #13213a;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
}
.ant-layout-sider-children {
  height: 100%;
  padding-top: 0.1px;
  margin-top: -0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  text-align: center;
  bottom: 0;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background: #002140;
  z-index: 1;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 4.375rem;
  right: -36px;
  text-align: center;
  width: 36px;
  height: 42px;
  line-height: 42px;
  background: #13213a;
  color: #fff;
  font-size: 18px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #2b374e;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: #fff;
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #fff;
  background: #fff;
}
.ant-list {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-left: 32px;
  padding-right: 32px;
}
.ant-list-spin {
  text-align: center;
  min-height: 40px;
}
.ant-list-empty-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1rem;
  padding: 16px;
  text-align: center;
}
.ant-list-item {
  align-items: center;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-item-meta {
  align-items: flex-start;
  display: flex;
  flex: 1;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  color: #fff;
  margin-bottom: 4px;
  font-size: 1rem;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: #fff;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #1f3e6f;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1rem;
  line-height: 22px;
}
.ant-list-item-content {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.ant-list-item-content-single {
  justify-content: flex-start;
}
.ant-list-item-action {
  font-size: 0;
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  list-style: none;
}
.ant-list-item-action > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  padding: 0 8px;
  position: relative;
  font-size: 1rem;
  line-height: 22px;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  background-color: #e8e8e8;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
}
.ant-list-item-main {
  display: flex;
  flex: 1;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  color: rgba(0, 0, 0, 0.45);
  padding: 16px 0;
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  display: block;
}
.ant-list-vertical .ant-list-item-extra-wrap {
  display: flex;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 58px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-avatar {
  display: none;
}
.ant-list-vertical .ant-list-item-meta-title {
  color: inherit;
  margin-bottom: 12px;
  font-size: 1.312rem;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-content {
  display: block;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-action {
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-list-item {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 16px;
}
.ant-list-grid .ant-list-item-content {
  display: block;
  max-width: 100%;
}
.ant-list-bordered {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.ant-list-bordered .ant-list-header {
  padding-left: 24px;
  padding-right: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-left: 24px;
  padding-right: 24px;
}
.ant-list-bordered .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
  padding-left: 24px;
  padding-right: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-left: 16px;
  padding-right: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 480px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item-extra-wrap {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
  }
}
.ant-mention-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.3s;
  min-height: 2.75rem;
  height: auto;
  line-height: 1.285;
  padding: 0;
  display: block;
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: #585f6d;
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-mention-wrapper .ant-mention-editor-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-mention-wrapper .ant-mention-editor-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-mention-wrapper .ant-mention-editor-wrapper {
  overflow-y: auto;
  height: auto;
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  color: #585f6d;
  opacity: 1;
  outline: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: auto;
  padding: 5px 11px;
}
.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mention-dropdown {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 1.5em;
  max-height: 250px;
  min-width: 120px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1050;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  color: #1f3e6f;
  text-align: center;
  display: block;
}
.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  line-height: 22px;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background 0.3s;
}
.ant-mention-dropdown-item:hover {
  background-color: #b6daff;
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #b6daff;
}
.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #fff;
}
.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e8e8e8;
  line-height: 0;
}
.ant-menu {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: #fff;
  background: #fff;
  line-height: 0;
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu:before,
.ant-menu:after {
  content: "";
  display: table;
}
.ant-menu:after {
  clear: both;
}
.ant-menu:before,
.ant-menu:after {
  content: "";
  display: table;
}
.ant-menu:after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1rem;
  line-height: 1.285;
  padding: 8px 16px;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #b6daff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: #fff;
}
.ant-menu-item > a:hover {
  color: #1f3e6f;
}
.ant-menu-item > a:focus {
  text-decoration: none;
}
.ant-menu-item > a:before {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  background-color: #e8e8e8;
  line-height: 0;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1f3e6f;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #1f3e6f;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #1f3e6f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #b6daff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  border-right: 0;
  padding: 0;
  transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
  position: relative;
  display: block;
  white-space: nowrap;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 3rem;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  border-radius: 4px;
  z-index: 1050;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup:before {
  position: absolute;
  top: -7px;
  left: -6px;
  right: -6px;
  bottom: 0;
  content: ' ';
  opacity: 0.0001;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title:after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  content: '';
  position: absolute;
  vertical-align: baseline;
  background: #fff;
  background-image: linear-gradient(to right, #fff, #fff);
  width: 6px;
  height: 1.5px;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #1f3e6f, #1f3e6f);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1f3e6f;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #1f3e6f;
}
.ant-menu-horizontal {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
  line-height: 46px;
  white-space: nowrap;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #1f3e6f;
  color: #1f3e6f;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #fff;
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #1f3e6f;
}
.ant-menu-horizontal > .ant-menu-item > a:before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #1f3e6f;
}
.ant-menu-horizontal:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 3px solid #1f3e6f;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 16px;
  font-size: 1rem;
  line-height: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.01px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 40px;
  height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected:after,
.ant-menu-inline .ant-menu-item-selected:after {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
  transform: scaleY(1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  text-overflow: clip;
  padding: 0 32px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 16px;
  line-height: 40px;
  margin: 0;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: #13213a;
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4px;
  padding-right: 4px;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 40px;
  height: 40px;
  list-style-type: disc;
  list-style-position: inside;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
  background: none;
  border-color: transparent !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #fff;
  background: #13213a;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #13213a;
  border-bottom: 0;
  top: 0;
  margin-top: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a:before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: #fff;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: transparent;
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item-selected {
  border-right: 0;
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected:after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1f3e6f;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-message {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #fff;
  display: inline-block;
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #46b063;
}
.ant-message-error .anticon {
  color: #c01300;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #b6daff;
}
.ant-message .anticon {
  margin-right: 8px;
  font-size: 1.312rem;
  top: 1px;
  position: relative;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  animation-name: MessageMoveOut;
  overflow: hidden;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }
  100% {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}
.ant-modal {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: auto;
  margin: 0 auto;
  top: 100px;
  padding-bottom: 24px;
}
.ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.ant-modal-title {
  margin: 0;
  font-size: 1.312rem;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
}
.ant-modal-close-x {
  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 1.312rem;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #444;
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: #fff;
  border-bottom: 1px solid #e8e8e8;
}
.ant-modal-body {
  padding: 24px;
  font-size: 1rem;
  line-height: 1.285;
  word-wrap: break-word;
}
.ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-left: 8px;
  margin-bottom: 0;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  animation-duration: 0.3s;
  transform: none;
  opacity: 0;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.ant-modal-centered .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}
.ant-modal-centered .ant-table-body {
  white-space: nowrap;
  overflow-x: auto;
}
@media (max-width: 767px) {
  .ant-modal {
    width: auto !important;
    margin: 10px;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-confirm .ant-modal-header {
  display: none;
}
.ant-confirm .ant-modal-close {
  display: none;
}
.ant-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-confirm-body-wrapper {
  zoom: 1;
}
.ant-confirm-body-wrapper:before,
.ant-confirm-body-wrapper:after {
  content: "";
  display: table;
}
.ant-confirm-body-wrapper:after {
  clear: both;
}
.ant-confirm-body-wrapper:before,
.ant-confirm-body-wrapper:after {
  content: "";
  display: table;
}
.ant-confirm-body-wrapper:after {
  clear: both;
}
.ant-confirm-body .ant-confirm-title {
  color: inherit;
  font-weight: 500;
  font-size: 1.312rem;
  display: block;
  overflow: hidden;
}
.ant-confirm-body .ant-confirm-content {
  margin-left: 42px;
  font-size: 1rem;
  color: #fff;
  margin-top: 8px;
}
.ant-confirm-body > .anticon {
  font-size: 26px;
  margin-right: 16px;
  float: left;
}
.ant-confirm .ant-confirm-btns {
  margin-top: 24px;
  float: right;
}
.ant-confirm .ant-confirm-btns button + button {
  margin-left: 8px;
  margin-bottom: 0;
}
.ant-confirm-error .ant-confirm-body > .anticon {
  color: #c01300;
}
.ant-confirm-warning .ant-confirm-body > .anticon,
.ant-confirm-confirm .ant-confirm-body > .anticon {
  color: #faad14;
}
.ant-confirm-info .ant-confirm-body > .anticon {
  color: #b6daff;
}
.ant-confirm-success .ant-confirm-body > .anticon {
  color: #46b063;
}
.ant-notification {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-left: 24px;
  margin-right: 0;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 1rem;
  cursor: pointer;
}
.ant-notification-notice {
  padding: 16px 24px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #fff;
  line-height: 1.5;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
}
.ant-notification-notice-message {
  font-size: 1.312rem;
  color: inherit;
  margin-bottom: 8px;
  line-height: 24px;
  display: inline-block;
}
.ant-notification-notice-message-single-line-auto-margin {
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  background-color: transparent;
  pointer-events: none;
  display: block;
  max-width: 4px;
}
.ant-notification-notice-message-single-line-auto-margin:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.ant-notification-notice-description {
  font-size: 1rem;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 1.312rem;
  margin-left: 48px;
  margin-bottom: 4px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 1rem;
}
.ant-notification-notice-icon {
  position: absolute;
  font-size: 26px;
  line-height: 24px;
  margin-left: 4px;
}
.ant-notification-notice-icon-success {
  color: #46b063;
}
.ant-notification-notice-icon-info {
  color: #b6daff;
}
.ant-notification-notice-icon-warning {
  color: #faad14;
}
.ant-notification-notice-icon-error {
  color: #c01300;
}
.ant-notification-notice-close {
  position: absolute;
  right: 22px;
  top: 16px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
a.ant-notification-notice-close:focus {
  text-decoration: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    max-height: 150px;
  }
  100% {
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }
}
.ant-pagination {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.ant-pagination-total-text {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  line-height: 30px;
  margin-right: 8px;
}
.ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-right: 8px;
  font-family: Arial;
  outline: 0;
}
.ant-pagination-item a {
  text-decoration: none;
  color: #fff;
  transition: none;
  margin: 0 6px;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  transition: all 0.3s;
  border-color: #1f3e6f;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1f3e6f;
}
.ant-pagination-item-active {
  border-color: #1f3e6f;
  font-weight: 500;
}
.ant-pagination-item-active a {
  color: #1f3e6f;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #37547d;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #37547d;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  color: #1f3e6f;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 0.875rem;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  display: block;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  opacity: 1;
  transition: all 0.2s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: Arial;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  list-style: none;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  transition: all 0.3s;
  display: inline-block;
  vertical-align: middle;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: #fff;
  user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #37547d;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  display: block;
  transition: all 0.3s;
  font-size: 12px;
  height: 100%;
  text-align: center;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #1f3e6f;
  color: #1f3e6f;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  vertical-align: top;
  height: 2.75rem;
  line-height: 2.75rem;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.3s;
  margin: 0 8px;
  width: 50px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  border: 0;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-right: 8px;
  height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1f3e6f;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  margin: 0;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-right: 0;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 1px 7px;
  height: 24px;
  width: 44px;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-popover {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  cursor: auto;
  user-select: text;
  white-space: normal;
  font-weight: normal;
  text-align: left;
}
.ant-popover:after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-popover-title {
  min-width: 177px;
  margin: 0;
  padding: 5px 16px 4px;
  min-height: 32px;
  border-bottom: 1px solid #e8e8e8;
  color: inherit;
  font-weight: 500;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #13213a;
}
.ant-popover-message {
  padding: 4px 0 12px;
  font-size: 1rem;
  color: #13213a;
}
.ant-popover-message > .anticon {
  color: #faad14;
  line-height: 1.385;
  top: 20px;
  position: absolute;
  font-size: 3rem;
}
.ant-popover-message-title {
  padding-left: 9rem;
}
.ant-popover-buttons {
  text-align: right;
  margin-bottom: 4px;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  background: #fff;
  width: 8.48528137px;
  height: 8.48528137px;
  transform: rotate(45deg);
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 5.5px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-progress {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.ant-progress-line {
  width: 100%;
  font-size: 1rem;
  position: relative;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 0.875rem;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  padding-right: calc(2em + 8px);
  margin-right: calc(-2em - 8px);
}
.ant-progress-inner {
  display: inline-block;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 100px;
  vertical-align: middle;
  position: relative;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  stroke: #b6daff;
  animation: ant-progress-appear 0.3s;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #b6daff;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position: relative;
}
.ant-progress-success-bg {
  background-color: #46b063;
  position: absolute;
  top: 0;
  left: 0;
}
.ant-progress-text {
  word-break: normal;
  width: 2em;
  text-align: left;
  font-size: 1em;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.45);
  line-height: 1;
}
.ant-progress-text .anticon {
  font-size: 1rem;
}
.ant-progress-status-active .ant-progress-bg:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #c01300;
}
.ant-progress-status-exception .ant-progress-text {
  color: #c01300;
}
.ant-progress-status-exception .ant-progress-circle-path {
  stroke: #c01300;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #46b063;
}
.ant-progress-status-success .ant-progress-text {
  color: #46b063;
}
.ant-progress-status-success .ant-progress-circle-path {
  stroke: #46b063;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin: 0;
  color: #fff;
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #c01300;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #46b063;
}
@keyframes ant-progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
.ant-radio-group {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  line-height: unset;
}
.ant-radio-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: sub;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
  border-color: #1f3e6f;
}
.ant-radio-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #1f3e6f;
  content: '';
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}
.ant-radio:hover:after,
.ant-radio-wrapper:hover .ant-radio:after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  border-color: #d9d9d9;
  background-color: #fff;
  transition: all 0.3s;
}
.ant-radio-inner:after {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 3px;
  top: 3px;
  border-radius: 8px;
  display: table;
  border-top: 0;
  border-left: 0;
  content: ' ';
  background-color: #1f3e6f;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1f3e6f;
}
.ant-radio-checked .ant-radio-inner:after {
  transform: scale(0.875);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}
.ant-radio-disabled .ant-radio-inner:after {
  background-color: #ccc;
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-radio-button-wrapper {
  margin: 0;
  height: 2.75rem;
  line-height: 0.75rem;
  color: #13213a;
  display: inline-block;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-left: 0;
  border-top-width: 1.02px;
  background: #fff;
  padding: 0 15px;
  position: relative;
}
.ant-radio-button-wrapper a {
  color: #13213a;
}
.ant-radio-button-wrapper > .ant-radio-button {
  margin-left: 0;
  display: block;
  width: 0;
  height: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  line-height: 38px;
  font-size: 1.312rem;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  line-height: 22px;
  padding: 0 7px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  content: "";
  display: block;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #d9d9d9;
}
.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid #d9d9d9;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-focused {
  color: #1f3e6f;
  position: relative;
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type="checkbox"],
.ant-radio-button-wrapper input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.ant-radio-button-wrapper-checked {
  background: #fff;
  border-color: #1f3e6f;
  color: #1f3e6f;
  box-shadow: -1px 0 0 0 #1f3e6f;
  z-index: 1;
}
.ant-radio-button-wrapper-checked::before {
  background-color: #1f3e6f !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:first-child {
  border-color: #1f3e6f;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:hover {
  border-color: #37547d;
  box-shadow: -1px 0 0 0 #37547d;
  color: #37547d;
}
.ant-radio-button-wrapper-checked:active {
  border-color: #11254a;
  box-shadow: -1px 0 0 0 #11254a;
  color: #11254a;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #1f3e6f;
  border-color: #1f3e6f;
  color: #fff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: #37547d;
  background: #37547d;
  color: #fff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  border-color: #11254a;
  background: #11254a;
  color: #fff;
}
.ant-radio-button-wrapper-disabled {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance:meterbar) and (background-blend-mode:difference,normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.ant-rate {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  line-height: unset;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 20px;
  display: inline-block;
  color: #fadb14;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:focus {
  outline: 0;
}
.ant-rate-star-first,
.ant-rate-star-second {
  user-select: none;
  transition: all 0.3s;
  color: #e8e8e8;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star:hover,
.ant-rate-star:focus {
  transform: scale(1.1);
}
.ant-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  margin-left: 8px;
  display: inline-block;
  font-size: 1rem;
}
.ant-select {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  line-height: 1;
  margin-top: -0.4375rem;
  transform-origin: 50% 50%;
  color: rgba(0, 0, 0, 0.25);
  font-size: 0.875rem;
}
.ant-select-arrow svg {
  display: block;
}
.ant-select-arrow:before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}
.ant-select-selection {
  outline: none;
  user-select: none;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-select-selection__clear {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  right: 11px;
  z-index: 1;
  background: #fff;
  top: 50%;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-select-selection__clear:before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-right: 20px;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background: #f5f5f5;
  color: #aaa;
  padding-right: 10px;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  height: 2.75rem;
  position: relative;
  cursor: pointer;
}
.ant-select-selection__rendered {
  display: block;
  margin-left: 11px;
  margin-right: 11px;
  position: relative;
  line-height: 0.75rem;
}
.ant-select-selection__rendered:after {
  content: '.';
  visibility: hidden;
  pointer-events: none;
  display: inline-block;
  width: 0;
}
.ant-select-lg {
  font-size: 1.312rem;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  line-height: 22px;
  margin: 0 7px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  display: inline-block;
  position: relative;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  right: 9px;
  color: #585f6d;
  line-height: 20px;
  height: 20px;
  max-width: 100%;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: -9999px;
  left: -9999px;
  white-space: pre;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  border-width: 0;
  font-size: 100%;
  height: 100%;
  width: 100%;
  background: transparent;
  outline: 0;
  border-radius: 4px;
  line-height: 1;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 2.75rem;
  cursor: text;
  padding-bottom: 3px;
  zoom: 1;
}
.ant-select-selection--multiple:before,
.ant-select-selection--multiple:after {
  content: "";
  display: table;
}
.ant-select-selection--multiple:after {
  clear: both;
}
.ant-select-selection--multiple:before,
.ant-select-selection--multiple:after {
  content: "";
  display: table;
}
.ant-select-selection--multiple:after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  float: left;
  position: static;
  width: auto;
  padding: 0;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  max-width: 100%;
  width: 0.75em;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 5px;
  margin-bottom: -3px;
  height: auto;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 3px;
  height: -5.25rem;
  line-height: -7.25rem;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  color: #fff;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: left;
  margin-right: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 20px 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.45);
  line-height: inherit;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  font-size: 0.875rem;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 4px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 0.875rem;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #404040;
}
.ant-select-selection--multiple .ant-select-selection__clear {
  top: 1.375rem;
}
.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  height: 100%;
  width: 100%;
  float: none;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: none;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
  font-size: 1rem;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  color: rgba(0, 0, 0, 0.45);
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  font-size: 0.875rem;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  line-height: 22px;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #b6daff;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
  background-color: #fafafa;
  font-weight: 600;
  color: #fff;
}
.ant-select-dropdown-menu-item-active {
  background-color: #b6daff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e8e8e8;
  line-height: 0;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  color: transparent;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-weight: bold;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
}
:root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-size: 0.875rem;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover:after {
  color: #ddd;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled:after {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:after,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover:after {
  color: #1f3e6f;
  display: inline-block;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  vertical-align: top;
  padding-right: 16px;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}
.ant-skeleton-content .ant-skeleton-title {
  margin-top: 16px;
  height: 16px;
  width: 100%;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 16px;
  background: #f2f2f2;
  list-style: none;
  width: 100%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:nth-child(3) {
  width: 94%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:nth-child(4) {
  width: 96%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  background-size: 400% 100%;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  background-size: 400% 100%;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-slider {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin: 14px 6px 10px;
  padding: 4px 0;
  height: 12px;
  cursor: pointer;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  height: 100%;
  width: 4px;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background-color: #728496;
  transition: background-color 0.3s ease;
}
.ant-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
  border: solid 2px #728496;
  background-color: #fff;
  transition: border-color 0.3s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #4c658c;
  box-shadow: 0 0 0 5px #8f9fb7;
  outline: none;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1f3e6f;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #536b8a;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #536b8a;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 1rem;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}
.ant-slider-mark-text-active {
  color: #fff;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e8e8e8;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8f9fb7;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-color: #fff;
  cursor: not-allowed;
  box-shadow: none;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-spin {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #1f3e6f;
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  display: none;
}
.ant-spin-spinning {
  opacity: 1;
  position: static;
  display: inline-block;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  display: block;
  position: absolute;
  height: 100%;
  max-height: 360px;
  width: 100%;
  z-index: 4;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 11.5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 8.5px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 17.5px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  zoom: 1;
}
.ant-spin-container:before,
.ant-spin-container:after {
  content: "";
  display: table;
}
.ant-spin-container:after {
  clear: both;
}
.ant-spin-container:before,
.ant-spin-container:after {
  content: "";
  display: table;
}
.ant-spin-container:after {
  clear: both;
}
.ant-spin-blur {
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  opacity: 0.5;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  /* autoprefixer: off */
  filter: progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false);
}
.ant-spin-blur:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  transition: all 0.3s;
  z-index: 10;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.ant-spin-dot i {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #1f3e6f;
  transform: scale(0.75);
  display: block;
  position: absolute;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
  transform-origin: 50% 50%;
}
.ant-spin-dot i:nth-child(1) {
  left: 0;
  top: 0;
}
.ant-spin-dot i:nth-child(2) {
  right: 0;
  top: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot i:nth-child(4) {
  left: 0;
  bottom: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
  width: 14px;
  height: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
  width: 32px;
  height: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-steps {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  width: 100%;
  display: flex;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  overflow: hidden;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child .ant-steps-item-tail,
.ant-steps-item:last-child .ant-steps-item-title:after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  font-size: 1.312rem;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ant-steps-item-icon > .ant-steps-icon {
  line-height: 1;
  top: -1px;
  color: #1f3e6f;
  position: relative;
}
.ant-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}
.ant-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: #e8e8e8;
  height: 1px;
  border-radius: 1px;
  width: 100%;
  transition: background 0.3s;
}
.ant-steps-item-title {
  font-size: 1.312rem;
  color: #fff;
  display: inline-block;
  padding-right: 16px;
  position: relative;
  line-height: 32px;
}
.ant-steps-item-title:after {
  content: '';
  height: 1px;
  width: 9999px;
  background: #e8e8e8;
  display: block;
  position: absolute;
  top: 16px;
  left: 100%;
}
.ant-steps-item-description {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-tail:after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #1f3e6f;
  background-color: #fff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1f3e6f;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1f3e6f;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: inherit;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-tail:after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #1f3e6f;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #1f3e6f;
  background-color: #fff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1f3e6f;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1f3e6f;
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #1f3e6f;
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #1f3e6f;
}
.ant-steps-item-error .ant-steps-item-icon {
  border-color: #c01300;
  background-color: #fff;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #c01300;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #c01300;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title {
  color: #c01300;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-description {
  color: #c01300;
}
.ant-steps-item-error > .ant-steps-item-tail:after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
  background: #c01300;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 32px;
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1f3e6f;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  font-size: 0.875rem;
}
.ant-steps-small .ant-steps-item-title {
  font-size: 1rem;
  line-height: 24px;
  padding-right: 12px;
}
.ant-steps-small .ant-steps-item-title:after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  border-radius: 0;
  border: 0;
  background: none;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  min-height: 48px;
  overflow: hidden;
  display: block;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail:after {
  height: 100%;
  width: 1px;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title:after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  left: 12px;
  top: 0;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    min-height: 48px;
    overflow: hidden;
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
    height: 100%;
    width: 1px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title:after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    left: 12px;
    top: 0;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  padding: 0 24px;
  margin-left: 48px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 104px;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title:after {
  display: none;
}
.ant-steps-dot .ant-steps-item-title {
  line-height: 1.285;
}
.ant-steps-dot .ant-steps-item-tail {
  width: 100%;
  top: 2px;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail:after {
  height: 3px;
  width: calc(100% - 20px);
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon {
  padding-right: 0;
  width: 8px;
  height: 8px;
  line-height: 8px;
  border: 0;
  margin-left: 67px;
  background: transparent;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: relative;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: rgba(0, 0, 0, 0.001);
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  left: -26px;
}
.ant-steps-dot .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-left: 0;
  margin-top: 8px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  margin: 0;
  left: -9px;
  top: 2px;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-switch {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 1.625rem;
  min-width: 44px;
  line-height: -0.375rem;
  vertical-align: middle;
  border-radius: 100px;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.36s;
  user-select: none;
}
.ant-switch-inner {
  color: #fff;
  font-size: 0.875rem;
  margin-left: 24px;
  margin-right: 6px;
  display: block;
}
.ant-switch-loading-icon,
.ant-switch:after {
  position: absolute;
  width: -2.375rem;
  height: -2.375rem;
  left: 1px;
  top: 1px;
  border-radius: 18px;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-switch:after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:active:before,
.ant-switch:active:after {
  width: 24px;
}
.ant-switch-loading-icon {
  animation: loadingCircle 1s infinite linear;
  background: transparent;
  z-index: 1;
  display: none;
  font-size: 12px;
}
.ant-switch-loading-icon svg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: #fff;
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #46b063;
}
.ant-switch:focus {
  box-shadow: 0 0 0 2px rgba(70, 176, 99, 0.2);
  outline: 0;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  height: 16px;
  min-width: 28px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-left: 18px;
  margin-right: 3px;
  font-size: 0.875rem;
}
.ant-switch-small:after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active:before,
.ant-switch-small:active:after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-left: 3px;
  margin-right: 18px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  animation: AntSwitchSmallLoadingCircle 1s infinite linear;
  font-weight: bold;
}
.ant-switch-checked {
  background-color: #46b063;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
  margin-right: 24px;
}
.ant-switch-checked:after {
  left: 100%;
  transform: translateX(-100%);
  margin-left: -1px;
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  pointer-events: none;
  opacity: 0.4;
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg) scale(0.66667);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg) scale(0.66667);
  }
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper:before,
.ant-table-wrapper:after {
  content: "";
  display: table;
}
.ant-table-wrapper:after {
  clear: both;
}
.ant-table-wrapper:before,
.ant-table-wrapper:after {
  content: "";
  display: table;
}
.ant-table-wrapper:after {
  clear: both;
}
.ant-table {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow: auto !important;
}
.ant-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-radius: 4px 4px 0 0;
}
.ant-table-thead > tr > th {
  background: #fafafa;
  transition: background 0.3s ease;
  text-align: left;
  color: inherit;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-thead > tr > th[colspan] {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: relative;
  margin-left: 8px;
  font-size: 1rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  width: 14px;
  font-weight: normal;
  vertical-align: text-bottom;
}
.ant-table-thead > tr > th .anticon-filter:hover,
.ant-table-thead > tr > th .ant-table-filter-icon:hover {
  color: #fff;
}
.ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
  margin-left: 4px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #1f3e6f;
}
.ant-table-thead > tr > th.ant-table-column-has-filters {
  overflow: hidden;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s;
}
.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td {
  background: #a5abb0;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  padding: 16px 16px;
  background: #fafafa;
  border-radius: 0 0 4px 4px;
  position: relative;
  border-top: 1px solid #e8e8e8;
}
.ant-table-footer:before {
  content: '';
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  left: 0;
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  padding: 16px 0;
  position: relative;
  top: 1px;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-left: 16px;
  padding-right: 16px;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  word-break: break-word;
  -ms-word-break: break-all;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding-left: 16px;
  padding-right: 0;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
  min-width: 62px;
  width: 62px;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  text-align: center;
  min-width: 50px;
  width: 50px;
}
.ant-table-header {
  background: #fafafa;
  overflow: hidden;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  position: absolute;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-column-sorter {
  position: relative;
  margin-left: 8px;
  display: inline-block;
  width: 14px;
  height: 1rem;
  vertical-align: -0.125em;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  display: block;
  width: 14px;
  height: -0.5rem;
  line-height: -0.5rem;
  cursor: pointer;
  position: relative;
}
.ant-table-column-sorter-up:hover .anticon,
.ant-table-column-sorter-down:hover .anticon {
  color: #536b8a;
}
.ant-table-column-sorter-up.on .anticon-caret-up,
.ant-table-column-sorter-down.on .anticon-caret-up,
.ant-table-column-sorter-up.on .anticon-caret-down,
.ant-table-column-sorter-down.on .anticon-caret-down {
  color: #1f3e6f;
}
.ant-table-column-sorter-up:after,
.ant-table-column-sorter-down:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 14px;
  left: 0;
}
.ant-table-column-sorter-up:after {
  bottom: 0;
}
.ant-table-column-sorter-down:after {
  top: 0;
}
.ant-table-column-sorter .anticon-caret-up,
.ant-table-column-sorter .anticon-caret-down {
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  transform: scale(0.66666667) rotate(0deg);
  line-height: 4px;
  height: 4px;
  transition: all 0.3s;
  position: relative;
  display: block;
}
:root .ant-table-column-sorter .anticon-caret-up,
:root .ant-table-column-sorter .anticon-caret-down {
  font-size: 0.875rem;
}
.ant-table-column-sorter-down {
  margin-top: 1.5px;
}
.ant-table-column-sorter .anticon-caret-up {
  margin-top: 0.5px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-placeholder {
  border: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  padding: 16px 16px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
  z-index: 1;
}
.ant-table-placeholder .anticon {
  margin-right: 4px;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
  float: right;
}
.ant-table-filter-dropdown {
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  box-shadow: none;
  border-radius: 4px 4px 0 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title:after {
  color: #1f3e6f;
  font-weight: bold;
  text-shadow: 0 0 2px #969da3;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  overflow: hidden;
  padding: 7px 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #fff;
}
.ant-table-filter-dropdown-link:hover {
  color: #b6daff;
}
.ant-table-filter-dropdown-link:active {
  color: #d9cccc;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-selection-down {
  cursor: pointer;
  padding: 0;
  display: inline-block;
  line-height: 1;
}
.ant-table-selection-down:hover .anticon-down {
  color: #666;
}
.ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 14px;
  border: 1px solid #e8e8e8;
  user-select: none;
  background: #fff;
}
.ant-table-row-expanded:after {
  content: '-';
}
.ant-table-row-collapsed:after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced:after {
  content: '.';
}
.ant-table-row[class*="ant-table-row-level-0"] .ant-table-selection-column > span {
  display: inline-block;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: scroll;
  padding-bottom: 20px;
  margin-bottom: -20px;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  border-bottom: 1px solid #e8e8e8;
  top: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
  padding: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background: #fff;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-left: 0;
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  margin: 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background: #fafafa;
  margin-right: 2px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  line-height: 38px;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  background: #fff;
  border-color: #e8e8e8;
  color: #1f3e6f;
  padding-bottom: 1px;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .ant-tabs-close-x {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  font-size: 0.875rem;
  margin-left: 3px;
  margin-right: -5px;
  overflow: hidden;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  height: 1rem;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: inherit;
}
.ant-tabs.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-content > .ant-tabs-tabpane {
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #e8e8e8;
  font-size: 12px;
  color: #fff;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #1f3e6f;
  border-color: #1f3e6f;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs-vertical.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container {
  height: auto;
}
.ant-tabs-vertical.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card > .ant-tabs-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  border-right: 0;
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs.ant-tabs-card.ant-tabs-bottom > .ant-tabs-bar .ant-tabs-tab {
  border-bottom: 1px solid #e8e8e8;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}
.ant-tabs.ant-tabs-card.ant-tabs-bottom > .ant-tabs-bar .ant-tabs-tab-active {
  color: #1f3e6f;
  padding-bottom: 0;
  padding-top: 1px;
}
.ant-tabs {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs:before,
.ant-tabs:after {
  content: "";
  display: table;
}
.ant-tabs:after {
  clear: both;
}
.ant-tabs:before,
.ant-tabs:after {
  content: "";
  display: table;
}
.ant-tabs:after {
  clear: both;
}
.ant-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 1px;
  box-sizing: border-box;
  height: 2px;
  background-color: #1f3e6f;
  transform-origin: 0 0;
}
.ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 0 16px 0;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.285;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  margin-bottom: -1px;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container:before,
.ant-tabs-nav-container:after {
  content: "";
  display: table;
}
.ant-tabs-nav-container:after {
  clear: both;
}
.ant-tabs-nav-container:before,
.ant-tabs-nav-container:after {
  content: "";
  display: table;
}
.ant-tabs-nav-container:after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-left: 32px;
  padding-right: 32px;
}
.ant-tabs-bottom .ant-tabs-bar {
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.ant-tabs-bottom .ant-tabs-ink-bar {
  bottom: auto;
  top: 1px;
}
.ant-tabs-bottom .ant-tabs-nav-container {
  margin-bottom: 0;
  margin-top: -1px;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  user-select: none;
  z-index: 2;
  width: 0;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  position: absolute;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  opacity: 1;
  width: 32px;
  height: 100%;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: #fff;
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: none;
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 0.875rem;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  filter: none;
}
.ant-tabs-nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  box-sizing: border-box;
  padding-left: 0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.ant-tabs-nav:before,
.ant-tabs-nav:after {
  display: table;
  content: " ";
}
.ant-tabs-nav:after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab-disabled {
  pointer-events: none;
  cursor: default;
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-nav .ant-tabs-tab {
  display: inline-block;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  box-sizing: border-box;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  text-decoration: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #37547d;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #11254a;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
  font-size: 3rem;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1f3e6f;
  font-weight: 500;
}
.ant-tabs-large > .ant-tabs-bar .ant-tabs-nav-container {
  font-size: 1.312rem;
}
.ant-tabs-large > .ant-tabs-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs-small > .ant-tabs-bar .ant-tabs-nav-container {
  font-size: 1rem;
}
.ant-tabs-small > .ant-tabs-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content {
  width: 100%;
}
.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  transition: opacity 0.45s;
  opacity: 1;
}
.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  opacity: 0;
  height: 0;
  padding: 0 !important;
  pointer-events: none;
}
.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  will-change: margin-left;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-vertical > .ant-tabs-bar {
  border-bottom: 0;
  height: 100%;
}
.ant-tabs-vertical > .ant-tabs-bar-tab-prev,
.ant-tabs-vertical > .ant-tabs-bar-tab-next {
  width: 32px;
  height: 0;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-vertical > .ant-tabs-bar-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-vertical > .ant-tabs-bar-tab-next.ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab {
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
  display: block;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-container,
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-ink-bar {
  width: 2px;
  left: auto;
  height: auto;
  top: 0;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab-next {
  width: 100%;
  bottom: 0;
  height: 32px;
}
.ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs-vertical > .ant-tabs-content {
  overflow: hidden;
  width: auto;
  margin-top: 0 !important;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
  float: left;
  border-right: 1px solid #e8e8e8;
  margin-right: -1px;
  margin-bottom: 0;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar {
  float: right;
  border-left: 1px solid #e8e8e8;
  margin-left: -1px;
  margin-bottom: 0;
}
.ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs-vertical.ant-tabs-right > .ant-tabs-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-bottom > .ant-tabs-bar {
  margin-bottom: 0;
  margin-top: 16px;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content-animated,
.ant-tabs-vertical > .ant-tabs-content-animated {
  transform: none !important;
  margin-left: 0 !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-vertical > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  display: none;
}
.ant-tag {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  line-height: 20px;
  height: 22px;
  padding: 0 7px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 0.875rem;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  margin-right: 8px;
  cursor: pointer;
  white-space: nowrap;
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #fff;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  cursor: pointer;
  margin-left: 3px;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}
:root .ant-tag .anticon-close {
  font-size: 0.875rem;
}
.ant-tag .anticon-close:hover {
  color: inherit;
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1f3e6f;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1f3e6f;
}
.ant-tag-checkable:active {
  background-color: #11254a;
}
.ant-tag-close {
  width: 0 !important;
  padding: 0;
  margin: 0;
}
.ant-tag-zoom-enter,
.ant-tag-zoom-appear {
  animation: antFadeIn 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
.ant-tag-zoom-leave {
  animation: antZoomOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  background: #eb2f96;
  border-color: #eb2f96;
  color: #fff;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  background: #eb2f96;
  border-color: #eb2f96;
  color: #fff;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  background: #f5222d;
  border-color: #f5222d;
  color: #fff;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  background: #fa541c;
  border-color: #fa541c;
  color: #fff;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  background: #fa8c16;
  border-color: #fa8c16;
  color: #fff;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  background: #fadb14;
  border-color: #fadb14;
  color: #fff;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  background: #faad14;
  border-color: #faad14;
  color: #fff;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  background: #13c2c2;
  border-color: #13c2c2;
  color: #fff;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  background: #a0d911;
  border-color: #a0d911;
  color: #fff;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  background: #52c41a;
  border-color: #52c41a;
  color: #fff;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  background: #2f54eb;
  border-color: #2f54eb;
  color: #fff;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  background: #722ed1;
  border-color: #722ed1;
  color: #fff;
}
.ant-time-picker-panel {
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  z-index: 1050;
  position: absolute;
}
.ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 1rem;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  left: -2px;
}
.ant-time-picker-panel-input {
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 154px;
  cursor: auto;
  outline: 0;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: red;
}
.ant-time-picker-panel-clear-btn {
  position: absolute;
  right: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}
.ant-time-picker-panel-clear-btn-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  transition: color 0.3s ease;
}
.ant-time-picker-panel-clear-btn-icon svg:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  float: left;
  font-size: 1rem;
  border-left: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  max-height: 192px;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 160px;
  width: 100%;
}
.ant-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 12px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s;
}
.ant-time-picker-panel-select li:hover {
  background: #b6daff;
}
li.ant-time-picker-panel-select-option-selected {
  background: #f5f5f5;
  font-weight: bold;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox:before,
.ant-time-picker-panel-combobox:after {
  content: "";
  display: table;
}
.ant-time-picker-panel-combobox:after {
  clear: both;
}
.ant-time-picker-panel-combobox:before,
.ant-time-picker-panel-combobox:after {
  content: "";
  display: table;
}
.ant-time-picker-panel-combobox:after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-time-picker {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  transition: opacity 0.3s;
  width: 128px;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 2.75rem;
  font-size: 1rem;
  line-height: 1.285;
  color: #fff;
  background-color: transparent;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #585f6d;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #585f6d;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #585f6d;
}
.ant-time-picker-input:hover {
  border-color: #def0ff;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #f7ffb3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(232, 248, 133, 0.2);
  border-right-width: 1px !important;
}
.ant-time-picker-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-time-picker-input-disabled:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  min-height: 2.75rem;
}
.ant-time-picker-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-time-picker-input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-time-picker-input[disabled] {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #fff0f0;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon {
  position: absolute;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  right: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px;
}
.ant-time-picker-icon .ant-time-picker-clock-icon {
  color: rgba(0, 0, 0, 0.25);
  display: block;
  line-height: 1;
}
.ant-time-picker-large .ant-time-picker-input {
  padding: 6px 11px;
  height: 40px;
  font-size: 1.312rem;
}
.ant-time-picker-small .ant-time-picker-input {
  padding: 1px 7px;
  height: 24px;
}
.ant-time-picker-small .ant-time-picker-icon {
  right: 7px;
}
.ant-timeline {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-timeline-item {
  position: relative;
  padding: 0 0 20px;
  list-style: none;
  margin: 0;
  font-size: 1rem;
}
.ant-timeline-item-tail {
  position: absolute;
  left: 4px;
  top: 0.75em;
  height: 100%;
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 0.875rem;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100px;
  border: 2px solid transparent;
}
.ant-timeline-item-head-blue {
  border-color: #1f3e6f;
  color: #1f3e6f;
}
.ant-timeline-item-head-red {
  border-color: #c01300;
  color: #c01300;
}
.ant-timeline-item-head-green {
  border-color: #46b063;
  color: #46b063;
}
.ant-timeline-item-head-custom {
  position: absolute;
  text-align: center;
  line-height: 1;
  margin-top: 0;
  border: 0;
  height: auto;
  border-radius: 0;
  padding: 3px 1px;
  transform: translate(-50%, -50%);
  top: 5.5px;
  left: 5px;
  width: auto;
}
.ant-timeline-item-content {
  margin: 0 0 0 18px;
  position: relative;
  top: 0.715rem;
}
.ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  text-align: left;
  left: 50%;
  width: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  text-align: right;
  right: 50%;
  margin-right: 18px;
  width: 50%;
  left: -30px;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: 100%;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 0;
  width: 100%;
  left: -30px;
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-left: 2px dotted #e8e8e8;
  display: block;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: 2px dotted #e8e8e8;
  display: block;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-tooltip {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 1060;
  display: block;
  visibility: visible;
  max-width: 162px;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  padding: 6px 8px;
  color: #111;
  text-align: left;
  text-decoration: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-height: 32px;
  word-wrap: break-word;
}
.ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}
.ant-transfer {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.ant-transfer-list {
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  width: 180px;
  height: 200px;
  padding-top: 34px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 2.75rem;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  padding: 6px 12px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: #fff;
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-body {
  font-size: 1rem;
  position: relative;
  height: 100%;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px;
  width: 100%;
}
.ant-transfer-list-body-with-search {
  padding-top: 10.75rem;
}
.ant-transfer-list-content {
  height: 100%;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ant-transfer-list-content > .LazyLoad {
  animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px 12px;
  min-height: 32px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: pointer;
  background-color: #b6daff;
}
.ant-transfer-list-content-item-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-body-not-found {
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  display: none;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -10px;
}
.ant-transfer-list-content:empty + .ant-transfer-list-body-not-found {
  display: block;
}
.ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.ant-transfer-operation {
  display: inline-block;
  overflow: hidden;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@keyframes transferHighlightIn {
  0% {
    background: #969da3;
  }
  100% {
    background: transparent;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  user-select: none;
  border-radius: 0;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover:before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover:before {
  background: #b6daff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 24px;
  transition: all 0.3s;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #1f3e6f;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked:after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked:after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  border-color: #1f3e6f;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper:before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper:before {
  background: #1f3e6f;
}
.ant-tree-checkbox {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1f3e6f;
}
.ant-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #1f3e6f;
  content: '';
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}
.ant-tree-checkbox:hover:after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  opacity: 0;
}
.ant-tree-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  content: ' ';
  transform: translate(-50%, -50%) scale(1);
  border: 0;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: #1f3e6f;
  opacity: 1;
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  opacity: 1;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1f3e6f;
  border-color: #1f3e6f;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  animation-name: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  animation-name: none;
  border-color: #f5f5f5;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper + span,
.ant-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-tree-checkbox-group {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-tree ol,
.ant-tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-tree li {
  padding: 4px 0;
  margin: 0;
  list-style: none;
  white-space: nowrap;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable="true"] {
  user-select: none;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  line-height: 20px;
}
.ant-tree li.drag-over > span[draggable] {
  background-color: #1f3e6f;
  color: white;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #1f3e6f;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #1f3e6f;
}
.ant-tree li.filter-node > span {
  color: #e64230 !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  color: #1f3e6f;
  transform: none;
  font-size: 14px;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open:after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close:after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  padding: 0 5px;
  border-radius: 2px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  vertical-align: top;
  color: #fff;
  transition: all 0.3s;
  height: 24px;
  line-height: 24px;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #b6daff;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #969da3;
}
.ant-tree li span.ant-tree-checkbox {
  margin: 4px 4px 0 2px;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: top;
  border: 0 none;
  cursor: pointer;
  outline: none;
  text-align: center;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher:before,
.ant-tree li:last-child > span.ant-tree-iconEle:before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree {
  display: none;
}
.ant-tree-child-tree-open {
  display: block;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: #fff;
  color: rgba(0, 0, 0, 0.45);
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  display: inline-block;
  font-weight: normal;
}
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  display: inline-block;
  font-weight: normal;
}
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  display: inline-block;
  font-weight: normal;
}
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child):before {
  content: ' ';
  width: 1px;
  border-left: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  left: 12px;
  margin: 22px 0;
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-select-tree-checkbox {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1f3e6f;
}
.ant-select-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #1f3e6f;
  content: '';
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}
.ant-select-tree-checkbox:hover:after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  opacity: 0;
}
.ant-select-tree-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  content: ' ';
  transform: translate(-50%, -50%) scale(1);
  border: 0;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: #1f3e6f;
  opacity: 1;
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  opacity: 1;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1f3e6f;
  border-color: #1f3e6f;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  animation-name: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  border-color: #d9d9d9 !important;
  background-color: #f5f5f5;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  animation-name: none;
  border-color: #f5f5f5;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper + span,
.ant-select-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-select-tree-checkbox-group {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0 4px;
  margin-top: -4px;
}
.ant-select-tree li {
  padding: 0;
  margin: 8px 0;
  list-style: none;
  white-space: nowrap;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 2px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s;
  width: calc(100% - 24px);
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #b6daff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #969da3;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 22px;
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  cursor: pointer;
  outline: none;
  text-align: center;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  display: inline-block;
  position: absolute;
  left: 0;
  color: #1f3e6f;
  transform: none;
  font-size: 14px;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 0.875rem;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  color: #1f3e6f;
  transform: none;
  font-size: 14px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  display: block;
  padding: 4px;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  padding: 4px 7px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  padding: 7px 16px;
  display: block;
}
.ant-upload {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type="file"] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
  display: table;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  width: 100%;
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1f3e6f;
}
.ant-upload.ant-upload-drag {
  border: 1px dashed #d9d9d9;
  transition: border-color 0.3s;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: #fafafa;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border: 2px dashed #37547d;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #37547d;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 48px;
  color: #37547d;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 1.312rem;
  margin: 0 0 4px;
  color: inherit;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag .anticon-plus {
  font-size: 30px;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.25);
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 1.285;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  zoom: 1;
}
.ant-upload-list:before,
.ant-upload-list:after {
  content: "";
  display: table;
}
.ant-upload-list:after {
  clear: both;
}
.ant-upload-list:before,
.ant-upload-list:after {
  content: "";
  display: table;
}
.ant-upload-list:after {
  clear: both;
}
.ant-upload-list-item {
  margin-top: 8px;
  font-size: 1rem;
  position: relative;
  height: 22px;
}
.ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 9rem;
  width: 100%;
  display: inline-block;
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: -1.5rem;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 0.875rem;
}
.ant-upload-list-item .anticon-close:hover {
  color: #fff;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #b6daff;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #c01300;
}
.ant-upload-list-item-error .anticon-close {
  opacity: 1;
  color: #c01300 !important;
}
.ant-upload-list-item-progress {
  line-height: 0;
  font-size: 1rem;
  position: absolute;
  width: 100%;
  bottom: -12px;
  padding-left: 13rem;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: 66px;
  position: relative;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #c01300;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 8px;
  left: 8px;
  text-align: center;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail.anticon:before,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail.anticon:before {
  line-height: 48px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 8px;
  line-height: 44px;
  transition: all 0.3s;
  padding-left: 48px;
  padding-right: 8px;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 56px;
  margin-top: 0;
  bottom: 14px;
  width: calc(100% - 24px);
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card {
  float: left;
}
.ant-upload-list-picture-card.ant-upload-list:after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 16px;
  width: 16px;
  color: #13213a;
  margin: 0 4px;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  position: static;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
  text-align: center;
  line-height: 1.285;
  display: none;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 0;
  bottom: 32px;
}
.ant-upload-list .ant-upload-success-icon {
  color: #46b063;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.list-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-unstyled li {
  display: block;
  margin: 0;
  padding: 0;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.ttf?ur8tdy') format('truetype'), url('../fonts/icomoon/icomoon.woff?ur8tdy') format('woff'), url('../fonts/icomoon/icomoon.svg?ur8tdy#icomoon') format('svg');
  font-weight: 300;
  font-style: normal;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flip-icon:before {
  display: block;
  transform: rotate(180deg);
}
.icon-minus-square:before {
  content: "\e910";
}
.icon-plus-square:before {
  content: "\e911";
}
.icon-arrow-thin:before {
  content: "\e90f";
}
.icon-fullscreen:before {
  content: "\e90e";
}
.icon-plus-circle:before {
  content: "\e908";
}
.icon-grid-2:before {
  content: "\e900";
}
.icon-grid-4:before {
  content: "\e901";
}
.icon-grid-6:before {
  content: "\e902";
}
.icon-camera:before {
  content: "\e903";
}
.icon-exit:before {
  content: "\e904";
}
.icon-movement-controls:before {
  content: "\e905";
}
.icon-play:before {
  content: "\e906";
}
.icon-live:before {
  content: "\e907";
}
.icon-next:before {
  content: "\e909";
}
.icon-pause:before {
  content: "\e90a";
}
.icon-skip:before {
  content: "\e90b";
}
.icon-arrow:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-reverse:before {
  content: "\e906";
}
.icon-reverse:before {
  display: block;
  transform: rotate(180deg);
}
.icon-prev:before {
  content: "\e909";
}
.icon-prev:before {
  display: block;
  transform: rotate(180deg);
}
.icon-skip-back:before {
  content: "\e90b";
}
.icon-skip-back:before {
  display: block;
  transform: rotate(180deg);
}
.icon-close:before {
  content: "\e908";
}
.icon-close:before {
  display: block;
  transform: rotate(45deg);
}
.icon-plus:before {
  content: "\e904";
}
.icon-plus:before {
  display: block;
  transform: rotate(45deg);
}
.icon-up-arrow:before,
.icon-down-arrow:before,
.icon-left-arrow:before,
.icon-right-arrow:before {
  content: "\e90c";
}
.icon-up-arrow:before,
.icon-down-arrow:before,
.icon-left-arrow:before,
.icon-right-arrow:before {
  display: block;
}
.icon-up-arrow-thin:before,
.icon-down-arrow-thin:before,
.icon-left-arrow-thin:before,
.icon-right-arrow-thin:before {
  content: "\e90f";
}
.icon-up-arrow-thin:before,
.icon-down-arrow-thin:before,
.icon-left-arrow-thin:before,
.icon-right-arrow-thin:before {
  display: block;
}
.icon-up-arrow:before,
.icon-up-arrow-thin:before {
  transform: rotate(-90deg);
}
.icon-down-arrow:before,
.icon-down-arrow-thin:before {
  transform: rotate(90deg);
}
.icon-left-arrow:before,
.icon-left-arrow-thin:before {
  display: block;
  transform: rotate(180deg);
}
.ant-spin .ant-spin-dot i {
  background-color: #585f6d;
}
.ant-spin-theme-dark .ant-spin-dot i {
  background-color: #fff;
}
.ant-spin-nested-loading > div > .ant-spin-theme-dark .ant-spin-text {
  color: #fff;
  text-shadow: none;
}
#app {
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden;
  --camera-aspect-ratio: 0.715;
  background: url("../images/background-lg.png") 50% 50% no-repeat;
  background-size: cover;
}
@media screen and (min-width: 2560px) {
  body {
    background-image: url("../images/background-xl.png");
  }
}
.container {
  width: 90%;
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.page-header {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  height: 4rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.site-layout {
  height: 100vh;
  min-height: 42.5rem;
  overflow: hidden;
  display: flex;
}
.site-layout > .ant-layout,
.site-layout > .ant-layout-content {
  display: flex;
}
.site-layout .ant-layout-header + .ant-layout-content {
  max-height: calc(100vh -  4.375rem);
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content {
  width: 100%;
  margin: 0;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content > * {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content:not(.camera-view-page),
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content:not(.camera-view-page) > *,
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content:not(.camera-view-page) > * > * {
  max-height: inherit;
}
.site-layout > .ant-layout-content {
  display: flex;
  flex-direction: column;
}
.site-layout > .ant-layout-content .ant-layout-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.site-layout > .ant-layout-content .ant-layout-content > .ant-spin {
  display: block;
  margin: 3.125rem auto;
}
.site-layout > .ant-layout-content .ant-layout-content {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.site-layout > .ant-layout-content .ant-layout-content > .ant-layout {
  display: flex;
  flex: auto;
}
.ant-layout-has-sider > .ant-layout-content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.container .ant-layout-has-sider > .ant-layout-content {
  padding-left: 0;
  padding-right: 0;
}
.ant-layout-sider-light {
  background: transparent;
}
.ant-row {
  display: flex;
  flex-wrap: wrap;
}
.ant-row:before,
.ant-row:after {
  display: none;
}
.ant-spin-container:before,
.ant-spin-container:after {
  display: none;
}
.full-height {
  display: flex;
  flex-direction: column;
}
body {
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.312rem;
}
h3 {
  font-size: 1rem;
  text-transform: uppercase;
}
h4 {
  font-size: 0.8125rem;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
ol:last-child,
ul:last-child,
p:last-child {
  margin-bottom: 0;
}
.page-title {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 300;
}
.copyright {
  font-size: 0.75rem;
}
.disabled,
[disabled] {
  opacity: 0.3;
}
.error-message {
  font-size: 0.875rem;
}
.error-message-text:not(:last-child) {
  margin-right: 0.25em;
}
.logo {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 5.0625rem;
  height: 3.125rem;
  background-image: url('../images/logo-dw.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
.logo-size-lg {
  width: 11.875rem;
  height: 7.1875rem;
}
.ant-layout-sider .logo {
  margin: 0 auto 0.9375rem;
}
.logo-app-name {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 11.0625rem;
  height: 2.375rem;
  background-image: url('../images/app-name.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.logo-company {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 15.8125rem;
  height: 3.875rem;
  background-image: url('../images/logo-company.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.branding .title {
  margin: 0 auto;
}
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
}
.ant-btn:focus,
.ant-btn:hover,
.ant-btn:active {
  color: #13213a;
}
.ant-btn:focus {
  box-shadow: 0 0 1px 2px #e8f885;
}
.ant-btn:hover,
.ant-btn:active {
  background: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-btn:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.ant-btn:active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.ant-btn[disabled] {
  opacity: 0.3;
}
.ant-btn-group {
  display: flex;
}
.ant-btn-has-icon {
  display: inline-flex;
  align-items: center;
}
.ant-btn .icon:first-child {
  margin-right: 0.4167em;
}
.ant-btn .icon:last-child {
  margin-right: 0;
}
.ant-btn .icon:last-child:not(:first-child) {
  margin-left: auto;
}
.ant-btn-sm {
  font-size: 0.75rem;
}
.ant-btn-primary:focus {
  background: #fdcf7e;
}
.ant-btn-primary:hover,
.ant-btn-primary:active {
  background: #fff;
  border-color: #fff;
}
.ant-btn-dark {
  background: #234982;
  color: #fff;
  border: 0;
}
.ant-btn-dark:focus,
.ant-btn-dark:hover,
.ant-btn-dark:active {
  background: #234982;
  color: #fff;
}
.ant-btn-light,
.ant-btn-light-ghost,
.ant-btn-outline,
.ant-btn-light:hover,
.ant-btn-light-ghost:hover,
.ant-btn-outline:hover,
.ant-btn-light:focus,
.ant-btn-light-ghost:focus,
.ant-btn-outline:focus,
.ant-btn-light:active,
.ant-btn-light-ghost:active,
.ant-btn-outline:active {
  box-shadow: none;
  border-width: 1px;
}
.ant-btn-light,
.ant-btn-light-ghost {
  color: #13213a;
  background: #fff;
}
.ant-btn-light,
.ant-btn-light-ghost,
.ant-btn-light:hover,
.ant-btn-light-ghost:hover,
.ant-btn-light:focus,
.ant-btn-light-ghost:focus,
.ant-btn-light:active,
.ant-btn-light-ghost:active,
.ant-btn-light.active,
.ant-btn-light-ghost.active {
  box-shadow: none;
  border-color: #13213a;
}
.ant-btn-light:hover,
.ant-btn-light-ghost:hover,
.ant-btn-light:focus,
.ant-btn-light-ghost:focus {
  color: #13213a;
  background: #b6daff;
}
.ant-btn-light[disabled],
.ant-btn-light-ghost[disabled],
.ant-btn-light[disabled]:hover,
.ant-btn-light-ghost[disabled]:hover,
.ant-btn-light[disabled]:focus,
.ant-btn-light-ghost[disabled]:focus,
.ant-btn-light[disabled]:active,
.ant-btn-light-ghost[disabled]:active,
.ant-btn-light[disabled]:active,
.ant-btn-light-ghost[disabled]:active {
  color: #13213a;
  background: #fff;
}
.ant-btn-light-ghost {
  background: transparent;
}
.ant-btn-light-ghost:hover,
.ant-btn-light-ghost:focus {
  color: #fff;
  background: #13213a;
}
.ant-btn-light-ghost[disabled],
.ant-btn-light-ghost[disabled]:hover,
.ant-btn-light-ghost[disabled]:focus,
.ant-btn-light-ghost[disabled]:active,
.ant-btn-light-ghost[disabled]:active {
  background: transparent;
}
.ant-btn-outline {
  color: #fff;
  background: transparent;
}
.ant-btn-outline,
.ant-btn-outline:hover,
.ant-btn-outline:focus,
.ant-btn-outline:active,
.ant-btn-outline.active {
  border-color: #fff;
}
.ant-btn-outline[disabled],
.ant-btn-outline[disabled]:hover,
.ant-btn-outline[disabled]:focus,
.ant-btn-outline[disabled]:active,
.ant-btn-outline[disabled]:active {
  color: #fff;
  background: transparent;
}
.ant-btn-outline:hover,
.ant-btn-outline:focus {
  color: #13213a;
  background: #fff;
}
.ant-btn-progress {
  display: flex;
  justify-content: space-around;
}
.ant-btn-progress.ant-btn-loading {
  flex-direction: row-reverse;
}
.ant-btn-progress.ant-btn-loading i svg {
  width: 1.5rem;
  height: 1.5rem;
}
.ant-btn-progress.ant-btn-loading.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 1rem;
}
.ant-btn-progress.ant-btn-loading.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) i,
.ant-btn-progress.ant-btn-loading.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) i + span {
  margin-left: 0;
}
.ant-btn-progress .ant-progress-circle-trail {
  stroke: rgba(182, 218, 255, 0.25);
}
.ant-btn-link,
.ant-btn-link-alt {
  font: inherit;
}
.ant-btn-link,
.ant-btn-link-alt,
.ant-btn-link:hover,
.ant-btn-link-alt:hover,
.ant-btn-link:focus,
.ant-btn-link-alt:focus,
.ant-btn-link:active,
.ant-btn-link-alt:active,
.ant-btn-link.active,
.ant-btn-link-alt.active,
.ant-btn-link[disabled],
.ant-btn-link-alt[disabled] {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;
}
.ant-btn-link.ant-btn-sm,
.ant-btn-link-alt.ant-btn-sm,
.ant-btn-link:hover.ant-btn-sm,
.ant-btn-link-alt:hover.ant-btn-sm,
.ant-btn-link:focus.ant-btn-sm,
.ant-btn-link-alt:focus.ant-btn-sm,
.ant-btn-link:active.ant-btn-sm,
.ant-btn-link-alt:active.ant-btn-sm,
.ant-btn-link.active.ant-btn-sm,
.ant-btn-link-alt.active.ant-btn-sm,
.ant-btn-link[disabled].ant-btn-sm,
.ant-btn-link-alt[disabled].ant-btn-sm {
  font-size: 0.75rem;
}
.ant-btn-link:hover:not([disabled]),
.ant-btn-link:focus:not([disabled]),
.ant-btn-link:active:not([disabled]),
.ant-btn-link.active:not([disabled]) {
  color: #b6daff;
}
.ant-btn-link.ant-btn-background-ghost {
  border: 1px solid #fff;
}
.ant-btn-link.ant-btn-background-ghost:hover:not([disabled]),
.ant-btn-link.ant-btn-background-ghost:focus:not([disabled]),
.ant-btn-link.ant-btn-background-ghost:active:not([disabled]),
.ant-btn-link.ant-btn-background-ghost.active:not([disabled]) {
  color: #b6daff;
  border-color: #b6daff;
}
.ant-btn-link-alt,
.ant-btn-link-alt[disabled] {
  color: #b6daff;
}
.ant-btn-link-alt:hover,
.ant-btn-link-alt:focus,
.ant-btn-link-alt:active {
  color: #fff;
}
.ant-btn-link-alt.ant-btn-background-ghost {
  border: 1px solid #b6daff;
}
.ant-btn-link-alt.ant-btn-background-ghost:hover,
.ant-btn-link-alt.ant-btn-background-ghost:focus,
.ant-btn-link-alt.ant-btn-background-ghost:active {
  color: #fff;
  border-color: #fff;
}
.form-title {
  margin-bottom: 1.875rem;
  text-align: center;
}
.ant-form label {
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
}
.ant-form-vertical .ant-row {
  display: block;
}
.ant-form-item {
  margin-bottom: 1.25rem;
  color: inherit;
}
.ant-form-item-control-wrapper {
  width: 100%;
}
.ant-form-item-label-hidden > .ant-form-item-label {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.ant-form-item-control:before,
.ant-form-item-control:after {
  display: none;
}
.form-actions {
  margin-top: 1.875rem;
}
.ant-input {
  padding: 0.625rem;
}
.ant-input::-moz-placeholder {
  font-weight: 300;
  font-style: italic;
}
.ant-input:-ms-input-placeholder {
  font-weight: 300;
  font-style: italic;
}
.ant-input::-webkit-input-placeholder {
  font-weight: 300;
  font-style: italic;
}
.ant-input-affix-wrapper,
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  color: inherit;
}
.ant-switch:after {
  top: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
}
.form-dark {
  color: #13213a;
}
.form-dark .ant-input {
  color: #13213a;
  border-color: #13213a;
}
.form-dark .ant-input:hover,
.form-dark .ant-input:active,
.form-dark .ant-input:focus {
  border-color: #000;
}
.form-dark .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #13213a;
}
.ant-collapse {
  background: transparent;
  border-radius: 0;
  border: 0;
}
.ant-collapse-content {
  font-size: 1rem;
}
.ant-collapse > .ant-collapse-item {
  border-bottom-color: #234982;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-size: 1.125rem;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header {
  position: relative;
  padding-left: 3.3125rem;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* Better Font Rendering =========== */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90c";
  display: block;
  position: absolute;
  left: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  transform: rotate(90deg);
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header[aria-expanded="true"]:before {
  transform: rotate(-90deg);
}
.ant-collapse > .ant-collapse-item--invisible-header {
  background: transparent;
}
.ant-collapse > .ant-collapse-item--invisible-header > .ant-collapse-header {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.ant-collapse > .ant-collapse-item--invisible-header .ant-collapse-content {
  background: transparent;
  color: #fff;
  border: 0;
}
.ant-collapse > .ant-collapse-item--invisible-header .ant-collapse-content > .ant-collapse-content-box {
  padding: 1.875rem;
  padding-left: 3.3125rem;
  line-height: 1.375;
}
.ant-collapse .ant-collapse-item:last-child,
.ant-collapse .ant-collapse-item:last-child .ant-collapse-content {
  border-radius: 0;
}
.ant-card,
.ant-card-head-title {
  color: inherit;
}
.ant-card-head {
  padding: 0 1.125rem;
}
.ant-card-body {
  padding: 1.125rem;
}
.ant-card-head {
  border: 0;
}
.ant-card-head + .ant-card-body {
  padding-top: 0;
}
.ant-card-head-title {
  padding-top: 1rem;
  padding-bottom: 0.625rem;
}
.ant-card-head-title h2 {
  font-size: 1.125rem;
  font-weight: 500;
}
.ant-card-meta-title,
.ant-card-meta-description {
  color: inherit;
}
.ant-card-link,
.ant-card-link:hover,
.ant-card-link:focus,
.ant-card-link:active {
  text-decoration: none;
}
.ant-card-dark {
  color: #fff;
  background: rgba(19, 33, 58, 0.39);
}
.ant-popover {
  max-width: 20.125rem;
  color: #13213a;
  min-width: 10.125rem;
}
.ant-popover-title {
  padding: 0.625rem 0.9375rem 0.3125rem;
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: 0;
}
.ant-popover-inner-content {
  padding: 0.9375rem;
  font-size: 0.9375rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
}
.ant-popover .ant-menu {
  margin: -0.9375rem;
  overflow: hidden;
  font-size: inherit;
  border-radius: 4px;
}
.ant-popover .ant-menu.ant-menu-light {
  color: #13213a;
}
.ant-popover .ant-menu.ant-menu-vertical {
  border: 0;
}
.ant-popover .ant-menu.ant-menu-vertical .ant-menu-item {
  margin: 0;
}
.ant-popover .ant-menu.ant-menu-pop-confirm {
  width: 10.125rem;
}
.ant-popover .ant-menu-item {
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 0.889rem;
  line-height: 1.2;
}
.ant-popover .ant-menu-item > a,
.ant-popover .ant-menu-item > .ant-btn-link {
  display: block;
  margin: 0;
  padding: 0.75rem 0.625rem;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #151515;
}
.ant-popover .ant-menu-item > a:hover,
.ant-popover .ant-menu-item > .ant-btn-link:hover,
.ant-popover .ant-menu-item > a:focus,
.ant-popover .ant-menu-item > .ant-btn-link:focus,
.ant-popover .ant-menu-item > a:active,
.ant-popover .ant-menu-item > .ant-btn-link:active {
  text-decoration: none;
}
.ant-popover .ant-menu-item > a:hover,
.ant-popover .ant-menu-item > .ant-btn-link:hover {
  background: #b6daff;
}
.ant-popover .ant-menu-item-group-title {
  padding: 0.5625rem 0.875rem;
  color: inherit;
  font-size: inherit;
  text-align: left;
}
.ant-modal {
  color: #13213a;
}
.ant-modal form,
.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.ant-modal-header {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  color: inherit;
  text-align: center;
}
.ant-modal-header,
.ant-modal-footer {
  border: 0;
}
.ant-modal-title {
  font-weight: 400;
}
.ant-modal form,
.ant-modal-footer {
  padding-bottom: 1.875rem;
}
.ant-modal form .ant-btn,
.ant-modal-footer .ant-btn {
  min-width: 8.6875rem;
}
.ant-modal-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.ant-modal-body form {
  margin: 0 -1.875rem -1.5rem;
}
.ant-modal-body form .form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.75rem;
}
.ant-modal-body form .form-actions .ant-btn {
  margin-right: 0.625rem;
}
.ant-modal-body form .form-actions .ant-btn:last-child {
  margin-right: 0;
}
.ant-modal .ant-modal-close {
  top: 0.3125rem;
  right: 0.3125rem;
  color: #13213a;
}
.ant-modal .ant-modal-close-x {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* Better Font Rendering =========== */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 2.0625rem;
  height: 2.0625rem;
  padding: 0.3125rem;
}
.ant-modal .ant-modal-close-x:before {
  content: "\e908";
}
.ant-modal .ant-modal-close-x:before {
  display: block;
  transform: rotate(45deg);
}
.ant-modal .ant-modal-close-icon {
  display: none;
}
.ant-btn.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}
.ant-btn.ant-dropdown-trigger.ant-btn-link {
  font-weight: 400;
}
.ant-btn.ant-dropdown-trigger .btn-text--open {
  display: none;
}
.dropdown-container.active .ant-btn.ant-dropdown-trigger.ant-btn-dark {
  background: #13213a;
  color: #fff;
  box-shadow: none;
}
.dropdown-container.active .ant-btn.ant-dropdown-trigger.ant-btn-dark .btn-text--open {
  display: block;
}
.dropdown-container.active .ant-btn.ant-dropdown-trigger.ant-btn-dark .btn-text--closed {
  display: none;
}
.dropdown-container.active .ant-btn.ant-dropdown-trigger {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-container.active .ant-btn.ant-dropdown-trigger .icon-down-arrow:before {
  transform: rotate(-90deg);
}
.ant-btn.ant-dropdown-trigger .icon-down-arrow {
  flex-shrink: 0;
  font-size: 1.5rem;
}
.ant-btn.ant-dropdown-trigger.ant-btn-sm {
  padding-right: 0;
  padding-left: 0.375rem;
}
.ant-dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid #13213a;
}
.dropdown-container .ant-dropdown-menu {
  top: -1px;
}
.ant-dropdown-menu-light {
  color: #13213a;
}
.ant-dropdown-menu-light .ant-dropdown-menu-item + .ant-dropdown-menu-item {
  border-top: 1px solid #13213a;
}
.ant-dropdown-menu-item {
  padding: 0;
  font-size: inherit;
  color: inherit;
}
.ant-dropdown-menu-item .ant-btn-link:hover:not([disabled]),
.ant-dropdown-menu-item .ant-btn-link:focus:not([disabled]),
.ant-dropdown-menu-item .ant-btn-link:active:not([disabled]),
.ant-dropdown-menu-item .ant-btn-link.active:not([disabled]) {
  color: #13213a;
}
.ant-dropdown-menu-item a,
.ant-dropdown-menu-item .ant-btn {
  display: block;
  min-width: 100%;
  height: auto;
  text-align: left;
}
.ant-dropdown-sm {
  font-size: 0.75rem;
}
.ant-dropdown-sm .ant-dropdown-menu-item a,
.ant-dropdown-sm .ant-dropdown-menu-item .ant-btn {
  padding: 0.125rem 0.375rem;
}
.dropdown-container {
  position: relative;
}
.dropdown-container > div {
  top: 100% !important;
}
.dropdown-container > div .ant-dropdown {
  top: 0 !important;
}
.dropdown-container .ant-dropdown {
  min-width: 100%;
}
.dropdown-container .ant-dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-container .ant-dropdown-trigger {
  width: 100%;
}
.ant-list {
  color: inherit;
}
.ant-list [class*="ant-col-"],
.ant-list [class*="ant-col-"] .ant-list-item {
  display: flex;
}
.ant-list [class*="ant-col-"] .ant-list-item {
  width: 100%;
}
.ant-list [class*="ant-col-"] .ant-list-item-content {
  width: 100%;
  height: 100%;
}
.ant-list-cards .ant-list-item-content {
  display: flex;
}
.ant-list-item-meta-title,
.ant-list-item-meta-description {
  color: inherit;
}
.ant-list-item-meta-title {
  line-height: 1.2;
}
.ant-list-item-meta-description {
  font-size: 0.6875rem;
  font-weight: 400;
}
.ant-list-item-link,
.ant-list-item-button {
  display: block;
  text-align: left;
  color: inherit;
}
.ant-list-item-button {
  height: auto;
}
.ant-list-item-action {
  margin: 0 1.125rem 0 0;
}
.ant-list-item-action > li:last-child {
  padding-right: 0;
}
.ant-list-item-action .dropdown-container {
  width: 5.1875rem;
}
.ant-list .ant-list-item.ant-list-item--block {
  padding: 0;
  z-index: auto;
}
.ant-list .ant-list-item.ant-list-item--block:hover,
.ant-list .ant-list-item.ant-list-item--block:focus {
  background: #b6daff;
}
.ant-list .ant-list-item.ant-list-item--block,
.ant-list .ant-list-item.ant-list-item--block:last-child {
  border: 2px solid #dbdfe5;
  border-radius: 4px;
}
.ant-list .ant-list-item.ant-list-item--block + .ant-list-item--block {
  margin-top: 0.625rem;
}
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-content {
  position: relative;
  width: 100%;
}
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-link {
  width: 100%;
  padding: 0.6875rem 0.6875rem 0.6875rem 1.125rem;
}
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-link,
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-link:hover,
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-link:focus,
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-link:active {
  color: inherit;
  text-decoration: none;
  background: transparent;
}
.ant-list .ant-list-item.ant-list-item--block .dropdown-container.active {
  z-index: 9999;
}
.ant-list .ant-list-item.ant-list-item--block .ant-list-item-meta-title {
  margin-bottom: 0;
}
.ant-list-item-inactive {
  background: #dbdfe5;
}
.ant-list-item-active {
  background: #b6daff;
}
.ant-list .ant-list-item {
  border: 0;
}
.ant-list .ant-list-header {
  border: 0;
}
.list-panel-header + .ant-list {
  margin-top: 0.75rem;
}
.ant-list-empty-text {
  text-align: left;
  color: inherit;
}
.ant-tooltip {
  font-size: 0.9375rem;
  font-weight: 400;
}
.ant-tooltip-inner {
  padding: 0.5rem 0.875rem;
  border-radius: 2px;
}
.ant-alert {
  padding-left: 2rem;
  padding-right: 0.625rem;
  font-size: 0.75rem;
  color: #13213a;
}
.ant-alert-icon {
  left: 0.625rem;
  font-size: 1.125rem;
}
.ant-alert ul {
  list-style-type: none;
  padding: 0;
}
.ant-message {
  top: 5.375rem;
}
.ant-message .ant-message-notice {
  color: #13213a;
}
.ant-calendar-picker .ant-calendar-picker-input,
.ant-calendar-picker:hover .ant-calendar-picker-input {
  line-height: 1.285;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 1rem;
  border-radius: 4px;
  height: 2.75rem;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
}
.ant-calendar-picker .ant-calendar-picker-input > .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input > .anticon {
  line-height: 1;
}
.ant-calendar-picker .ant-calendar-picker-input,
.ant-calendar-picker:hover .ant-calendar-picker-input,
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active,
.ant-calendar-picker .ant-calendar-picker-input:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus {
  outline: 0;
}
.ant-calendar-picker .ant-calendar-picker-input:not([disabled]):hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar-picker .ant-calendar-picker-input:not([disabled]):active,
.ant-calendar-picker:hover .ant-calendar-picker-input:not([disabled]):active {
  outline: 0;
  transition: none;
}
.ant-calendar-picker .ant-calendar-picker-input.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled,
.ant-calendar-picker .ant-calendar-picker-input[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-picker .ant-calendar-picker-input.disabled > *,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled > *,
.ant-calendar-picker .ant-calendar-picker-input[disabled] > *,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled] > * {
  pointer-events: none;
}
.ant-calendar-picker .ant-calendar-picker-input-lg,
.ant-calendar-picker:hover .ant-calendar-picker-input-lg {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 4px;
  height: 3.625rem;
}
.ant-calendar-picker .ant-calendar-picker-input-sm,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  height: 1.625rem;
}
.ant-calendar-picker .ant-calendar-picker-input > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover,
.ant-calendar-picker .ant-calendar-picker-input:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-calendar-picker .ant-calendar-picker-input:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active,
.ant-calendar-picker .ant-calendar-picker-input.active,
.ant-calendar-picker:hover .ant-calendar-picker-input.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-calendar-picker .ant-calendar-picker-input:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled,
.ant-calendar-picker .ant-calendar-picker-input[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled],
.ant-calendar-picker .ant-calendar-picker-input.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover,
.ant-calendar-picker .ant-calendar-picker-input:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus,
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active,
.ant-calendar-picker .ant-calendar-picker-input.active,
.ant-calendar-picker:hover .ant-calendar-picker-input.active {
  background: #fff;
  text-decoration: none;
}
.ant-calendar-picker .ant-calendar-picker-input > i,
.ant-calendar-picker:hover .ant-calendar-picker-input > i,
.ant-calendar-picker .ant-calendar-picker-input > span,
.ant-calendar-picker:hover .ant-calendar-picker-input > span {
  pointer-events: none;
}
.ant-calendar-picker .ant-calendar-picker-input-primary,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary {
  color: #13213a;
  background-color: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker .ant-calendar-picker-input-primary:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus {
  color: #13213a;
  background-color: #ffe2a8;
  border-color: #ffe2a8;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active,
.ant-calendar-picker .ant-calendar-picker-input-primary.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active {
  color: #13213a;
  background-color: #d6a760;
  border-color: #d6a760;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:not(:first-child):not(:last-child),
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:not(:first-child):not(:last-child) {
  border-right-color: #37547d;
  border-left-color: #37547d;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:not(:first-child):not(:last-child):disabled,
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:not(:first-child):not(:last-child):disabled {
  border-color: #fdcf7e;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:first-child:not(:last-child),
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:first-child:not(:last-child) {
  border-right-color: #37547d;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:first-child:not(:last-child)[disabled],
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #fdcf7e;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:last-child:not(:first-child),
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:last-child:not(:first-child),
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary + .ant-calendar-picker .ant-calendar-picker-input-primary,
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary + .ant-calendar-picker:hover .ant-calendar-picker-input-primary,
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary + .ant-calendar-picker .ant-calendar-picker-input-primary,
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary + .ant-calendar-picker:hover .ant-calendar-picker-input-primary {
  border-left-color: #37547d;
}
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary + .ant-calendar-picker .ant-calendar-picker-input-primary[disabled],
.ant-btn-group .ant-calendar-picker .ant-calendar-picker-input-primary + .ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled],
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary + .ant-calendar-picker .ant-calendar-picker-input-primary[disabled],
.ant-btn-group .ant-calendar-picker:hover .ant-calendar-picker-input-primary + .ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] {
  border-left-color: #fdcf7e;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost {
  color: #fff;
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:hover,
.ant-calendar-picker .ant-calendar-picker-input-ghost:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:focus {
  color: #37547d;
  background-color: transparent;
  border-color: #37547d;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:active,
.ant-calendar-picker .ant-calendar-picker-input-ghost.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.active {
  color: #11254a;
  background-color: transparent;
  border-color: #11254a;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled],
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-ghost[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-ghost[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed {
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
  border-style: dashed;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:hover,
.ant-calendar-picker .ant-calendar-picker-input-dashed:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:active,
.ant-calendar-picker .ant-calendar-picker-input-dashed.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled],
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-dashed[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-dashed[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-danger,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger {
  color: #fff;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover {
  color: #13213a;
  background-color: #fff0f0;
  border-color: #fff0f0;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus {
  color: #fff0f0;
  background-color: #fff;
  border-color: #fff0f0;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active,
.ant-calendar-picker .ant-calendar-picker-input-danger.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active {
  color: #13213a;
  background-color: #d9cccc;
  border-color: #d9cccc;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-circle,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle,
.ant-calendar-picker .ant-calendar-picker-input-circle-outline,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline {
  width: 2.75rem;
  padding: 0;
  font-size: 3rem;
  border-radius: 50%;
  height: 2.75rem;
}
.ant-calendar-picker .ant-calendar-picker-input-circle.ant-btn-lg,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle.ant-btn-lg,
.ant-calendar-picker .ant-calendar-picker-input-circle-outline.ant-btn-lg,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline.ant-btn-lg {
  width: 3.625rem;
  padding: 0;
  font-size: 3.312rem;
  border-radius: 50%;
  height: 3.625rem;
}
.ant-calendar-picker .ant-calendar-picker-input-circle.ant-btn-sm,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle.ant-btn-sm,
.ant-calendar-picker .ant-calendar-picker-input-circle-outline.ant-btn-sm,
.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline.ant-btn-sm {
  width: 1.625rem;
  padding: 0;
  font-size: 1rem;
  border-radius: 50%;
  height: 1.625rem;
}
.ant-calendar-picker .ant-calendar-picker-input:before,
.ant-calendar-picker:hover .ant-calendar-picker-input:before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #fff;
  opacity: 0.35;
  content: '';
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}
.ant-calendar-picker .ant-calendar-picker-input .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:before,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:before,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:before,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:before {
  display: block;
}
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) {
  padding-left: 29px;
  pointer-events: none;
  position: relative;
}
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon {
  margin-left: -14px;
}
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only),
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) {
  padding-left: 24px;
}
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-sm.ant-calendar-picker:hover .ant-calendar-picker-input-loading:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline):not(.ant-calendar-picker:hover .ant-calendar-picker-input-icon-only) .anticon {
  margin-left: -17px;
}
.ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group {
  position: relative;
  display: inline-block;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn {
  position: relative;
  line-height: 0.75rem;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:hover,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn:hover,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:focus,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn:focus,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:active,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn:active,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn.active,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:disabled,
.ant-calendar-picker .ant-calendar-picker-input-group > span > .ant-btn:disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-calendar-picker .ant-calendar-picker-input-group-lg > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-lg > .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group-lg > span > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-lg > span > .ant-btn {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 0;
  height: 3.625rem;
  line-height: 1.625rem;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group-sm > span > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > span > .ant-btn {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 0;
  height: 1.625rem;
  line-height: -0.375rem;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > .ant-btn > .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > .ant-btn > .anticon,
.ant-calendar-picker .ant-calendar-picker-input-group-sm > span > .ant-btn > .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > span > .ant-btn > .anticon {
  font-size: 1rem;
}
.ant-calendar-picker .ant-calendar-picker-input-group .ant-btn + .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group .ant-btn + .ant-btn,
.ant-btn + .ant-calendar-picker .ant-calendar-picker-input-group,
.ant-btn + .ant-calendar-picker:hover .ant-calendar-picker-input-group,
.ant-calendar-picker .ant-calendar-picker-input-group span + .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group span + .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group .ant-btn + span,
.ant-calendar-picker:hover .ant-calendar-picker-input-group .ant-btn + span,
.ant-calendar-picker .ant-calendar-picker-input-group > span + span,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span + span,
.ant-calendar-picker .ant-calendar-picker-input-group + .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group + .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group + .ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker .ant-calendar-picker-input-group + .ant-calendar-picker:hover .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group + .ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group + .ant-calendar-picker:hover .ant-calendar-picker-input-group {
  margin-left: -1px;
}
.ant-calendar-picker .ant-calendar-picker-input-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]),
.ant-calendar-picker:hover .ant-calendar-picker-input-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-group .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group .ant-btn {
  border-radius: 0;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:first-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:first-child,
.ant-calendar-picker .ant-calendar-picker-input-group > span:first-child > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group > span:only-child > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:first-child:not(:last-child),
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:first-child:not(:last-child),
.ant-calendar-picker .ant-calendar-picker-input-group > span:first-child:not(:last-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-btn:last-child:not(:first-child),
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-btn:last-child:not(:first-child),
.ant-calendar-picker .ant-calendar-picker-input-group > span:last-child:not(:first-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > .ant-btn:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > span:only-child > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > .ant-btn:first-child:not(:last-child),
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > .ant-btn:first-child:not(:last-child),
.ant-calendar-picker .ant-calendar-picker-input-group-sm > span:first-child:not(:last-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group-sm > .ant-btn:last-child:not(:first-child),
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > .ant-btn:last-child:not(:first-child),
.ant-calendar-picker .ant-calendar-picker-input-group-sm > span:last-child:not(:first-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group {
  float: left;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-calendar-picker .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker .ant-calendar-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-group > .ant-calendar-picker:hover .ant-calendar-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker .ant-calendar-picker-input-icon-only,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle):not(.ant-calendar-picker:hover .ant-calendar-picker-input-circle-outline).ant-calendar-picker:hover .ant-calendar-picker-input-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-calendar-picker .ant-calendar-picker-input:focus > span,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus > span,
.ant-calendar-picker .ant-calendar-picker-input:active > span,
.ant-calendar-picker:hover .ant-calendar-picker-input:active > span {
  position: relative;
}
.ant-calendar-picker .ant-calendar-picker-input > .anticon + span,
.ant-calendar-picker:hover .ant-calendar-picker-input > .anticon + span,
.ant-calendar-picker .ant-calendar-picker-input > span + .anticon,
.ant-calendar-picker:hover .ant-calendar-picker-input > span + .anticon {
  margin-left: 8px;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary {
  color: #fdcf7e;
  background-color: transparent;
  border-color: #fdcf7e;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus {
  color: #ffe2a8;
  background-color: transparent;
  border-color: #ffe2a8;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active {
  color: #d6a760;
  background-color: transparent;
  border-color: #d6a760;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled],
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-primary[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus {
  color: #fff0f0;
  background-color: transparent;
  border-color: #fff0f0;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active {
  color: #d9cccc;
  background-color: transparent;
  border-color: #d9cccc;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled],
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled] > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled]:active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger.disabled.active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child:after,
.ant-calendar-picker .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker .ant-calendar-picker-input-danger[disabled].active > a:only-child:after,
.ant-calendar-picker:hover .ant-calendar-picker-input-background-ghost.ant-calendar-picker:hover .ant-calendar-picker-input-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-calendar-picker .ant-calendar-picker-input-two-chinese-chars:first-letter,
.ant-calendar-picker:hover .ant-calendar-picker-input-two-chinese-chars:first-letter {
  letter-spacing: 0.34em;
}
.ant-calendar-picker .ant-calendar-picker-input-two-chinese-chars > *,
.ant-calendar-picker:hover .ant-calendar-picker-input-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-right: -0.34em;
}
.ant-calendar-picker .ant-calendar-picker-input-block,
.ant-calendar-picker:hover .ant-calendar-picker-input-block {
  width: 100%;
}
.ant-calendar-picker .ant-calendar-picker-input:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus,
.ant-calendar-picker .ant-calendar-picker-input:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover,
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active {
  color: #13213a;
}
.ant-calendar-picker .ant-calendar-picker-input:focus,
.ant-calendar-picker:hover .ant-calendar-picker-input:focus {
  box-shadow: 0 0 1px 2px #e8f885;
}
.ant-calendar-picker .ant-calendar-picker-input:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover,
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active {
  background: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-calendar-picker .ant-calendar-picker-input:hover,
.ant-calendar-picker:hover .ant-calendar-picker-input:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.ant-calendar-picker .ant-calendar-picker-input:active,
.ant-calendar-picker:hover .ant-calendar-picker-input:active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.ant-calendar-picker .ant-calendar-picker-input[disabled],
.ant-calendar-picker:hover .ant-calendar-picker-input[disabled] {
  opacity: 0.3;
}
.ant-calendar-picker .ant-calendar-picker-input-group,
.ant-calendar-picker:hover .ant-calendar-picker-input-group {
  display: flex;
}
.ant-calendar-picker .ant-calendar-picker-input-has-icon,
.ant-calendar-picker:hover .ant-calendar-picker-input-has-icon {
  display: inline-flex;
  align-items: center;
}
.ant-calendar-picker .ant-calendar-picker-input .icon:first-child,
.ant-calendar-picker:hover .ant-calendar-picker-input .icon:first-child {
  margin-right: 0.4167em;
}
.ant-calendar-picker .ant-calendar-picker-input .icon:last-child,
.ant-calendar-picker:hover .ant-calendar-picker-input .icon:last-child {
  margin-right: 0;
}
.ant-calendar-picker .ant-calendar-picker-input .icon:last-child:not(:first-child),
.ant-calendar-picker:hover .ant-calendar-picker-input .icon:last-child:not(:first-child) {
  margin-left: auto;
}
.ant-calendar-picker-icon {
  display: none;
}
.ant-time-picker .ant-time-picker-input,
.ant-time-picker:hover .ant-time-picker-input {
  line-height: 1.285;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 1rem;
  border-radius: 4px;
  height: 2.75rem;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
}
.ant-time-picker .ant-time-picker-input > .anticon,
.ant-time-picker:hover .ant-time-picker-input > .anticon {
  line-height: 1;
}
.ant-time-picker .ant-time-picker-input,
.ant-time-picker:hover .ant-time-picker-input,
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active,
.ant-time-picker .ant-time-picker-input:focus,
.ant-time-picker:hover .ant-time-picker-input:focus {
  outline: 0;
}
.ant-time-picker .ant-time-picker-input:not([disabled]):hover,
.ant-time-picker:hover .ant-time-picker-input:not([disabled]):hover {
  text-decoration: none;
}
.ant-time-picker .ant-time-picker-input:not([disabled]):active,
.ant-time-picker:hover .ant-time-picker-input:not([disabled]):active {
  outline: 0;
  transition: none;
}
.ant-time-picker .ant-time-picker-input.disabled,
.ant-time-picker:hover .ant-time-picker-input.disabled,
.ant-time-picker .ant-time-picker-input[disabled],
.ant-time-picker:hover .ant-time-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-time-picker .ant-time-picker-input.disabled > *,
.ant-time-picker:hover .ant-time-picker-input.disabled > *,
.ant-time-picker .ant-time-picker-input[disabled] > *,
.ant-time-picker:hover .ant-time-picker-input[disabled] > * {
  pointer-events: none;
}
.ant-time-picker .ant-time-picker-input-lg,
.ant-time-picker:hover .ant-time-picker-input-lg {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 4px;
  height: 3.625rem;
}
.ant-time-picker .ant-time-picker-input-sm,
.ant-time-picker:hover .ant-time-picker-input-sm {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  height: 1.625rem;
}
.ant-time-picker .ant-time-picker-input > a:only-child,
.ant-time-picker:hover .ant-time-picker-input > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input:hover,
.ant-time-picker:hover .ant-time-picker-input:hover,
.ant-time-picker .ant-time-picker-input:focus,
.ant-time-picker:hover .ant-time-picker-input:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-time-picker .ant-time-picker-input:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input:hover > a:only-child,
.ant-time-picker .ant-time-picker-input:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active,
.ant-time-picker .ant-time-picker-input.active,
.ant-time-picker:hover .ant-time-picker-input.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-time-picker .ant-time-picker-input:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input:active > a:only-child,
.ant-time-picker .ant-time-picker-input.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input.disabled,
.ant-time-picker:hover .ant-time-picker-input.disabled,
.ant-time-picker .ant-time-picker-input[disabled],
.ant-time-picker:hover .ant-time-picker-input[disabled],
.ant-time-picker .ant-time-picker-input.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input.disabled:hover,
.ant-time-picker .ant-time-picker-input[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input[disabled]:hover,
.ant-time-picker .ant-time-picker-input.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input.disabled:focus,
.ant-time-picker .ant-time-picker-input[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input[disabled]:focus,
.ant-time-picker .ant-time-picker-input.disabled:active,
.ant-time-picker:hover .ant-time-picker-input.disabled:active,
.ant-time-picker .ant-time-picker-input[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input[disabled]:active,
.ant-time-picker .ant-time-picker-input.disabled.active,
.ant-time-picker:hover .ant-time-picker-input.disabled.active,
.ant-time-picker .ant-time-picker-input[disabled].active,
.ant-time-picker:hover .ant-time-picker-input[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input:hover,
.ant-time-picker:hover .ant-time-picker-input:hover,
.ant-time-picker .ant-time-picker-input:focus,
.ant-time-picker:hover .ant-time-picker-input:focus,
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active,
.ant-time-picker .ant-time-picker-input.active,
.ant-time-picker:hover .ant-time-picker-input.active {
  background: #fff;
  text-decoration: none;
}
.ant-time-picker .ant-time-picker-input > i,
.ant-time-picker:hover .ant-time-picker-input > i,
.ant-time-picker .ant-time-picker-input > span,
.ant-time-picker:hover .ant-time-picker-input > span {
  pointer-events: none;
}
.ant-time-picker .ant-time-picker-input-primary,
.ant-time-picker:hover .ant-time-picker-input-primary {
  color: #13213a;
  background-color: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-time-picker .ant-time-picker-input-primary > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-primary > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-primary:hover,
.ant-time-picker:hover .ant-time-picker-input-primary:hover,
.ant-time-picker .ant-time-picker-input-primary:focus,
.ant-time-picker:hover .ant-time-picker-input-primary:focus {
  color: #13213a;
  background-color: #ffe2a8;
  border-color: #ffe2a8;
}
.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-primary:active,
.ant-time-picker:hover .ant-time-picker-input-primary:active,
.ant-time-picker .ant-time-picker-input-primary.active,
.ant-time-picker:hover .ant-time-picker-input-primary.active {
  color: #13213a;
  background-color: #d6a760;
  border-color: #d6a760;
}
.ant-time-picker .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker .ant-time-picker-input-primary.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-primary.disabled,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled,
.ant-time-picker .ant-time-picker-input-primary[disabled],
.ant-time-picker:hover .ant-time-picker-input-primary[disabled],
.ant-time-picker .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker .ant-time-picker-input-primary.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active,
.ant-time-picker .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker .ant-time-picker-input-primary.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active,
.ant-time-picker .ant-time-picker-input-primary[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:not(:first-child):not(:last-child),
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:not(:first-child):not(:last-child) {
  border-right-color: #37547d;
  border-left-color: #37547d;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:not(:first-child):not(:last-child):disabled,
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:not(:first-child):not(:last-child):disabled {
  border-color: #fdcf7e;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:first-child:not(:last-child),
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:first-child:not(:last-child) {
  border-right-color: #37547d;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:first-child:not(:last-child)[disabled],
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #fdcf7e;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:last-child:not(:first-child),
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:last-child:not(:first-child),
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary + .ant-time-picker .ant-time-picker-input-primary,
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary + .ant-time-picker:hover .ant-time-picker-input-primary,
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary + .ant-time-picker .ant-time-picker-input-primary,
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary + .ant-time-picker:hover .ant-time-picker-input-primary {
  border-left-color: #37547d;
}
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary + .ant-time-picker .ant-time-picker-input-primary[disabled],
.ant-btn-group .ant-time-picker .ant-time-picker-input-primary + .ant-time-picker:hover .ant-time-picker-input-primary[disabled],
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary + .ant-time-picker .ant-time-picker-input-primary[disabled],
.ant-btn-group .ant-time-picker:hover .ant-time-picker-input-primary + .ant-time-picker:hover .ant-time-picker-input-primary[disabled] {
  border-left-color: #fdcf7e;
}
.ant-time-picker .ant-time-picker-input-ghost,
.ant-time-picker:hover .ant-time-picker-input-ghost {
  color: #fff;
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-ghost > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-ghost > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-ghost:hover,
.ant-time-picker:hover .ant-time-picker-input-ghost:hover,
.ant-time-picker .ant-time-picker-input-ghost:focus,
.ant-time-picker:hover .ant-time-picker-input-ghost:focus {
  color: #37547d;
  background-color: transparent;
  border-color: #37547d;
}
.ant-time-picker .ant-time-picker-input-ghost:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-ghost:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-ghost:active,
.ant-time-picker:hover .ant-time-picker-input-ghost:active,
.ant-time-picker .ant-time-picker-input-ghost.active,
.ant-time-picker:hover .ant-time-picker-input-ghost.active {
  color: #11254a;
  background-color: transparent;
  border-color: #11254a;
}
.ant-time-picker .ant-time-picker-input-ghost:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost:active > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-ghost:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-ghost.disabled,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled,
.ant-time-picker .ant-time-picker-input-ghost[disabled],
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled],
.ant-time-picker .ant-time-picker-input-ghost.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:hover,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:hover,
.ant-time-picker .ant-time-picker-input-ghost.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:focus,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:focus,
.ant-time-picker .ant-time-picker-input-ghost.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:active,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:active,
.ant-time-picker .ant-time-picker-input-ghost.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled.active,
.ant-time-picker .ant-time-picker-input-ghost[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-ghost.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-ghost[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-ghost.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-ghost[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-ghost[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-dashed,
.ant-time-picker:hover .ant-time-picker-input-dashed {
  color: #13213a;
  background-color: #fff;
  border-color: #fdcf7e;
  border-style: dashed;
}
.ant-time-picker .ant-time-picker-input-dashed > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-dashed > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-dashed:hover,
.ant-time-picker:hover .ant-time-picker-input-dashed:hover,
.ant-time-picker .ant-time-picker-input-dashed:focus,
.ant-time-picker:hover .ant-time-picker-input-dashed:focus {
  color: #37547d;
  background-color: #fff;
  border-color: #37547d;
}
.ant-time-picker .ant-time-picker-input-dashed:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-dashed:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-dashed:active,
.ant-time-picker:hover .ant-time-picker-input-dashed:active,
.ant-time-picker .ant-time-picker-input-dashed.active,
.ant-time-picker:hover .ant-time-picker-input-dashed.active {
  color: #11254a;
  background-color: #fff;
  border-color: #11254a;
}
.ant-time-picker .ant-time-picker-input-dashed:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed:active > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-dashed:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-dashed.disabled,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled,
.ant-time-picker .ant-time-picker-input-dashed[disabled],
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled],
.ant-time-picker .ant-time-picker-input-dashed.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:hover,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:hover,
.ant-time-picker .ant-time-picker-input-dashed.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:focus,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:focus,
.ant-time-picker .ant-time-picker-input-dashed.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:active,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:active,
.ant-time-picker .ant-time-picker-input-dashed.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled.active,
.ant-time-picker .ant-time-picker-input-dashed[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-dashed.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-dashed[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-dashed.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-dashed[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-dashed[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-danger,
.ant-time-picker:hover .ant-time-picker-input-danger {
  color: #fff;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-danger > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-danger > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-danger:hover,
.ant-time-picker:hover .ant-time-picker-input-danger:hover {
  color: #13213a;
  background-color: #fff0f0;
  border-color: #fff0f0;
}
.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-danger:focus,
.ant-time-picker:hover .ant-time-picker-input-danger:focus {
  color: #fff0f0;
  background-color: #fff;
  border-color: #fff0f0;
}
.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-danger:active,
.ant-time-picker:hover .ant-time-picker-input-danger:active,
.ant-time-picker .ant-time-picker-input-danger.active,
.ant-time-picker:hover .ant-time-picker-input-danger.active {
  color: #13213a;
  background-color: #d9cccc;
  border-color: #d9cccc;
}
.ant-time-picker .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker .ant-time-picker-input-danger.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-danger.disabled,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled,
.ant-time-picker .ant-time-picker-input-danger[disabled],
.ant-time-picker:hover .ant-time-picker-input-danger[disabled],
.ant-time-picker .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker .ant-time-picker-input-danger.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active,
.ant-time-picker .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker .ant-time-picker-input-danger.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active,
.ant-time-picker .ant-time-picker-input-danger[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-circle,
.ant-time-picker:hover .ant-time-picker-input-circle,
.ant-time-picker .ant-time-picker-input-circle-outline,
.ant-time-picker:hover .ant-time-picker-input-circle-outline {
  width: 2.75rem;
  padding: 0;
  font-size: 3rem;
  border-radius: 50%;
  height: 2.75rem;
}
.ant-time-picker .ant-time-picker-input-circle.ant-btn-lg,
.ant-time-picker:hover .ant-time-picker-input-circle.ant-btn-lg,
.ant-time-picker .ant-time-picker-input-circle-outline.ant-btn-lg,
.ant-time-picker:hover .ant-time-picker-input-circle-outline.ant-btn-lg {
  width: 3.625rem;
  padding: 0;
  font-size: 3.312rem;
  border-radius: 50%;
  height: 3.625rem;
}
.ant-time-picker .ant-time-picker-input-circle.ant-btn-sm,
.ant-time-picker:hover .ant-time-picker-input-circle.ant-btn-sm,
.ant-time-picker .ant-time-picker-input-circle-outline.ant-btn-sm,
.ant-time-picker:hover .ant-time-picker-input-circle-outline.ant-btn-sm {
  width: 1.625rem;
  padding: 0;
  font-size: 1rem;
  border-radius: 50%;
  height: 1.625rem;
}
.ant-time-picker .ant-time-picker-input:before,
.ant-time-picker:hover .ant-time-picker-input:before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #fff;
  opacity: 0.35;
  content: '';
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}
.ant-time-picker .ant-time-picker-input .anticon,
.ant-time-picker:hover .ant-time-picker-input .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:before,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:before,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:before,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:before {
  display: block;
}
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) {
  padding-left: 29px;
  pointer-events: none;
  position: relative;
}
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon {
  margin-left: -14px;
}
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only),
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) {
  padding-left: 24px;
}
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon,
.ant-time-picker:hover .ant-time-picker-input-sm.ant-time-picker:hover .ant-time-picker-input-loading:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline):not(.ant-time-picker:hover .ant-time-picker-input-icon-only) .anticon {
  margin-left: -17px;
}
.ant-time-picker .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group {
  position: relative;
  display: inline-block;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn {
  position: relative;
  line-height: 0.75rem;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:hover,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:hover,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn:hover,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn:hover,
.ant-time-picker .ant-time-picker-input-group > .ant-btn:focus,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:focus,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn:focus,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn:focus,
.ant-time-picker .ant-time-picker-input-group > .ant-btn:active,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:active,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn:active,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn:active,
.ant-time-picker .ant-time-picker-input-group > .ant-btn.active,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn.active,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn.active,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:disabled,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:disabled,
.ant-time-picker .ant-time-picker-input-group > span > .ant-btn:disabled,
.ant-time-picker:hover .ant-time-picker-input-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-time-picker .ant-time-picker-input-group-lg > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-lg > .ant-btn,
.ant-time-picker .ant-time-picker-input-group-lg > span > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-lg > span > .ant-btn {
  padding: 0 15px;
  font-size: 1.312rem;
  border-radius: 0;
  height: 3.625rem;
  line-height: 1.625rem;
}
.ant-time-picker .ant-time-picker-input-group-sm > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-sm > .ant-btn,
.ant-time-picker .ant-time-picker-input-group-sm > span > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-sm > span > .ant-btn {
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 0;
  height: 1.625rem;
  line-height: -0.375rem;
}
.ant-time-picker .ant-time-picker-input-group-sm > .ant-btn > .anticon,
.ant-time-picker:hover .ant-time-picker-input-group-sm > .ant-btn > .anticon,
.ant-time-picker .ant-time-picker-input-group-sm > span > .ant-btn > .anticon,
.ant-time-picker:hover .ant-time-picker-input-group-sm > span > .ant-btn > .anticon {
  font-size: 1rem;
}
.ant-time-picker .ant-time-picker-input-group .ant-btn + .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group .ant-btn + .ant-btn,
.ant-btn + .ant-time-picker .ant-time-picker-input-group,
.ant-btn + .ant-time-picker:hover .ant-time-picker-input-group,
.ant-time-picker .ant-time-picker-input-group span + .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group span + .ant-btn,
.ant-time-picker .ant-time-picker-input-group .ant-btn + span,
.ant-time-picker:hover .ant-time-picker-input-group .ant-btn + span,
.ant-time-picker .ant-time-picker-input-group > span + span,
.ant-time-picker:hover .ant-time-picker-input-group > span + span,
.ant-time-picker .ant-time-picker-input-group + .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group + .ant-btn,
.ant-time-picker .ant-time-picker-input-group + .ant-time-picker .ant-time-picker-input-group,
.ant-time-picker .ant-time-picker-input-group + .ant-time-picker:hover .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group + .ant-time-picker .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group + .ant-time-picker:hover .ant-time-picker-input-group {
  margin-left: -1px;
}
.ant-time-picker .ant-time-picker-input-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]),
.ant-time-picker:hover .ant-time-picker-input-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-time-picker .ant-time-picker-input-group .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group .ant-btn {
  border-radius: 0;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:first-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:first-child,
.ant-time-picker .ant-time-picker-input-group > span:first-child > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:only-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group > span:only-child > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:first-child:not(:last-child),
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:first-child:not(:last-child),
.ant-time-picker .ant-time-picker-input-group > span:first-child:not(:last-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group > .ant-btn:last-child:not(:first-child),
.ant-time-picker:hover .ant-time-picker-input-group > .ant-btn:last-child:not(:first-child),
.ant-time-picker .ant-time-picker-input-group > span:last-child:not(:first-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group-sm > .ant-btn:only-child,
.ant-time-picker:hover .ant-time-picker-input-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group-sm > span:only-child > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group-sm > .ant-btn:first-child:not(:last-child),
.ant-time-picker:hover .ant-time-picker-input-group-sm > .ant-btn:first-child:not(:last-child),
.ant-time-picker .ant-time-picker-input-group-sm > span:first-child:not(:last-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group-sm > .ant-btn:last-child:not(:first-child),
.ant-time-picker:hover .ant-time-picker-input-group-sm > .ant-btn:last-child:not(:first-child),
.ant-time-picker .ant-time-picker-input-group-sm > span:last-child:not(:first-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group,
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group {
  float: left;
}
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:not(:first-child):not(:last-child) > .ant-btn,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-time-picker .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker .ant-time-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child,
.ant-time-picker:hover .ant-time-picker-input-group > .ant-time-picker:hover .ant-time-picker-input-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker .ant-time-picker-input-icon-only,
.ant-time-picker:hover .ant-time-picker-input:not(.ant-time-picker:hover .ant-time-picker-input-circle):not(.ant-time-picker:hover .ant-time-picker-input-circle-outline).ant-time-picker:hover .ant-time-picker-input-icon-only {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-time-picker .ant-time-picker-input:focus > span,
.ant-time-picker:hover .ant-time-picker-input:focus > span,
.ant-time-picker .ant-time-picker-input:active > span,
.ant-time-picker:hover .ant-time-picker-input:active > span {
  position: relative;
}
.ant-time-picker .ant-time-picker-input > .anticon + span,
.ant-time-picker:hover .ant-time-picker-input > .anticon + span,
.ant-time-picker .ant-time-picker-input > span + .anticon,
.ant-time-picker:hover .ant-time-picker-input > span + .anticon {
  margin-left: 8px;
}
.ant-time-picker .ant-time-picker-input-background-ghost,
.ant-time-picker:hover .ant-time-picker-input-background-ghost {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary {
  color: #fdcf7e;
  background-color: transparent;
  border-color: #fdcf7e;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus {
  color: #ffe2a8;
  background-color: transparent;
  border-color: #ffe2a8;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active {
  color: #d6a760;
  background-color: transparent;
  border-color: #d6a760;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled],
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled],
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled],
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled],
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-primary[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-primary[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus {
  color: #fff0f0;
  background-color: transparent;
  border-color: #fff0f0;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:focus > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active {
  color: #d9cccc;
  background-color: transparent;
  border-color: #d9cccc;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled],
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled],
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled],
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled],
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled] > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:hover > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:focus > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled]:active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger.disabled.active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child:after,
.ant-time-picker .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker .ant-time-picker-input-danger[disabled].active > a:only-child:after,
.ant-time-picker:hover .ant-time-picker-input-background-ghost.ant-time-picker:hover .ant-time-picker-input-danger[disabled].active > a:only-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.ant-time-picker .ant-time-picker-input-two-chinese-chars:first-letter,
.ant-time-picker:hover .ant-time-picker-input-two-chinese-chars:first-letter {
  letter-spacing: 0.34em;
}
.ant-time-picker .ant-time-picker-input-two-chinese-chars > *,
.ant-time-picker:hover .ant-time-picker-input-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-right: -0.34em;
}
.ant-time-picker .ant-time-picker-input-block,
.ant-time-picker:hover .ant-time-picker-input-block {
  width: 100%;
}
.ant-time-picker .ant-time-picker-input:focus,
.ant-time-picker:hover .ant-time-picker-input:focus,
.ant-time-picker .ant-time-picker-input:hover,
.ant-time-picker:hover .ant-time-picker-input:hover,
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active {
  color: #13213a;
}
.ant-time-picker .ant-time-picker-input:focus,
.ant-time-picker:hover .ant-time-picker-input:focus {
  box-shadow: 0 0 1px 2px #e8f885;
}
.ant-time-picker .ant-time-picker-input:hover,
.ant-time-picker:hover .ant-time-picker-input:hover,
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active {
  background: #fdcf7e;
  border-color: #fdcf7e;
}
.ant-time-picker .ant-time-picker-input:hover,
.ant-time-picker:hover .ant-time-picker-input:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.ant-time-picker .ant-time-picker-input:active,
.ant-time-picker:hover .ant-time-picker-input:active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.ant-time-picker .ant-time-picker-input[disabled],
.ant-time-picker:hover .ant-time-picker-input[disabled] {
  opacity: 0.3;
}
.ant-time-picker .ant-time-picker-input-group,
.ant-time-picker:hover .ant-time-picker-input-group {
  display: flex;
}
.ant-time-picker .ant-time-picker-input-has-icon,
.ant-time-picker:hover .ant-time-picker-input-has-icon {
  display: inline-flex;
  align-items: center;
}
.ant-time-picker .ant-time-picker-input .icon:first-child,
.ant-time-picker:hover .ant-time-picker-input .icon:first-child {
  margin-right: 0.4167em;
}
.ant-time-picker .ant-time-picker-input .icon:last-child,
.ant-time-picker:hover .ant-time-picker-input .icon:last-child {
  margin-right: 0;
}
.ant-time-picker .ant-time-picker-input .icon:last-child:not(:first-child),
.ant-time-picker:hover .ant-time-picker-input .icon:last-child:not(:first-child) {
  margin-left: auto;
}
.ant-time-picker-icon {
  display: none;
}
.ant-time,
.ant-calendar {
  color: #13213a;
}
.ant-time .ant-input,
.ant-calendar .ant-input {
  color: #13213a;
  border-color: #13213a;
}
.ant-time .ant-input:hover,
.ant-calendar .ant-input:hover,
.ant-time .ant-input:active,
.ant-calendar .ant-input:active,
.ant-time .ant-input:focus,
.ant-calendar .ant-input:focus {
  border-color: #000;
}
.ant-time .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #13213a;
}
.ant-time-input,
.ant-calendar-input {
  color: #13213a;
}
.ant-time-date,
.ant-calendar-date {
  padding: 0.1875rem;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1;
  color: inherit;
}
.ant-time .ant-time-ok-btn,
.ant-time .ant-calendar-ok-btn,
.ant-calendar .ant-time-ok-btn,
.ant-calendar .ant-calendar-ok-btn {
  line-height: 0;
  padding: 0.875em;
}
.ant-time-today-btn,
.ant-calendar-today-btn,
.ant-time-time-picker-btn,
.ant-calendar-time-picker-btn {
  color: #13213a;
}
.ant-time a,
.ant-calendar a,
.ant-time a:hover,
.ant-calendar a:hover {
  color: #13213a;
}
.ant-time-picker-panel-select li,
.ant-time-picker-panel-input {
  color: #13213a;
}
.ant-layout-header {
  display: flex;
  align-items: center;
  padding-left: 1.1875rem;
  padding-right: 1.1875rem;
}
.ant-layout-header .site-title {
  margin: 0 1.1875rem 0 0;
}
.ant-layout-header .ant-menu {
  height: 100%;
}
.ant-layout-header .ant-menu.menu--primary > .ant-menu-item,
.ant-layout-header .ant-menu.menu--secondary > .ant-menu-item {
  border: 0;
  background: transparent;
}
.ant-layout-header .ant-menu:before,
.ant-layout-header .ant-menu:after,
.ant-layout-header .ant-menu .ant-menu-submenu {
  display: none;
}
.ant-layout-header .ant-menu a {
  text-decoration: none;
}
.ant-layout-header .ant-menu,
.ant-layout-header .ant-menu-item {
  display: flex;
  background: transparent;
  border: 0;
}
.ant-layout-header .ant-menu-item {
  padding: 0;
}
.ant-layout-header .ant-menu-item > a,
.ant-layout-header .ant-menu-item > button,
.ant-layout-header .ant-menu-item > form,
.ant-layout-header .ant-menu-item > .menu-link,
.ant-layout-header .ant-menu-item > .search-form-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  line-height: 1.2;
}
.ant-layout-header .ant-menu-item > a,
.ant-layout-header .ant-menu-item > button,
.ant-layout-header .ant-menu-item > .menu-link {
  transition: background 0.3s;
}
.ant-layout-header .ant-menu-item > a,
.ant-layout-header .ant-menu-item > button,
.ant-layout-header .ant-menu-item > .menu-link,
.ant-layout-header .ant-menu-item > a:hover,
.ant-layout-header .ant-menu-item > button:hover,
.ant-layout-header .ant-menu-item > .menu-link:hover,
.ant-layout-header .ant-menu-item > a:focus,
.ant-layout-header .ant-menu-item > button:focus,
.ant-layout-header .ant-menu-item > .menu-link:focus,
.ant-layout-header .ant-menu-item > a:active,
.ant-layout-header .ant-menu-item > button:active,
.ant-layout-header .ant-menu-item > .menu-link:active {
  color: #fff;
}
.ant-layout-header .ant-menu-item > a:hover,
.ant-layout-header .ant-menu-item > button:hover,
.ant-layout-header .ant-menu-item > .menu-link:hover,
.ant-layout-header .ant-menu-item > a.active,
.ant-layout-header .ant-menu-item > button.active,
.ant-layout-header .ant-menu-item > .menu-link.active {
  position: relative;
}
.ant-layout-header .ant-menu-item > a:hover:before,
.ant-layout-header .ant-menu-item > button:hover:before,
.ant-layout-header .ant-menu-item > .menu-link:hover:before,
.ant-layout-header .ant-menu-item > a.active:before,
.ant-layout-header .ant-menu-item > button.active:before,
.ant-layout-header .ant-menu-item > .menu-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
  opacity: 0.5;
}
.ant-layout-header .ant-menu-item > a:hover,
.ant-layout-header .ant-menu-item > button:hover,
.ant-layout-header .ant-menu-item > .menu-link:hover {
  background: #192f53;
}
.ant-layout-header .ant-menu-item > a.active,
.ant-layout-header .ant-menu-item > button.active,
.ant-layout-header .ant-menu-item > .menu-link.active {
  background: #234982;
}
.ant-layout-header .ant-menu-item > a,
.ant-layout-header .ant-menu-item > .menu-link {
  position: relative;
}
.ant-layout-header .ant-menu-item > a:before,
.ant-layout-header .ant-menu-item > .menu-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: transparent;
  opacity: 0.5;
  transition: background 0.3s;
}
.ant-layout-header .ant-menu-item > a:hover:before,
.ant-layout-header .ant-menu-item > .menu-link:hover:before,
.ant-layout-header .ant-menu-item > a.active:before,
.ant-layout-header .ant-menu-item > .menu-link.active:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
}
.ant-layout-header .ant-menu-item .form-item,
.ant-layout-header .ant-menu-item .form-actions {
  margin: 0;
}
.ant-layout-header .ant-menu .modal-wrapper {
  padding-left: 0;
  padding-right: 0;
}
.ant-layout-header .ant-menu .modal-wrapper .btn-trigger-modal {
  height: 100%;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  min-width: 4.0625rem;
}
.ant-layout-header .ant-menu .username {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  color: #b6daff;
}
.ant-layout-header .menu--primary {
  font-size: 1.375rem;
  min-width: 28.75rem;
}
.ant-layout-header .menu--secondary {
  margin-left: auto;
  min-width: 30rem;
  transition: min-width 200ms ease;
}
.ant-layout-header .menu--secondary.search-open {
  min-width: 46.125rem;
}
.help-modal {
  min-width: 50rem;
  max-width: calc(1920px - 4rem);
}
.help-modal .ant-modal-body {
  padding: 0;
  padding-top: 2.5rem;
}
.help-modal .ant-modal-footer {
  display: none;
}
.help-modal iframe {
  display: block;
  border: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  height: calc(100vh - 8rem);
}
.settings-popover h2:not(:first-child) {
  margin-top: 1.5rem;
}
.ant-breadcrumb {
  line-height: 1.2;
}
.page-header--title-visible .ant-breadcrumb {
  width: 100%;
  margin-bottom: 1.25rem;
}
.ant-breadcrumb a {
  text-decoration: underline;
}
.ant-breadcrumb,
.ant-breadcrumb > * {
  display: flex;
  align-items: center;
}
.ant-breadcrumb-separator {
  font-size: 1.5rem;
}
.ant-breadcrumb-separator .icon,
.ant-breadcrumb-separator .icon:before {
  display: block;
}
.header {
  display: flex;
  align-items: center;
}
.header .toolbar,
.header-extras {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 0.625rem;
}
.header .toolbar > *,
.header-extras > * {
  margin-right: 0.625rem;
}
.header .toolbar > *:last-child,
.header-extras > *:last-child {
  margin-right: 0;
}
.header .toolbar .ant-btn,
.header-extras .ant-btn,
.header .toolbar .dropdown-container,
.header-extras .dropdown-container,
.header .toolbar .confirm-modal,
.header-extras .confirm-modal,
.header .toolbar .ant-dropdown,
.header-extras .ant-dropdown {
  font-size: 0.875rem;
}
.header .toolbar .ant-btn-sm,
.header-extras .ant-btn-sm,
.header .toolbar .dropdown-container-sm,
.header-extras .dropdown-container-sm,
.header .toolbar .confirm-modal-sm,
.header-extras .confirm-modal-sm,
.header .toolbar .ant-dropdown-sm,
.header-extras .ant-dropdown-sm {
  font-size: 0.75rem;
}
.header .toolbar > .ant-btn,
.header-extras > .ant-btn,
.header .toolbar .ant-dropdown-trigger,
.header-extras .ant-dropdown-trigger,
.header .toolbar .btn-trigger-modal,
.header-extras .btn-trigger-modal {
  height: 2.25rem;
}
.header .toolbar > .ant-btn,
.header-extras > .ant-btn,
.header .toolbar .btn-trigger-modal,
.header-extras .btn-trigger-modal {
  min-width: 7.6875rem;
}
.header .toolbar .dropdown-container,
.header-extras .dropdown-container {
  width: 6.75rem;
}
.header .toolbar .dropdown-container .ant-dropdown-menu-item a,
.header-extras .dropdown-container .ant-dropdown-menu-item a,
.header .toolbar .dropdown-container .ant-dropdown-menu-item .ant-btn,
.header-extras .dropdown-container .ant-dropdown-menu-item .ant-btn {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}
.ant-layout-sider-children,
.ant-layout-sider-children .ant-collapse {
  max-height: inherit;
}
.ant-layout-sider-children .ant-list-header,
.ant-layout-sider-children .ant-collapse .ant-list-header {
  max-width: calc(100% - 1px);
}
.ant-layout-sider .ant-collapse {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}
.ant-layout-sider .ant-collapse-item {
  width: 100%;
  background: #fff;
  border-top: 1px solid #1f3e6f;
  border-bottom: none;
  min-height: 3.25rem;
  flex: 0 1 3.25rem;
}
.ant-layout-sider .ant-collapse-item:first-child {
  border-top: none;
}
.ant-layout-sider .ant-collapse-header {
  height: 3.25rem;
  background: #13213a;
}
.ant-layout-sider .ant-collapse-item-active {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: auto;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-header {
  flex-shrink: 0;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content,
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box {
  display: flex;
  flex: 1 1 auto;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content > .list-panel,
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box > .list-panel {
  overflow-y: auto;
}
.ant-layout-sider .ant-collapse-item--invisible-header {
  background: transparent;
}
.ant-layout-sider .ant-collapse-item--invisible-header:not(.ant-collapse-item-active) {
  min-height: 0;
  height: 0;
  flex: 0 0 1px;
  border: none;
}
.ant-layout-sider .ant-collapse-item--invisible-header.ant-collapse-item-active .ant-collapse-content,
.ant-layout-sider .ant-collapse-item--invisible-header.ant-collapse-item-active .ant-collapse-content-box {
  display: block;
}
.ant-layout-sider .ant-collapse-content {
  max-height: calc(100% -  3.25rem);
  color: #13213a;
}
.ant-layout-sider .help-text {
  transition: opacity 0.2s;
}
.ant-layout-sider .help-text--hidden {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.ant-layout-sider .help-text--visible {
  opacity: 1;
  visibility: visible;
  margin: 1.875rem 0;
  padding: 0 1.875rem 0 3.3125rem;
}
.promotions .ant-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 82vh;
}
.promotions .ant-list [class*="ant-col-"] .ant-list-item {
  width: auto;
}
.promotions--set-width .ant-col-12 {
  max-width: 50%;
  max-height: 50%;
}
.promotions--set-width .ant-col-12 .ant-list-item,
.promotions--set-width .ant-col-12 .ant-list-item .ant-list-item-content,
.promotions--set-width .ant-col-12 .ant-list-item .ant-card-link,
.promotions--set-width .ant-col-12 .ant-list-item .ant-list-item-content .ant-card-link,
.promotions--set-width .ant-col-12 .ant-list-item .promotion,
.promotions--set-width .ant-col-12 .ant-list-item .ant-list-item-content .promotion,
.promotions--set-width .ant-col-12 .ant-list-item .ant-card-link .promotion,
.promotions--set-width .ant-col-12 .ant-list-item .ant-list-item-content .ant-card-link .promotion {
  max-width: 100%;
  max-height: 100%;
}
.promotions .ant-col-12 .ant-list-item {
  padding: 0 2px;
  margin-bottom: 4px;
}
@media screen and (min-width: 1200px) {
  .promotions .ant-col-12 .ant-list-item {
    padding: 0 8px;
    margin-bottom: 16px;
  }
}
.promotion {
  min-height: 12rem;
}
.promotion .ant-card-meta-title {
  white-space: normal;
}
.promotion .ant-card-meta-description,
.promotion .read-more {
  font-size: 0.875rem;
  line-height: 1.2857;
}
.promotion .read-more {
  margin-top: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #b6daff;
}
.promotion-custom .ant-card-body {
  padding: 0;
}
.promotion-custom .ant-card-meta {
  margin: 0;
}
.promotion__item--loading,
.promotion__item--error {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion__card-link {
  transition: background-color 200ms ease;
}
.promotion__card-link:hover,
.promotion__card-link:active,
.promotion__card-link:focus {
  color: white;
  text-decoration: none;
}
.promotion__card-link:focus {
  background-color: rgba(0, 0, 0, 0.25);
}
.promotion__card-link:hover .promotion__more-link,
.promotion__card-link:focus .promotion__more-link,
.promotion__card-link .promotion__more-link:hover {
  text-decoration: underline;
}
.promotion .ant-card-body,
.promotion .ant-card-meta,
.promotion .ant-card-meta-detail,
.promotion .ant-card-meta-description,
.promotion__card-link,
.promotion__item,
.promotion__item:not(.promotion__item--loading):not(.promotion__item--error) > * {
  height: 100%;
}
.promotion--centered .ant-card-body,
.promotion--centered .ant-card-meta,
.promotion--centered .ant-card-meta-detail,
.promotion--centered .ant-card-meta-description {
  height: 100%;
}
.entity-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 25.625rem;
  margin: 0 auto;
}
.views-page #entity-save button > .btn-text:after,
.investigation-page #entity-save button > .btn-text:after {
  content: "*";
  color: inherit;
  font-size: 1em;
  display: inline-block;
  margin-left: 0.25rem;
  transform: scale(0);
  transform-origin: 30% center;
  transition: transform 150ms ease;
}
.views-page > [data-modified="true"] #entity-save button > .btn-text:after,
.investigation-page > [data-modified="true"] #entity-save button > .btn-text:after {
  transform: scale(1);
}
.ant-btn-progress.entity-saving {
  opacity: 1;
}
.ant-btn-progress.entity-saving,
.ant-btn-progress.entity-saving:hover {
  border-color: rgba(255, 255, 255, 0.3);
}
.login-form .ant-input {
  background: #13213a;
}
.login-form__error-wrapper {
  padding: 0.5rem;
  margin: 0 -0.5rem;
  border-radius: 4px;
}
.login-form__error-wrapper .ant-row:nth-child(2) {
  margin-bottom: 0.5rem;
}
.login-form.has-error .login-form__error-wrapper {
  background: white;
}
.login-form .ant-form-explain,
.login-form .form-error {
  color: #c01300;
  font-size: 0.75rem;
  font-weight: 400;
}
.login-form .ant-form-explain {
  margin-top: 0.25rem;
}
.login-form .form-error:before {
  content: ' ';
  background: url('../images/icon_error.svg');
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: -0.25rem 0.25rem;
}
.login-form .form-actions {
  margin-top: 1.25rem;
}
.views-list .list-panel {
  width: 100%;
}
.views-list .list-panel .ant-list-item.ant-list-item--block.ant-list-item--active {
  background-color: #b6daff;
}
.views-list .list-panel .ant-list .ant-list-item-content.ant-list-item-content-single {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.views-list .list-panel .ant-list .ant-list-item-content.ant-list-item-content-single .ant-list-item-actions {
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.views-page .ant-layout-content .page-header--title-hidden {
  display: none;
}
.views-page .ant-layout-content .empty-state {
  flex: 1 0 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.views-page .ant-layout-content .empty-state a.ant-btn {
  max-width: 20rem;
}
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view {
  border: none;
  top: 1.25%;
  right: 1.25%;
  left: unset;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view > .btn-text {
  font-size: 0.75rem;
}
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view > .btn-text > .icon {
  font-size: 2rem;
  padding: 0.5rem;
  margin-bottom: 0;
}
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view > .btn-text > .icon + span {
  display: none;
}
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view:hover .icon + span,
.camera-thumbnail .btn-remove-camera.btn-remove-camera--view:focus .icon + span {
  display: block;
}
.camera-card {
  padding: 3px;
  color: #585f6d;
}
.ant-list-item .camera-card,
.ant-list-item .camera-card .camera-card-link,
.ant-list-item .camera-card .ant-card {
  width: 100%;
  height: 100%;
}
.camera-card .ant-card {
  transition: background-color 0.2s;
}
.camera-card .ant-card-body {
  position: relative;
}
.camera-card .ant-list-loading {
  width: 100%;
  height: 0;
  padding-bottom: 22.67%;
}
.camera-card .ant-list-loading .ant-spin-nested-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.camera-card-link {
  display: block;
  overflow: hidden;
  color: inherit;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}
.camera-card-link,
.camera-card-link:focus,
.camera-card-link:hover,
.camera-card-link:active {
  text-decoration: none;
  color: inherit;
}
.camera-card-link:focus {
  box-shadow: 0 0 1px 3px #e8f885;
}
.camera-card-link:hover,
.camera-card-link:active {
  box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.4);
}
.camera-card-link:active .ant-card {
  background: #dbdfe5;
}
.camera-card .camera-card-thumbnails-item {
  margin-bottom: 0;
}
.camera {
  width: 100%;
  color: #13213a;
  position: relative;
}
.camera__player-page {
  display: flex;
  flex-direction: column;
}
.camera__player-page .ant-layout-sider {
  overflow: scroll;
}
.camera-thumbnail {
  background-color: rgba(19, 33, 58, 0.25);
}
.camera-thumbnail-content {
  position: relative;
}
.camera-thumbnail-content .ant-btn.show-on-hover {
  transition: all 0.2s linear;
}
.camera-thumbnail-error {
  text-align: center;
  width: 100%;
  padding-top: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
}
.camera-theme-dark {
  color: #13213a;
}
.camera-thumbnail,
.camera-image,
.camera-canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.camera-canvas {
  position: relative;
}
.camera-image {
  position: relative;
  height: 0;
  padding-bottom: calc(var(--camera-aspect-ratio) * 100%);
}
.camera-image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.camera-has-video .camera-canvas {
  /*      padding-bottom: 0;
      height: unset;
      max-height: 100%;
      max-width: 100%;*/
  position: absolute;
  top: 0;
  left: 0;
}
.camera .ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
}
.camera .ant-spin-dot i {
  background-color: #585f6d;
}
.camera-content,
.camera-content > .ant-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-layout-sider .camera-content.btn-camera-added,
.ant-layout-sider .camera-content > .ant-btn.btn-camera-added,
.ant-layout-sider .camera-content.btn-remove-camera,
.ant-layout-sider .camera-content > .ant-btn.btn-remove-camera {
  background-color: rgba(0, 0, 0, 0.5);
}
.camera-content {
  padding-top: 1.25rem;
}
.camera-content > .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgba(250, 250, 250, 0.3);
  z-index: 16;
}
.camera-content > .ant-btn,
.camera-content > .ant-btn:hover,
.camera-content > .ant-btn:focus,
.camera-content > .ant-btn:active {
  color: #fff;
}
.camera-content > .ant-btn .btn-text {
  position: relative;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.375);
}
.camera-content > .ant-btn .btn-text-added,
.camera-content > .ant-btn .btn-text-remove {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 1.25rem);
  opacity: 0;
}
.camera-content > .ant-btn .btn-text-remove {
  opacity: 0;
}
.camera-content > .ant-btn .btn-text-remove .icon.icon-close {
  padding-right: 1px;
  padding-top: 1px;
}
.camera-content > .ant-btn .btn-text-added {
  opacity: 1;
}
.camera-content > .ant-btn:hover .btn-text-remove {
  opacity: 1;
}
.camera-content > .ant-btn:hover .btn-text-added {
  opacity: 0;
}
.camera-content > .ant-btn .icon,
.camera-content > .ant-btn .anticon {
  display: block;
  font-size: 3.25rem;
  margin: 0 auto 0.625rem;
}
.camera-content > .ant-btn .icon svg,
.camera-content > .ant-btn .anticon svg {
  margin: 0 auto;
}
.camera-content > .ant-btn.ant-btn-sm {
  font-size: 0.75rem;
}
.camera-content > .ant-btn.ant-btn-sm .icon,
.camera-content > .ant-btn.ant-btn-sm .anticon {
  font-size: 1.75rem;
  margin-bottom: 0.3125rem;
}
.camera-image + .camera-content > .ant-btn {
  border: 0;
  background: rgba(0, 0, 0, 0.8);
}
.camera-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.25rem;
  padding: 0.2rem 0.3125rem;
  z-index: 1;
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.camera-name {
  margin: 0 0.5rem 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  font-weight: 300;
}
.camera:hover .camera-thumbnail-content .camera-header {
  height: auto;
}
.camera:hover .camera-thumbnail-content .camera-name {
  overflow: visible;
  white-space: normal;
}
.camera-link {
  display: block;
  position: relative;
  padding: 3px;
}
.camera-link:hover,
.camera-link:focus,
.camera-link:active,
.camera-link.active {
  color: inherit;
}
.camera-link:focus {
  background: transparent;
}
.camera-link:focus:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  box-shadow: 0 0 1px 3px #e8f885;
}
.camera-link:hover,
.camera-link:active,
.camera-link.active {
  background: #b6daff;
}
.camera-link:hover:before,
.camera-link:active:before,
.camera-link.active:before {
  display: none;
}
.camera-theme-dark {
  color: #fff;
}
.camera-theme-dark .ant-spin-dot i {
  background-color: #fff;
}
.camera-theme-dark .camera-image {
  background: #000;
}
.camera-list .ant-list-item {
  padding: 0;
  border: 0;
}
.camera-list .ant-list-item + .ant-list-item {
  margin-top: 0.5rem;
}
.camera-view-page > .page-header {
  flex-wrap: nowrap;
}
.camera-view-page__header__sorting .ant-btn {
  margin-left: 1rem;
}
.camera-view-page > .ant-layout > .ant-layout-content {
  overflow-y: hidden;
  flex: 1 0;
}
.camera-player {
  color: #fff;
}
.camera-player-content {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.camera-player-body {
  position: relative;
}
.camera-player-toolbar {
  height: 3.75rem;
  background: #13213a;
  text-align: center;
  min-width: min-content;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  width: stretch;
}
.camera-player-toolbar a {
  text-decoration: none;
}
.camera-player-toolbar-groups,
.camera-player-toolbar-group,
.camera-player-toolbar .toolbar-buttons {
  display: flex;
  align-items: center;
}
.camera-player-toolbar-groups {
  height: 100%;
}
.camera-player-toolbar-group {
  padding: 0 0.3125rem;
  margin-left: auto;
}
.camera-player-toolbar-group:first-child {
  margin-left: 0;
}
.camera-player-toolbar-group-main {
  justify-content: center;
  align-items: center;
  flex: 1;
}
.camera-player-toolbar .toolbar-buttons {
  justify-content: center;
}
.camera-player-toolbar .ant-btn {
  display: block;
  margin: 0 0.3125rem;
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
}
.camera-player-toolbar .ant-btn:hover,
.camera-player-toolbar .ant-btn:focus,
.camera-player-toolbar .ant-btn:active {
  box-shadow: none;
}
.camera-player-toolbar .ant-btn:hover,
.camera-player-toolbar .ant-btn:focus {
  color: #b6daff;
}
.camera-player-toolbar .ant-btn:active {
  color: #fff;
}
.camera-player-toolbar .ant-btn .icon {
  margin-right: 0;
  font-size: 1.875rem;
}
.camera-player-toolbar .ant-btn .icon,
.camera-player-toolbar .ant-btn .icon:before {
  display: block;
}
.camera-player-toolbar .ant-btn-link-alt:hover,
.camera-player-toolbar .ant-btn-link-alt:focus {
  color: #fff;
}
.camera-player-toolbar .ant-btn-link-alt:active {
  color: #b6daff;
}
.camera-player-toolbar .ant-btn-dark {
  width: auto;
  min-width: 11.25rem;
  height: 2.25rem;
  font-size: 0.875rem;
  font-weight: 300;
  text-align: center;
  text-transform: none;
}
.camera-player-toolbar .player-action-btn {
  padding: 0;
  width: 2.1875rem;
  height: 2.1875rem;
}
.camera-player-toolbar .btn-speed {
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  font-weight: 400;
}
.camera-player-toolbar .btn-speed .speed-value:after {
  content: "x";
  font-size: 0.4375rem;
}
.camera-player-toolbar .camera-player-duration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.camera-player-toolbar .label {
  margin: 0 0 0.3125rem;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.camera-player[data-fullscreen="true"] > .camera-player-content {
  position: absolute;
  opacity: 0;
}
.camera-player-ptz {
  position: absolute;
  top: 1.25rem;
  bottom: 0.25rem;
  left: 0;
  width: 100%;
  z-index: 14;
}
.camera-player-ptz .btn-ptz-up,
.camera-player-ptz .btn-ptz-down,
.camera-player-ptz .btn-ptz-left,
.camera-player-ptz .btn-ptz-right {
  position: absolute;
  min-width: 3.125rem;
  min-height: 3.125rem;
  width: 20%;
  height: 20%;
  overflow: hidden;
  font-size: 2.875rem;
}
.camera-player-ptz .btn-ptz-up:hover .icon:before,
.camera-player-ptz .btn-ptz-down:hover .icon:before,
.camera-player-ptz .btn-ptz-left:hover .icon:before,
.camera-player-ptz .btn-ptz-right:hover .icon:before {
  font-size: 1.125em;
}
.camera-player-ptz .btn-ptz-up .icon,
.camera-player-ptz .btn-ptz-down .icon,
.camera-player-ptz .btn-ptz-left .icon,
.camera-player-ptz .btn-ptz-right .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  text-shadow: -0.0625rem -0.0625rem 0 rgba(19, 33, 58, 0.8), 0.0625rem -0.0625rem 0 rgba(19, 33, 58, 0.8), -0.0625rem 0.0625rem 0 rgba(19, 33, 58, 0.8), 0.0625rem 0.0625rem 0 rgba(19, 33, 58, 0.8);
}
.camera-player-ptz .btn-ptz-up .icon:before,
.camera-player-ptz .btn-ptz-down .icon:before,
.camera-player-ptz .btn-ptz-left .icon:before,
.camera-player-ptz .btn-ptz-right .icon:before {
  padding: 0.125rem;
  transition: 150ms cubic-bezier(0.25, 0, 0, 1) font-size;
}
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-up .icon,
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-down .icon,
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-left .icon,
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-right .icon {
  font-size: 4rem;
}
.camera-player-ptz .btn-ptz-up,
.camera-player-ptz .btn-ptz-down {
  left: 50%;
  transform: translateX(-50%);
  padding: 7% 16%;
}
.camera-player-ptz .btn-ptz-up {
  top: 0.625rem;
}
.camera-player-ptz .btn-ptz-up .icon {
  top: 30%;
}
.camera-player-ptz .btn-ptz-down {
  bottom: 0.625rem;
}
.camera-player-ptz .btn-ptz-down .icon {
  top: 70%;
}
.camera-player-ptz .btn-ptz-left,
.camera-player-ptz .btn-ptz-right {
  top: 50%;
  transform: translateY(-50%);
  padding: 16% 9%;
}
.camera-player-ptz .btn-ptz-left {
  left: 0.3125rem;
}
.camera-player-ptz .btn-ptz-left .icon {
  left: 30%;
}
.camera-player-ptz .btn-ptz-right {
  right: 0.3125rem;
}
.camera-player-ptz .btn-ptz-right .icon {
  left: 70%;
}
.camera-player-ptz-zoom,
.camera-player-ptz-preset {
  position: absolute;
  right: 5%;
  bottom: 7%;
  width: 1.6875rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.camera-player-ptz .btn-ptz-preset {
  margin-right: calc(1.75em + 2px);
}
.camera-player-ptz .btn-ptz-zoom-in,
.camera-player-ptz .btn-ptz-zoom-out,
.camera-player-ptz .btn-ptz-preset {
  width: 1em;
  height: 1em;
  padding: 0;
  font-size: 1.875rem;
  margin-top: 0.75em;
}
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-zoom-in,
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-zoom-out,
.camera-frame-fullscreen .camera-player-ptz .btn-ptz-preset {
  font-size: 2.8125rem;
}
.camera-player-ptz .btn-ptz-zoom-in .icon,
.camera-player-ptz .btn-ptz-zoom-out .icon,
.camera-player-ptz .btn-ptz-preset .icon {
  width: 100%;
  height: 100%;
  text-shadow: -0.0625rem -0.0625rem 1px rgba(19, 33, 58, 0.4), 0.0625rem -0.0625rem 1px rgba(19, 33, 58, 0.4), -0.0625rem 0.0625rem 1px rgba(19, 33, 58, 0.4), 0.0625rem 0.0625rem 1px rgba(19, 33, 58, 0.4);
}
.camera-player-ptz .btn-ptz-zoom-in .anticon,
.camera-player-ptz .btn-ptz-zoom-out .anticon,
.camera-player-ptz .btn-ptz-preset .anticon {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
}
.camera-player-single {
  /*  width: calc(~"100% - 2.25rem");
    height: calc(~"100% - 2.25rem");*/
  padding-right: 1.25rem;
  margin-top: 3px;
  /*
    .camera-player-content,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .camera {
      height: 100%;
    }
    .camera-player-frames {
      height: calc(~"100% - " @toolbar-height);
    }*/
}
.camera-player-single .camera-frame {
  width: 100%;
}
.camera-player-single .camera-player-content {
  align-items: center;
}
.camera-player-single .camera-player-frames {
  width: 100%;
}
.camera-player-multiple,
.investigation-page .camera-player-single {
  display: flex;
  flex: auto;
}
.camera-player-multiple .camera-player-content,
.investigation-page .camera-player-single .camera-player-content {
  justify-content: space-between;
  align-items: center;
}
.camera-player-multiple .camera-player-frames,
.investigation-page .camera-player-single .camera-player-frames {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% -  5.0625rem);
  align-content: center;
}
.camera-player-multiple .camera-player-toolbar,
.investigation-page .camera-player-single .camera-player-toolbar {
  height: 5.0625rem;
  width: 100%;
}
.camera-player-multiple .camera-frame {
  width: 50%;
  padding: 0 0.3125rem 0.625rem;
  margin-bottom: 0.3125rem;
}
.camera-player-multiple .camera-frame-fullscreen.camera-frame {
  margin: 0;
  padding: 0;
}
.camera-player-multiple .camera-frame-fullscreen.camera-frame .camera-thumbnail,
.camera-player-multiple .camera-frame-fullscreen.camera-frame .camera-image,
.camera-player-multiple .camera-frame-fullscreen.camera-frame .camera-canvas {
  margin-bottom: 0;
}
.camera-player-multiple .camera-frame .camera-thumbnail,
.camera-player-multiple .camera-frame .camera-image,
.camera-player-multiple .camera-frame .camera-canvas {
  margin-bottom: -0.3125rem;
}
.camera-player[data-exports] + .camera-player,
.camera-grid + .camera-player {
  display: none;
}
.camera-player .btn-fullscreen,
.camera-player .btn-exit-fullscreen {
  position: absolute;
  top: 1.875rem;
  right: 0.625rem;
  padding: 0.5rem;
  z-index: 15;
}
.camera-player .btn-fullscreen .icon,
.camera-player .btn-exit-fullscreen .icon {
  display: block;
}
.camera-player .icon-fullscreen {
  font-size: 1.75rem;
}
.camera-player .icon-exit {
  font-size: 3rem;
}
.camera-player-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}
div.camera-player-fullscreen .camera-frame.camera-frame-fullscreen {
  flex: 1 1 100%;
  z-index: 1;
  width: 100%;
  max-width: calc((100vh -  3.75rem ) / var(--camera-aspect-ratio) );
  max-height: calc(100vh -  3.75rem );
}
.investigation-page div.camera-player-fullscreen .camera-frame.camera-frame-fullscreen {
  max-width: calc((100vh -  5.0625rem  - 2.5rem) / var(--camera-aspect-ratio) );
}
.camera-player-fullscreen .camera-player-toolbar {
  flex: 0 0 auto;
}
.camera-player-single .camera-player-fullscreen .camera-frame {
  width: 85vw;
  max-width: 113vh;
}
.camera-player-page > .ant-layout-has-sider {
  position: relative;
}
.camera-player-page > .ant-layout-has-sider > .ant-layout-sider {
  overflow-y: auto;
}
.camera-player-page .camera-player-single {
  height: 100%;
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera-player-page .camera-player-single .camera-player-content {
  width: 100%;
}
.camera-player-sequence {
  position: absolute;
  top: -1px;
  bottom: -1px;
  min-width: 0.125rem;
  background-color: #e64230;
}
.camera-player-sequence::selection {
  background-color: transparent;
}
.camera-player-sequence:hover {
  background-color: #e8f885;
}
.camera-player-sequence:hover:after {
  content: attr(data-text);
  display: block;
  position: absolute;
  font-size: 0.875rem;
  top: calc(-200% - 8px);
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 3px;
}
.camera-player-sequence-active {
  background-color: #fdcf7e;
}
.camera-player-sequence,
.camera-player-sequences-cursor {
  transition: background-color 200ms cubic-bezier(0.25, 0, 0, 1);
}
.camera-player-fullscreen .camera-player-sequences-bar {
  height: 2.5rem;
  width: 100%;
}
.camera-player-fullscreen .camera-player-sequences-cursor {
  width: 0.25rem;
}
.camera-player-fullscreen .camera-player-sequences-cursor:hover {
  width: 0.3125rem;
}
.camera-player-sequences {
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%;
}
.camera-player-sequences-cursor {
  position: absolute;
  background-color: #fff;
  width: 0.1875rem;
  transform: translateX(-50%);
  top: -1px;
  bottom: -1px;
  transition-property: background-color, top, bottom, width;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
.camera-player-sequences-cursor::selection {
  background-color: transparent;
}
.camera-player-sequences-cursor:hover {
  top: -3px;
  bottom: -3px;
  width: 0.25rem;
}
.camera-player-sequences-cursor-ghost {
  background-color: rgba(255, 255, 255, 0.6);
}
.camera-player-sequences-cursor-ghost:after {
  content: attr(data-text);
  display: none;
  position: absolute;
  top: calc(-100% - 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 3px;
}
.camera-player-sequences:hover .camera-player-sequences-cursor-ghost:after {
  display: block;
}
.camera-player-sequences-bar {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.25rem;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px dashed #e64230;
  border-left: none;
  border-right: none;
}
.camera-player-fullscreen .camera-player-sequences-bar {
  z-index: 10;
  bottom: 3.75rem;
  position: static;
}
.camera-player-multiple .camera-player-fullscreen .camera-player-sequences-bar {
  bottom: 5.0625rem;
}
.camera-is-playing + .camera-player-sequences-bar {
  transition: 200ms cubic-bezier(0.25, 0, 0.375, 1);
  transition-delay: 1.5s;
  transition-property: height, border-width, border-image-width, padding;
  height: 2px;
  border-width: 0;
  border-image-width: 0;
  padding: 1px 0;
}
.camera-player-fullscreen .camera-is-playing + .camera-player-sequences-bar {
  height: 4px;
  border-width: 0;
  border-image-width: 0;
  padding: 1px 0;
}
.camera-frame > div > .ant-spin-container:hover .camera-is-playing + .camera-player-sequences-bar,
.camera-player-fullscreen:hover > .camera-is-playing + .camera-player-sequences-bar {
  transition-delay: 0ms;
  border-width: 1px;
  border-image-width: 1px;
  height: 1.25rem;
  padding: 0;
}
.camera-player-fullscreen .camera-frame > div > .ant-spin-container:hover .camera-is-playing + .camera-player-sequences-bar,
.camera-player-fullscreen .camera-player-fullscreen:hover > .camera-is-playing + .camera-player-sequences-bar {
  border-width: 1px;
  border-image-width: 1px;
  height: 2.5rem;
  padding: 0;
}
.camera-frame {
  position: relative;
}
.camera-frame .placeholder {
  width: 100%;
  height: 100%;
  min-height: 15.5rem;
  opacity: 0.3;
  border: 2px dashed #fff;
}
.camera-frame[data-playing="true"] {
  background-color: #234982;
}
.camera-frame[data-playing="true"] > div .camera-header,
.camera-frame[data-playing="true"] > div .btn-fullscreen {
  transform: translateY(-1.25rem);
  transition: 200ms cubic-bezier(0.25, 0, 0.375, 1);
  transition-delay: 1.5s;
  transition-property: transform, opacity;
}
.camera-frame[data-playing="true"] > div .camera-header {
  top: 0rem;
}
.camera-frame[data-playing="true"] > div .btn-fullscreen {
  top: 1.875rem;
  opacity: 0;
}
.camera-frame[data-playing="true"] > div:hover .camera-header,
.camera-frame[data-playing="true"] > div:hover .btn-fullscreen {
  transition-delay: 0ms;
  transform: translateY(0rem);
}
.camera-frame[data-playing="true"] > div:hover .btn-fullscreen {
  opacity: 1;
}
.camera-frame[data-blank="true"] .camera-thumbnail-content {
  background-color: black;
}
.camera-frame[data-blank="true"] .camera-thumbnail-content img,
.camera-frame[data-blank="true"] .camera-thumbnail-content canvas,
.camera-frame[data-blank="true"] .camera-thumbnail-content video,
.camera-frame[data-blank="true"] .camera-thumbnail-content videos-stream {
  opacity: 0;
}
.camera-frame .camera-header,
.camera-frame .camera-player-sequences-bar {
  z-index: 12;
}
[data-playing="true"].camera-frame .camera-thumbnail-content > .camera-image {
  z-index: 10;
}
.camera-frame .camera-thumbnail-content > canvas,
.camera-frame .camera-thumbnail-content > videos-stream,
.camera-frame .camera-thumbnail-content > video {
  z-index: 11;
}
.camera-frame__reconnect {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
}
.camera-frame__reconnect__button {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  margin-top: 2rem;
  cursor: pointer;
}
.camera-frame__reconnect__button:disabled {
  pointer-events: none;
}
.camera-frame__reconnect__button,
.camera-frame__reconnect__button svg {
  width: 3rem;
  height: 3rem;
  padding: 0;
}
.camera-frame__reconnect .ant-spin {
  margin-top: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera-frame[data-is-blank="false"] videos-stream,
.camera-feed-thumbnail videos-stream {
  background-color: black;
}
.camera-feed-thumbnail {
  position: relative;
}
.camera-feed-thumbnail canvas,
.camera-feed-thumbnail video,
.camera-feed-thumbnail videos-stream {
  display: none;
}
.camera-is-playing .camera-feed-thumbnail .camera-image canvas,
.camera-is-playing .camera-feed-thumbnail .camera-image video,
.camera-is-playing .camera-feed-thumbnail .camera-image videos-stream {
  display: block;
  z-index: 0;
}
.camera-is-playing .camera-feed-thumbnail .camera-image img {
  z-index: -1;
}
.camera-player-page .camera-player-single .camera-player-content {
  max-width: calc((100vh -  8.375rem + 6.75rem + 1.25rem ) /  var(--camera-aspect-ratio));
}
.investigation-page .camera-player-single > .camera-player-content .camera-frame {
  max-width: calc((100vh -  8.375rem + 5.0625rem + 1.25rem ) /  var(--camera-aspect-ratio));
}
.camera-player-multiple .camera-player-frames {
  max-width: calc((100vh -  8.375rem + 2.375rem + 1.25rem ) /  0.75);
}
.camera-player-multiple[data-camera-count="2"] .camera-player-frames {
  max-width: 100%;
}
.investigation-page .camera-draggable-grid-items {
  max-width: calc((100vh -  8.375rem + 4.125rem + 1.25rem ) /  0.75);
}
@keyframes camera-title-show {
  0% {
    top: -1.25rem;
  }
  100% {
    top: 0;
  }
}
@keyframes camera-title-hide {
  0% {
    top: 0;
  }
  100% {
    top: -1.25rem;
  }
}
@keyframes camera-fs-btn-show {
  0% {
    top: 0.625rem;
    opacity: 0;
  }
  100% {
    top: 1.875rem;
    opacity: 1;
  }
}
@keyframes camera-fs-btn-hide {
  0% {
    top: 1.875rem;
    opacity: 1;
  }
  100% {
    top: 0.625rem;
    opacity: 0;
  }
}
.camera-grid .ant-row {
  display: flex;
  flex-wrap: wrap;
}
.camera-grid [class*="ant-col-"],
.camera-grid .ant-list-item,
.camera-grid .ant-list-item-content {
  display: flex;
}
.camera-grid .ant-list-item,
.camera-grid .ant-list-item-content,
.camera-grid .camera,
.camera-grid .camera .btn-toggle-mode {
  width: 100%;
  height: 100%;
}
.camera-grid .ant-list-item {
  margin-bottom: 0;
}
.camera-grid [class*="ant-col-"] {
  margin-bottom: 0.625rem;
}
.camera-grid-editable {
  width: 36.75rem;
}
.camera-grid-editable .camera {
  height: 13.5625rem;
}
.column-options {
  display: flex;
}
.column-options .ant-btn {
  display: block;
  padding: 0;
  margin-left: 0.5625rem;
  height: 1.75rem;
}
.column-options .icon {
  font-size: 4.625rem;
  line-height: 1.75rem;
}
.column-options .icon,
.column-options .icon:before {
  display: block;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content {
  position: relative;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content > header.page-header {
  background-color: #234982;
  top: 0;
  position: sticky;
  margin: 0 -1.25rem;
  padding: 1.25rem;
  width: calc(100% + 2.5rem);
  z-index: 15;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content > header.page-header + .ant-layout:not(.ant-layout-has-sider) {
  margin-bottom: 2rem;
  z-index: 8;
}
.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content > header.page-header + .ant-layout-has-sider > .ant-layout-sider {
  margin-bottom: 2rem;
  height: calc(100vh - 2rem -  4.375rem + 4rem);
  max-height: calc(100vh - 2rem -  4.375rem + 4rem);
  z-index: 8;
}
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item .camera {
  padding: 3px;
  transform: scale(0.95);
}
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item:hover .camera {
  transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
}
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item .camera-thumbnail-content {
  z-index: 2;
}
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item .camera-content {
  z-index: 3;
}
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item.dragging .camera,
.camera-draggable-grid[data-enabled="true"] .camera-draggable-grid-item:active .camera {
  transform: scale(1.05);
}
.camera-draggable-grid[data-enabled="true"]:not(:hover) .camera-draggable-grid-item .camera {
  transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
}
.camera-draggable-grid[data-enabled="false"] .camera-draggable-grid-item {
  cursor: default;
}
.camera-draggable-grid[data-enabled="false"] .camera-draggable-grid-item .camera {
  transform: scale(1);
  transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
}
.camera-draggable-grid-items {
  display: flex;
  flex-wrap: wrap;
}
.camera-draggable-grid-item {
  display: flex;
  padding: 0 0.125rem;
  margin-bottom: 0.25rem;
  width: 50%;
  cursor: grab;
}
.camera-draggable-grid-item a {
  display: flex;
  width: 100%;
}
.camera-draggable-grid-item.dragging,
.camera-draggable-grid-item:active {
  opacity: 0.7;
  z-index: 9;
  cursor: grabbing;
}
.camera-draggable-grid-item.dragging .camera,
.camera-draggable-grid-item:active .camera {
  cursor: grabbing;
}
.camera-draggable-grid-item .camera {
  width: 100%;
  height: 100%;
}
.camera-draggable-grid-item .camera:hover {
  transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
}
.camera-draggable-grid-item .camera > .camera-thumbnail {
  color: #fff;
}
.camera-draggable-grid--columns-4 .camera-draggable-grid-item {
  width: 25%;
}
.camera-draggable-grid--columns-6 .camera-draggable-grid-item {
  width: 16.66%;
}
body > .camera-draggable-grid-item.dragging {
  cursor: grabbing;
}
body > .camera-draggable-grid-item.dragging .camera {
  transform: scale(1.05);
}
@keyframes StartRearrange {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
@keyframes EndRearrange {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
.camera-search {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
}
.camera-search-scrolling-spinner {
  position: absolute;
}
.camera-search-items-content {
  margin-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.camera-search-items,
.camera-search-items .ant-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;
}
.camera-search-items .ant-list-header {
  flex-shrink: 0;
}
.camera-search-items .ant-spin-nested-loading {
  display: flex;
  flex: 1 1 auto;
  min-height: 1px;
}
.camera-search-items .ant-spin-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.camera-search-items .ant-list-item {
  width: 50%;
}
.camera-search-items .ant-list-item:nth-child(even) {
  padding-left: 0.3125rem;
}
.camera-search-items .ant-list-item:nth-child(odd) {
  padding-right: 0.3125rem;
}
.camera-search-items .ant-list-item:nth-child(2) {
  margin-top: 0;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box .camera-search-items .ant-list-item-content-single,
.camera-search-items .ant-list-item-meta {
  flex: 1;
}
.camera-search-row {
  padding-bottom: 0.625rem;
}
.camera-search-row .ant-spin-container {
  width: 19.125rem;
}
.camera-search-row .ant-list-item {
  margin-bottom: 0;
}
.camera-search .camera {
  position: relative;
  width: calc(50% - 0.5rem);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.camera-search .camera .camera-content .btn-text {
  margin-top: 1.25rem;
}
.camera-search .camera--active {
  background-color: rgba(0, 0, 0, 0.375);
}
.camera-search .camera:not(.camera--active):hover .camera-content,
.camera-search .camera:not(.camera--active):focus .camera-content {
  opacity: 1;
}
.camera-search .camera:not(.camera--active) .camera-content {
  opacity: 0;
  transition: opacity 0.2s;
}
.camera-player-datepicker .ant-modal-header {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}
.camera-player-datepicker .btn-toggle-live {
  position: absolute;
  top: 0;
  left: 0.625rem;
  padding: 0.3125rem;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
}
.camera-player-datepicker .btn-toggle-live .icon {
  margin-right: 0.25rem;
  font-size: 1.875rem;
}
.camera-player-datepicker .timestamp-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 16.25rem;
  margin-bottom: 1.25rem;
}
.camera-player-datepicker .timestamp-nav-next,
.camera-player-datepicker .timestamp-nav-prev {
  height: auto;
  min-width: 9.0625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.camera-player-datepicker .timestamp-nav-next .icon,
.camera-player-datepicker .timestamp-nav-prev .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
}
.camera-player-datepicker .timestamp-nav-prev {
  padding-left: 1.625rem;
  text-align: right;
}
.camera-player-datepicker .timestamp-nav-prev .icon {
  left: 0;
}
.camera-player-datepicker .timestamp-nav-next {
  padding-right: 1.625rem;
}
.camera-player-datepicker .timestamp-nav-next .icon {
  right: 0;
}
.camera-player-datepicker .timestamp-nav-thumbnail {
  flex: 1;
  background: #000;
}
.camera-player-datepicker-input,
.camera-player-datepicker .ant-modal-footer {
  text-align: center;
}
.logout-modal .modal-text {
  font-size: 1.125rem;
  font-weight: bold;
}
.datepicker-modal-datepicker .btn-toggle-live {
  font-size: 0.8125rem;
  text-transform: uppercase;
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  height: 2rem;
  line-height: 2rem;
}
.datepicker-modal-datepicker .btn-toggle-live .icon.icon-live {
  font-size: 1.9375rem;
  margin-right: 0.125rem;
}
.datepicker-modal-datepicker .timestamp-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 14.625em;
  padding-bottom: 1.5em;
}
.datepicker-modal-datepicker .timestamp-nav-prev,
.datepicker-modal-datepicker .timestamp-nav-next {
  height: 4.0625em;
  width: 8.8125em;
  flex: 0 0 9em;
}
.datepicker-modal-datepicker .timestamp-nav-prev .btn-text {
  text-align: right;
}
.datepicker-modal-datepicker .timestamp-nav-next .btn-text {
  text-align: left;
}
.datepicker-modal-datepicker .timestamp-nav-thumbnail {
  flex: 0 0 auto;
}
.datepicker-modal-datepicker .timestamp-nav-thumbnail img {
  max-width: 16em;
}
.datepicker-modal-datepicker .ant-modal-footer,
.datepicker-modal-datepicker .datepicker-modal-datepicker-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-calendar-picker,
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-time-picker {
  margin: 0 0.75em;
}
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-calendar-picker,
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-time-picker,
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-calendar-picker-input,
.datepicker-modal-datepicker .datepicker-modal-datepicker-input .ant-time-picker-input {
  width: 10em;
}
.datepicker-modal-datepicker .ant-modal-footer .ant-btn-primary {
  padding: 0 3em;
}
.investigation-export-list {
  width: 100%;
  height: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background: rgba(0, 0, 0, 0.8);
}
.investigation-export-list .ant-list-item {
  padding: 0.4375rem 1rem;
}
.camera-player-single .investigation-export-list .ant-list-item-content {
  flex: 0;
}
.camera-player-single .investigation-export-list .ant-list-item-content:first-child {
  flex: 0 0 12rem;
}
.camera-player-single .investigation-export-list .ant-list-item-meta {
  flex: 0 0 12rem;
}
.investigation-export-list .ant-list-item-meta-content {
  font-size: 0.8125rem;
}
.investigation-export-list .ant-list-item-meta-title {
  color: white;
}
.investigation-export-list .ant-list-item-meta-title,
.investigation-export-list .ant-list-item-meta-description {
  font-weight: 300;
  font-size: 0.8125rem;
}
.investigation-export-list .ant-list-item-meta-description {
  font-size: 0.8125rem;
  color: #b6daff;
}
.investigation-export-list .ant-list-item-action {
  display: flex;
  width: 7.0625rem;
  margin-left: 0.3125rem;
}
.investigation-export-list .ant-list-item-action > li {
  display: flex;
  flex: 1;
}
.investigation-export-list .ant-list-item-action,
.investigation-export-list .ant-list-item-action > li,
.investigation-export-list .ant-list-item-action .ant-btn-group {
  justify-content: flex-end;
}
.investigation-export-list .ant-list-item-action .ant-btn,
.investigation-export-list .ant-list-item-action .ant-progress {
  width: 5.5625rem;
}
.investigation-export-list .ant-list-item-action .btn-cancel {
  width: 1.5rem;
}
.investigation-export-list .ant-list-item-action .ant-progress {
  position: relative;
  border: 1px solid #13213a;
  border-radius: 4px;
  overflow: hidden;
}
.investigation-export-list .ant-list-item-action .ant-progress:not(:only-child) {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.investigation-export-list .ant-list-item-action .ant-progress-outer {
  padding: 0;
  margin: 0;
}
.investigation-export-list .ant-list-item-action .ant-progress-inner {
  border-radius: 0;
}
.investigation-export-list .ant-list-item-action .ant-progress-text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #13213a;
  transform: translateY(-50%);
}
.investigation-export-list .export-error {
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;
}
.investigation-export-list .export-error .anticon-exclamation-circle {
  margin-right: 0.375rem;
  font-size: 1.125rem;
}
.investigation-export-list .export-error .anticon-exclamation-circle path {
  fill: #e64230;
  stroke: #e64230;
}
.investigation-export__no-content {
  color: white;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  text-align: center;
}
.investigation-export__no-content .ant-spin-dot i {
  background-color: white;
}
.investigation-page .page-header {
  margin-top: 0;
  margin-bottom: 0;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.investigation-page .page-header h1.page-title {
  flex: 0 1 auto;
  word-break: break-all;
}
.investigation-page .page-header .header-extras {
  flex: 0 0 auto;
  margin-left: 0;
}
.ant-layout-has-sider > .investigation-page.ant-layout-content {
  padding-left: 0;
  padding-right: 0;
}
.ant-layout-has-sider > .investigation-page.ant-layout-content .camera-grid,
.ant-layout-has-sider > .investigation-page.ant-layout-content .page-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.ant-layout-has-sider > .investigation-page.ant-layout-content .camera-player-frames {
  padding: 0.625rem 1.25rem;
}
.investigation-page .camera-player-multiple .camera-frame,
.investigation-page .camera-grid .camera-draggable-grid-item {
  max-width: calc((100vh -  13.4375rem ) / ( 2 * 0.75));
}
.investigation-page .camera-player-multiple[data-camera-count="2"] .camera-frame {
  max-width: calc((100vh -  13.4375rem ) / ( 0.75));
}
.investigation-page .camera-player-single {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.investigation-page .camera-player-single .camera-player-frames {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% -  5.0625rem);
}
.investigation-page .camera-player-single .camera-frame-empty {
  display: none;
}
.investigation-page .camera-grid {
  height: calc(100% -  5.0625rem + 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.investigation-page .camera-grid .camera-draggable-grid-items {
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0;
}
.investigation-list.list-panel {
  max-height: 100%;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box > .investigation-list.list-panel,
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box > .investigation-list.list-panel > .ant-list,
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box > .investigation-list.list-panel > .ant-spin-nested-loading {
  width: 100%;
}
.investigation-list.list-panel > .ant-list {
  height: 100%;
}
.investigation-list.list-panel > .ant-list > .ant-spin-nested-loading {
  height: calc(100% - 3.75rem);
}
.investigation-list.list-panel > .ant-list > .ant-spin-nested-loading > .ant-spin-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
}
[data-length="1"].investigation-list.list-panel > .ant-list > .ant-spin-nested-loading > .ant-spin-container {
  overflow-y: visible;
}
.investigation-list.list-panel > .ant-list > .ant-spin-nested-loading > .ant-spin-container > .ant-list-item.ant-list-item--block {
  justify-content: space-between;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box {
  width: 100%;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box .ant-list-item-content-single,
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box .ant-list-item-meta {
  flex: 0 1 auto;
  width: auto;
}
.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box .ant-list-item-meta-title {
  word-break: break-all;
}
.export-confirm-delete .ant-popover-inner-content {
  padding: 0;
}
.export-confirm-delete .ant-popover-message {
  padding: 0;
}
.export-confirm-delete .ant-popover-message .anticon {
  display: none;
}
.export-confirm-delete .ant-popover-message-title {
  padding: 0.75rem;
}
.export-confirm-delete,
.export-confirm-delete .ant-popover-message,
.export-confirm-delete .ant-popover-buttons {
  max-width: 10rem;
  min-width: 10rem;
}
.export-confirm-delete .ant-popover-buttons {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
.export-confirm-delete .ant-popover-buttons button,
.export-confirm-delete .ant-popover-buttons button.ant-btn,
.export-confirm-delete .ant-popover-buttons button.ant-btn.ant-btn-sm {
  font-size: 0.9375rem;
  border: none;
  border-top: 1px solid #13213a;
  border-radius: 0;
  background-color: white;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  text-align: center;
}
.export-confirm-delete .ant-popover-buttons button:hover,
.export-confirm-delete .ant-popover-buttons button.ant-btn:hover,
.export-confirm-delete .ant-popover-buttons button.ant-btn.ant-btn-sm:hover,
.export-confirm-delete .ant-popover-buttons button:focus,
.export-confirm-delete .ant-popover-buttons button.ant-btn:focus,
.export-confirm-delete .ant-popover-buttons button.ant-btn.ant-btn-sm:focus {
  background-color: #b6daff;
  box-shadow: none;
}
.export-confirm-delete .ant-popover-buttons button:first-child,
.export-confirm-delete .ant-popover-buttons button.ant-btn:first-child,
.export-confirm-delete .ant-popover-buttons button.ant-btn.ant-btn-sm:first-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-layout-has-sider > .export-confirm-delete-page.ant-layout-content {
  padding: 0;
}
.ant-layout-has-sider > .export-confirm-delete-page.ant-layout-content .page-header,
.ant-layout-has-sider > .export-confirm-delete-page.ant-layout-content .camera-player-frames,
.ant-layout-has-sider > .export-confirm-delete-page.ant-layout-content .camera-player-toolbar-groups {
  padding: 0 1.25rem;
}
[data-length="1"].investigation-list .ant-list-item.ant-list-item--block {
  overflow-y: visible;
}
.investigation-list .ant-list-item.ant-list-item--block:last-child:not(:first-child) .dropdown-container.active .ant-dropdown-trigger {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.investigation-list .ant-list-item.ant-list-item--block:last-child:not(:first-child) .dropdown-container .ant-dropdown-menu {
  transform: translateY(-100%);
  border-top: 1px solid #13213a;
}
.investigation-list .ant-list-item.ant-list-item--block:last-child:not(:first-child) .dropdown-container .ant-dropdown-menu,
.investigation-list .ant-list-item.ant-list-item--block:last-child:not(:first-child) .dropdown-container .ant-dropdown-menu-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.investigation-list .ant-list-item.ant-list-item--block:last-child:not(:first-child) .dropdown-container > div {
  top: 2px !important;
}
@keyframes antSlideDownInSpecial {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8) translateY(-100%);
    transform: scaleY(0.8) translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1) translateY(-100%);
    transform: scaleY(1) translateY(-100%);
  }
}
.search-form .ant-form-item {
  margin: 0;
}
.search-form .ant-form-item,
.search-form .ant-form-item-control,
.search-form .ant-form-item-children {
  display: block;
  height: 100%;
}
.search-form .ant-form-item-children {
  display: block;
  width: 100%;
}
.search-form .ant-input-search {
  position: relative;
  width: 19.125rem;
  height: 2.5625rem;
  transition: width 0.2s;
}
.search-form .ant-input-search:not(.collapsible) {
  display: block;
}
.search-form .ant-input-search .ant-input-suffix {
  width: 3.13rem;
  height: 100%;
}
.search-form .ant-input-search .ant-input,
.search-form .ant-input-search .ant-input-search-button {
  display: block;
  height: 100%;
}
.search-form .ant-input-search .ant-input {
  width: 19.125rem;
  font-size: 0.875rem;
  transition: opacity 0.1s;
  opacity: 1;
}
.search-form .ant-input-search:focus .ant-input,
.search-form .ant-input-search:hover .ant-input,
.search-form .ant-input-search:active .ant-input {
  display: block;
  height: 100%;
}
.search-form .ant-input-search:focus .ant-input:not(.ant-input-disabled),
.search-form .ant-input-search:hover .ant-input:not(.ant-input-disabled),
.search-form .ant-input-search:active .ant-input:not(.ant-input-disabled) {
  box-shadow: none;
  border-color: #b6daff;
}
.search-form .ant-input-search .ant-input-search-button {
  font: inherit;
  /* use !important to prevent issues with browser extensions that change fonts */
  /* Better Font Rendering =========== */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0 0.5625rem;
}
.search-form .ant-input-search .ant-input-search-button,
.search-form .ant-input-search .ant-input-search-button:hover,
.search-form .ant-input-search .ant-input-search-button:focus,
.search-form .ant-input-search .ant-input-search-button:active,
.search-form .ant-input-search .ant-input-search-button.active,
.search-form .ant-input-search .ant-input-search-button[disabled] {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;
}
.search-form .ant-input-search .ant-input-search-button.ant-btn-sm,
.search-form .ant-input-search .ant-input-search-button:hover.ant-btn-sm,
.search-form .ant-input-search .ant-input-search-button:focus.ant-btn-sm,
.search-form .ant-input-search .ant-input-search-button:active.ant-btn-sm,
.search-form .ant-input-search .ant-input-search-button.active.ant-btn-sm,
.search-form .ant-input-search .ant-input-search-button[disabled].ant-btn-sm {
  font-size: 0.75rem;
}
.search-form .ant-input-search .ant-input-search-button:hover:not([disabled]),
.search-form .ant-input-search .ant-input-search-button:focus:not([disabled]),
.search-form .ant-input-search .ant-input-search-button:active:not([disabled]),
.search-form .ant-input-search .ant-input-search-button.active:not([disabled]) {
  color: #b6daff;
}
.search-form .ant-input-search .ant-input-search-button.ant-btn-background-ghost {
  border: 1px solid #fff;
}
.search-form .ant-input-search .ant-input-search-button.ant-btn-background-ghost:hover:not([disabled]),
.search-form .ant-input-search .ant-input-search-button.ant-btn-background-ghost:focus:not([disabled]),
.search-form .ant-input-search .ant-input-search-button.ant-btn-background-ghost:active:not([disabled]),
.search-form .ant-input-search .ant-input-search-button.ant-btn-background-ghost.active:not([disabled]) {
  color: #b6daff;
  border-color: #b6daff;
}
.search-form .ant-input-search .ant-input-search-button:after {
  display: none;
}
.search-form .ant-input-search .ant-input-search-button > span {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.search-form .ant-input-search .ant-input-search-button > span:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* Better Font Rendering =========== */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90d";
  font-size: 1.5rem;
  color: #fff;
}
.search-form .ant-input-search .ant-input-search-button:hover > span:before,
.search-form .ant-input-search .ant-input-search-button:focus > span:before {
  color: #b6daff;
}
.search-form .ant-input-search.is-collapsed {
  width: 3.13rem;
  overflow: hidden;
}
.search-form .ant-input-search.is-collapsed .ant-input {
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
}
.search-form-size-lg {
  height: 3.125rem;
}
.search-form-size-lg .ant-input-search {
  height: 3.125rem;
}
.search-form-size-lg .ant-input-search .ant-input {
  font-size: 1rem;
}
.search-form-size-lg .ant-input-search .ant-input-search-button > span:before {
  font-size: 1.8125rem;
}
.search-form.form-dark .ant-input-search .ant-input-search-button > span:before {
  color: #13213a;
}
.search-form.form-dark .ant-input-search:focus .ant-input:not(.ant-input-disabled),
.search-form.form-dark .ant-input-search:hover .ant-input:not(.ant-input-disabled),
.search-form.form-dark .ant-input-search:active .ant-input:not(.ant-input-disabled) {
  box-shadow: none;
  border-color: #b6daff;
}
.search-results-title-summary {
  font-weight: 400;
}
.search-results-group .ant-list-header {
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #d8d8d8;
}
.search-results-group-header {
  font-size: 1.125rem;
  font-weight: 300;
}
.page--home .logo-company {
  margin: 0 auto 1.25rem;
  height: 3.875rem;
  flex: 0 1 3.875rem;
  min-height: 3.875rem;
}
.page--home .page-title {
  margin-bottom: 1.875rem;
  font-size: 2rem;
  text-align: center;
}
.page--home .site-layout > .ant-layout-content .ant-layout-content {
  max-height: 100vh;
}
.page--home .ant-layout-content {
  background-color: #234982;
  position: relative;
}
.page--home .ant-layout-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/loginbackgroundimage.png) 50% 50% no-repeat;
  background-size: cover;
  opacity: 0.3;
}
.page--home .ant-layout-content .main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  margin-top: 1rem;
  position: relative;
}
.page--home .ant-layout-content .promotions {
  margin: 0 auto;
}
.page--home .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
.page--home .ant-layout-sider .form-container {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.page--home .ant-layout-sider .sider-main {
  flex: 1 0 auto;
  width: 100%;
  max-width: 14.375rem;
  margin: 7.5rem auto 0;
}
.page--home .ant-layout-sider .sider-footer {
  margin-top: auto;
  flex-shrink: 0;
  padding: 1.875rem 1.125rem;
  text-align: center;
}
.login-sider {
  width: 25% !important;
  max-width: 30rem !important;
  min-width: 18rem !important;
  flex: 1 1 25% !important;
}
.error-modal .ant-modal-body {
  text-align: center;
}
