.investigation {

  &-export-list {
    width: 100%;
    height: 100%;
    padding-top: 6/@px;
    padding-bottom: 6/@px;
    background: rgba(0, 0, 0, 0.8);

    .ant-list {

      &-item {
        padding: 7/@px 16/@px;

        .camera-player-single &-content {
          flex: 0;
          &:first-child {
            flex: 0 0 12rem;
          }
        }

        .camera-player-single &-meta {
          flex: 0 0 12rem;
        }

        &-meta-content {
          font-size: @font-size-h4;
        }

        &-meta-title {
          color: white;
        }

        &-meta-title,
        &-meta-description {
          font-weight: @font-weight-base;
          font-size: @font-size-h4;
        }

        &-meta-description {
          font-size: @font-size-h4;
          color: @active-color;
        }

        &-action {
          display: flex;
          width: 113/@px;
          margin-left: 5/@px;

          & > li {
            display: flex;
            flex: 1;
          }

          &,
          & > li,
          .ant-btn-group {
            justify-content: flex-end;
          }

          .ant-btn,
          .ant-progress {
            width: 89/@px;
          }

          .btn-cancel {
            width: 24/@px;
          }

          .ant-progress {
            position: relative;
            border: 1px solid @btn-light-border;
            border-radius: @border-radius-base;
            overflow: hidden;

            &:not(:only-child) {
              border-left: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }

            &-outer {
              padding: 0;
              margin: 0;
            }

            &-inner {
              border-radius: 0;
            }

            &-text {
              position: absolute;
              width: 100%;
              left: 0;
              top: 50%;
              padding: 0 8/@px;
              font-size: @font-size-xs;
              font-weight: @font-weight-medium;
              color: @btn-light-color;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .export-error {
      display: flex;
      align-items: center;
      justify-content: center;
      order: -1;

      .anticon-exclamation-circle {
        margin-right: 6/@px;
        font-size: 18/@px;

        path {
          fill: @highlight-color;
          stroke: @highlight-color;
        }
      }
    }
  }

  &-export__no-content {
    color: white;
    background: rgba(0,0,0,0.7);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15%;
    text-align: center;

    .ant-spin-dot i  {
      background-color: white;
    }
  }

  &-page {
    .page-header {
      margin-top: 0;
      margin-bottom: 0;
      justify-content: space-between;
      flex-wrap: nowrap;

      h1.page-title {
        flex: 0 1 auto;
        word-break: break-all;
      }
      .header-extras {
        flex: 0 0 auto;
        margin-left: 0;
      }
    }

    .ant-layout-has-sider > &.ant-layout-content {
      padding-left: 0;
      padding-right: 0;

      .camera-grid,
      .page-header {
        padding-left: @content-margin-sm;
        padding-right: @content-margin-sm;
      }
      .camera-player-frames {
        padding: (@content-margin-sm / 2) @content-margin-sm;
      }
    }

    @other-areas-height: (@toolbar-height-lg + @page-header-height + @layout-header-height);
    .camera-player-multiple .camera-frame,
    .camera-grid .camera-draggable-grid-item {
      max-width: calc(~"(100vh - " @other-areas-height ~") / ( 2 * 0.75)")
    }

    .camera-player-multiple[data-camera-count="2"] .camera-frame {
      max-width: calc(~"(100vh - " @other-areas-height ~") / ( 0.75)")
    }

    .camera-player-single {
      max-width: 100%;
      width: 100%;
      height: 100%;
      .camera-player-frames {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(~"100% - " @toolbar-height-lg);
        //.camera-frame {
        //  max-width: calc(~"(1/0.715) * (100vh - " ((@other-areas-height * 2) + 1rem) ~")")
        //}
      }
      .camera-frame-empty {
        display: none;
      }
    }
    .camera-grid {
      height: calc(~"100% - " (@toolbar-height-lg + @page-header-height));
      display: flex;
      justify-content: center;
      align-items: center;

      .camera-draggable-grid-items {
        height: 100%;
        width: 100%;
        align-items: center;
        margin: 0;
      }
    }
  }

  &-list.list-panel {
    max-height: 100%;
    .ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box > & {
      &,
      & > .ant-list,
      & > .ant-spin-nested-loading {
        //overflow-y: hidden;
        width: 100%;
      }
    }


    > .ant-list {
      height: 100%;
      & > .ant-spin-nested-loading {
        height: calc(~"100% - 3.75rem");
        > .ant-spin-container {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 100%;
          height: 100%;
          [data-length="1"]& {
            overflow-y: visible;
          }
          > .ant-list-item.ant-list-item--block {
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box {
  width: 100%;

  .ant-list-item-content-single, .ant-list-item-meta {
    flex: 0 1 auto;
    width: auto;
  }
  .ant-list-item-meta-title {
    word-break: break-all;
  }
}

.export-confirm-delete {
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-message {
    padding: 0;
    .anticon {
      display: none;
    }
  }
  .ant-popover-message-title {
    padding: 0.75rem;
  }
  &,
  .ant-popover-message,
  .ant-popover-buttons {
    max-width: 10rem;
    min-width: 10rem;
  }
  .ant-popover-buttons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    button, button.ant-btn, button.ant-btn.ant-btn-sm {
      font-size: 0.9375rem;
      border: none;
      border-top: 1px solid @primary-dark-color;
      border-radius: 0;
      background-color: white;
      width: 100%;
      height: 2.5rem;
      margin: 0;
      padding: 0;
      text-align: center;

      &:hover, &:focus {
        background-color: @primary-light-color;
        box-shadow: none;
      }
      &:first-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

  }


  .ant-layout-has-sider > &-page.ant-layout-content {
    padding: 0;

    .page-header,
    .camera-player-frames,
    .camera-player-toolbar-groups {
      padding: 0 1.25rem;
    }
  }
}

.investigation-list .ant-list-item.ant-list-item--block {
  [data-length="1"]& {
    overflow-y: visible;
  }

  &:last-child:not(:first-child) .dropdown-container {
    &.active .ant-dropdown-trigger {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ant-dropdown {

      &-menu {
        transform: translateY(-100%);
        border-top: 1px solid @primary-dark-color;

        &, &-item:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    & > div {
      top: 2px !important;
    }

  }
}

@keyframes antSlideDownInSpecial {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8) translateY(-100%);
    transform: scaleY(0.8) translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1) translateY(-100%);
    transform: scaleY(1) translateY(-100%);
  }
}
