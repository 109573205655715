.datepicker-modal-datepicker {

  .btn-toggle-live {
    font-size: .8125rem;
    text-transform: uppercase;
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    height: 2rem;
    line-height: 2rem;

    .icon.icon-live {
      font-size: 1.9375rem;
      margin-right: 0.125rem;
    }
  }

  .timestamp-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 14.625em;
    padding-bottom: 1.5em;

    &-prev,
    &-next {
      height: 4.0625em;
      width: 8.8125em;
      flex: 0 0 9em;
    }

    &-prev .btn-text {
      text-align: right;
    }
    &-next .btn-text {
      text-align: left;
    }

    &-thumbnail{
      flex: 0 0 auto;
      img {
        max-width: 16em;
      }
    }
  }

  .ant-modal-footer,
  .datepicker-modal-datepicker-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .datepicker-modal-datepicker-input {
    .ant-calendar-picker,
    .ant-time-picker {
      margin: 0 0.75em;
      &,
      &-input {
        width: 10em;
      }
    }
  }

  .ant-modal-footer .ant-btn-primary {
    padding: 0 3em;
  }

}