.camera-player-datepicker {

  .ant-modal-header {
    padding-left: 120/@px;
    padding-right: 120/@px;
  }

  .btn-toggle-live {
    position: absolute;
    top: 0;
    left: 10/@px;
    padding: 5/@px;
    font-size: @font-size-xs;
    font-weight: @font-weight-medium;
    text-transform: uppercase;

    .icon {
      margin-right: 4/@px;
      font-size: 30/@px;
    }
  }

  .timestamp-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 260/@px;
    margin-bottom: @content-margin-sm;

    &-next,
    &-prev {
      height: auto;
      min-width: 145/@px;
      padding-top: 10/@px;
      padding-bottom: 10/@px;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24/@px;
      }

    }

    &-prev {
      padding-left: 26/@px;
      text-align: right;

      .icon {
        left: 0;
      }
    }

    &-next {
      padding-right: 26/@px;

      .icon {
        right: 0;
      }
    }

    &-thumbnail {
      flex: 1;
      background: #000;
    }
  }

  &-input,
  .ant-modal-footer {
    text-align: center;
  }
}
