.search-results {

  &-title-summary {
    font-weight: @font-weight-medium;
  }

  &-group {

    .ant-list-header {
      margin-bottom: 12/@px;
      border-bottom: 1px solid #d8d8d8;
    }

    &-header {
      font-size: @font-size-h3;
      font-weight: @font-weight-base;
    }
  }
}
