.search-form {
  .ant-form-item {
    margin: 0;

    &,
    &-control,
    &-children {
      display: block;
      height: 100%;
    }
  }

  .ant-form-item-children {
    display: block;
    width: 100%;
  }

  .ant-input-search {
    position: relative;
    width: @search-input-width;
    height: @search-input-height;
    transition: width 0.2s;

    &:not(.collapsible) {
      display: block;
    }

    .ant-input-suffix {
      width: @search-btn-width;
      height: 100%;
    }

    .ant-input,
    .ant-input-search-button {
      display: block;
      height: 100%;
    }

    .ant-input {
      width: @search-input-width;
      font-size: 14/@px;
      transition: opacity 0.1s;
      opacity: 1;
    }

    &:focus,
    &:hover,
    &:active {

      .ant-input {
        display: block;
        height: 100%;

        &:not(.ant-input-disabled) {
          box-shadow: none;
          border-color: @active-color;
        }
      }
    }

    .ant-input-search-button {
      .ant-btn-link();
      .icon();
      width: 100%;
      padding: 0 9/@px;

      &:after {
        display: none;
      }

      & > span {
        .hide-text();

        &:before {
          .icon();
          content: @icon-search;
          font-size: 24/@px;
          color: #fff;
        }
      }

      &:hover,
      &:focus {

        & > span:before {
          color: @active-color;
        }
      }
    }

    &.is-collapsed {
      width: @search-btn-width;
      overflow: hidden;

      .ant-input {
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &-size-lg {
    height: @search-input-height-lg;

    .ant-input-search {
      height: @search-input-height-lg;

      .ant-input {
        font-size: 16/@px;
      }

      .ant-input-search-button > span:before {
        font-size: 29/@px;
      }
    }

  }

  &.form-dark {
    .ant-input-search {

      .ant-input-search-button > span:before {
        color: @text-color-dark;
      }

      &:focus,
      &:hover,
      &:active {

        .ant-input:not(.ant-input-disabled) {
          box-shadow: none;
          border-color: @active-color;
        }
      }
    }
  }

}
