.camera-player {
  color: #fff;

  &-content {
    flex: auto;
    display: flex;
    flex-direction: column;
  }

  &-body {
    position: relative;
  }

  // Toolbar
  &-toolbar {
    height: @toolbar-height;
    background: @primary-dark-color;
    text-align: center;
    min-width: min-content;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    width: stretch;

    a {
      text-decoration: none;
    }

    // Toolbar groups
    &-groups,
    &-group,
    .toolbar-buttons {
      display: flex;
      align-items: center;
    }

    &-groups {
      height: 100%;
    }

    &-group {
      padding: 0 5/@px;
      margin-left: auto;

      &:first-child {
        margin-left: 0;
      }

      // Main group
      &-main {
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }

    .toolbar-buttons {
      justify-content: center;
    }

    // Buttons

    .ant-btn {
      display: block;
      margin: 0 5/@px;
      font-size: 10/@px;
      text-align: center;
      text-transform: uppercase;

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }

      &:hover,
      &:focus {
        color: @active-color;
      }

      &:active {
        color: #fff;
      }

      .icon {
        margin-right: 0;
        font-size: 30/@px;

        &,
        &:before {
          display: block;
        }
      }
    }

    .ant-btn-link-alt {
      &:hover,
      &:focus {
        color: #fff;
      }

      &:active {
        color: @active-color;
      }
    }

    .ant-btn-dark {
      width: auto;
      min-width: 180/@px;
      height: 36/@px;
      font-size: @font-size-sm;
      font-weight: @font-weight-base;
      text-align: center;
      text-transform: none;
    }

    .player-action-btn {
      padding: 0;
      width: @toolbar-button-size;
      height: @toolbar-button-size;
    }

    .btn-speed {
      width: 32/@px;
      height: 32/@px;
      font-size: @font-size-xs;
      font-weight: @font-weight-medium;

      .speed-value:after {
        content: "x";
        font-size: 7/@px;
      }
    }

    .camera-player-duration {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .label {
      margin: 0 0 5/@px;
      font-size: 14/@px;
      text-transform: uppercase;
    }
  }

  &[data-fullscreen="true"] > .camera-player-content {
    position: absolute;
    opacity: 0;
  }

  // Camera Movement Controls (PTZ)
  &-ptz {
    position: absolute;
    top: @camera-header-height;
    bottom: 0.25rem;
    left: 0;
    width: 100%;
    z-index: 14;

    .btn-ptz-up,
    .btn-ptz-down,
    .btn-ptz-left,
    .btn-ptz-right {
      position: absolute;
      min-width: 50/@px;
      min-height: 50/@px;
      width: 20%;
      height: 20%;
      overflow: hidden;
      font-size: 46/@px;

      &:hover .icon:before {
        font-size: 1.125em;
      }

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3D(-50%, -50%, 0);
        .text-outline((1/@px), rgba(19, 33, 58, 0.8), 0);
        &:before {
          padding: 2/@px;
          transition: 150ms cubic-bezier(0.25, 0, 0, 1) font-size;
        }
        .camera-frame-fullscreen & {
          font-size: 4rem;
        }
      }
    }

    .btn-ptz-up,
    .btn-ptz-down {
      left: 50%;
      transform: translateX(-50%);
      padding: 7% 16%;
    }

    .btn-ptz-up {
      top: 10/@px;
      .icon {
        top: 30%;
      }
    }

    .btn-ptz-down {
      bottom: 10/@px;
      .icon {
        top: 70%;
      }
    }

    .btn-ptz-left,
    .btn-ptz-right {
      top: 50%;
      transform: translateY(-50%);
      padding: 16% 9%;
    }

    .btn-ptz-left {
      left: 5/@px;
      .icon {
        left: 30%;
      }
    }

    .btn-ptz-right {
      right: 5/@px;
      .icon {
        left: 70%;
      }
    }

    &-zoom,
    &-preset {
      position: absolute;
      right: 5%;
      bottom: 7%;
      width: 27/@px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .btn-ptz-preset {
      margin-right: calc(~"1.75em + 2px");
    }

    .btn-ptz-zoom-in,
    .btn-ptz-zoom-out,
    .btn-ptz-preset {
      width: 1em;
      height: 1em;
      padding: 0;
      font-size: 30/@px;
      .camera-frame-fullscreen & {
        font-size: 45/@px;
      }
      margin-top: 0.75em;
      .icon {
        width: 100%;
        height: 100%;
        .text-outline((1/@px), rgba(19, 33, 58, 0.4), 1px);

      }

      .anticon {
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
      }
    }
  }


  // Camera Thumbnails
  &-single {
/*  width: calc(~"100% - 2.25rem");
    height: calc(~"100% - 2.25rem");*/
    //max-width: 45rem;
    padding-right: 1.25rem;
    margin-top: 3px;
    .camera-frame {
      width: 100%;
    }
    .camera-player-content {
      align-items: center;
    }
    .camera-player-frames {
      width: 100%;
    }
/*
    .camera-player-content,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .camera {
      height: 100%;
    }
    .camera-player-frames {
      height: calc(~"100% - " @toolbar-height);
    }*/
  }

  &-multiple, .investigation-page &-single {
    display: flex;
    flex: auto;

    .camera-player-content {
      justify-content: space-between;
      align-items: center;
    }

    .camera-player-frames {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: calc(~"100% - " @toolbar-height-lg);
      align-content: center;
    }

    .camera-player-toolbar {
      height: @toolbar-height-lg;
      width: 100%;
    }
  }

  &-multiple {
    .camera-frame {
      width: 50%;
      padding: 0 5/@px 10/@px;
      margin-bottom: 5/@px;
      &-fullscreen.camera-frame {
        margin: 0;
        padding: 0;
        .camera-thumbnail,
        .camera-image,
        .camera-canvas {
          margin-bottom: 0;
        }
      }
      .camera-thumbnail,
      .camera-image,
      .camera-canvas {
        margin-bottom: -5/@px;
      }
    }
  }

  &[data-exports] + &,
  .camera-grid + & {
    display: none;
  }

  // Fullscreen

  .btn-fullscreen,
  .btn-exit-fullscreen {
    position: absolute;
    top: 30/@px;
    right: 10/@px;
    padding: 8/@px;
    z-index: 15;

    .icon {
      display: block;
    }
  }

  .icon-fullscreen {
    font-size: 28/@px;
  }

  .icon-exit {
    font-size: 48/@px;
  }

  &-fullscreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);

    div& .camera-frame.camera-frame-fullscreen {
      flex: 1 1 100%;
      z-index: 1;
      width: 100%;
      max-width: calc(~"(100vh - " ( @toolbar-height ) ~") / var(--camera-aspect-ratio) " );
      max-height: calc(~"100vh - " ( @toolbar-height ) ~"" );
      .investigation-page & {
        max-width: calc(~"(100vh - " ( @toolbar-height-lg ) ~" - 2.5rem) / var(--camera-aspect-ratio) " );
      }
    }
    .camera-player-toolbar {
      flex: 0 0 auto;
    }

    .camera-player-single & .camera-frame {
      width: 85vw;
      max-width: 113vh;
    }
  }

  &-page {
    & > .ant-layout-has-sider {
      position: relative;
      > .ant-layout-sider {
        overflow-y: auto;
      }
    }
  }

  &-page &-single {
    height: 100%;
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .camera-player-content {
      width: 100%;
    }
  }

  &-sequence {
    position: absolute;
    top: -1px;
    bottom: -1px;
    min-width: 0.125rem;
    background-color: @highlight-color;
    &::selection {
      background-color: transparent;
    }

    &:hover {
      background-color: @outline-color;
      &:after {
        content: attr(data-text);
        display: block;
        position: absolute;
        font-size: 0.875rem;
        top: calc(~"-200% - 8px");
        left: 50%;
        width: max-content;
        transform: translateX(-50%);
        background-color: white;
        color:black;
        padding: 3px;
      }
    }

    &-active {
      background-color: @btn-primary-bg;
    }

    &,
    &s-cursor {
      transition: background-color 200ms cubic-bezier(.25, 0, 0, 1);
    }

    .camera-player-fullscreen & {
      &s {
        &-bar {
          height: 2.5rem;
          width: 100%;
        }

        &-cursor {
          width: .25rem;
          &:hover {
            width: 5/@px;
          }
        }
      }
    }

    &s {
      position: relative;
      margin: 0;
      height: 100%;
      width: 100%;

      &-cursor {
        position: absolute;
        background-color: @text-color;
        width: .1875rem;
        transform: translateX(-50%);
        top: -1px;
        bottom: -1px;
        transition-property: background-color, top, bottom, width;
        box-shadow: 0 0 2px rgba(0,0,0,0.4);
        pointer-events: none;
        &::selection {
          background-color: transparent;
        }

        &:hover {
          top: -3px;
          bottom: -3px;
          width: 0.25rem;
        }

        &-ghost {
          background-color: rgba(255,255,255,0.6);
          &:after {
            content: attr(data-text);
            display: none;
            position: absolute;
            top: calc(~"-100% - 8px");
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            color:black;
            padding: 3px;
          }
        }
      }

      &:hover &-cursor-ghost:after {
        display: block;
      }

      &-bar {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1.25rem;
        background-color: rgba(0,0,0,0.4);
        border: 1px dashed @highlight-color;
        border-left: none;
        border-right: none;

        .camera-player-fullscreen & {
          z-index: 10;
          bottom: @toolbar-height;
          position: static;
        }
        .camera-player-multiple .camera-player-fullscreen & {
          bottom: @toolbar-height-lg;
        }

        .camera-is-playing + & {
          transition: 200ms cubic-bezier(.25, 0, .375, 1);
          transition-delay: 1.5s;
          transition-property: height, border-width, border-image-width, padding;
          //animation: camera-sequence-bar-hide 200ms cubic-bezier(.25, 0, .375, 1);
          //animation-delay: 1s;
          //animation-fill-mode: forwards;
          height: 2px;
          border-width: 0;
          border-image-width: 0;
          padding: 1px 0;
          .camera-player-fullscreen & {
            //animation-name: camera-sequence-bar-hide-fs;
            height: 4px;
            border-width: 0;
            border-image-width: 0;
            padding: 1px 0;
          }

          .camera-frame > div > .ant-spin-container:hover &,
          .camera-player-fullscreen:hover > & {
            //animation-name: camera-sequence-bar-show;
            //animation-delay: 0ms;
            transition-delay: 0ms;
            border-width: 1px;
            border-image-width: 1px;
            height: 1.25rem;
            padding: 0;
            .camera-player-fullscreen & {
              border-width: 1px;
              border-image-width: 1px;
              height: 2.5rem;
              padding: 0;
              //animation-name: camera-sequence-bar-show-fs;
            }
          }
        }
      }
    }
  }

}

// Frame
.camera-frame {
  position: relative;

  .placeholder {
    width: 100%;
    height: 100%;
    min-height: 248/@px;
    opacity: 0.3;
    border: 2px dashed #fff;
  }
  &[data-playing="true"] {
    background-color: @body-background;
  }

  &[data-playing="true"] > div {
    .camera-header,
    .btn-fullscreen {
      transform: translateY(-1.25rem);
      transition: 200ms cubic-bezier(.25, 0, .375, 1);
      transition-delay: 1.5s;
      transition-property: transform, opacity;
    }
    .camera-header {
      top: 0rem;
    }
    .btn-fullscreen {
      top: 1.875rem;
      opacity: 0;
    }


    &:hover {
      .camera-header,
      .btn-fullscreen {
        transition-delay: 0ms;
        transform: translateY(0rem);
      }
      .btn-fullscreen {
        opacity: 1;
      }
    }
  }

  &[data-blank="true"] .camera-thumbnail-content {
    background-color: black;
    img, canvas, video, videos-stream {
      opacity: 0;
    }
  }

  .camera-header, .camera-player-sequences-bar {
    z-index: 12;
  }

  .camera-thumbnail-content {
    [data-playing="true"]& > .camera-image {
      z-index: 10;
    }
    & > canvas,
    & > videos-stream,
    & > video {
      z-index: 11
    }
  }

  &__reconnect {
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    &__button {
      color: #fff;
      background-color: transparent;
      border-color: transparent;
      margin-top: 2rem;
      cursor: pointer;
      &:disabled {
        pointer-events: none;
      }
      &, & svg {
        width: 3rem;
        height: 3rem;
        padding: 0;
      }
    }
    .ant-spin {
      margin-top: 2rem;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.camera-frame[data-is-blank="false"] videos-stream,
.camera-feed-thumbnail videos-stream {
  background-color: black;
}

.camera-feed-thumbnail {
  position: relative;

  canvas, video, videos-stream {
    display: none;
  }

  .camera-is-playing & .camera-image {
    canvas, video, videos-stream {
      display: block;
      z-index: 0
    }
    img {
      z-index: -1
    }
  }
}

@other-heights: @layout-header-height + @page-header-height;
@default-ratio: var(--camera-aspect-ratio);
.frame-aspect-ratio(@other-height, @ratio) {
  max-width: calc(~"(100vh - " ( @other-heights + @other-height + @content-margin-sm) ~") / " ( @ratio ) );
}
.camera-player {
  &-page &-single &-content {
    .frame-aspect-ratio(@toolbar-height + 3rem, @default-ratio);
  }
  .investigation-page &-single > &-content .camera-frame {
    .frame-aspect-ratio(@toolbar-height-lg, @default-ratio);
  }
  &-multiple &-frames {
    .frame-aspect-ratio((@toolbar-height-lg - (2 * @content-margin-sm) - 3/@px), 0.75);
  }
  &-multiple[data-camera-count="2"] &-frames {
    max-width: 100%;
  }
}
.investigation-page .camera-draggable-grid-items {
  .frame-aspect-ratio((@toolbar-height-lg - @content-margin-sm + 5/@px), 0.75);
}

@keyframes camera-title-show {
  0% {
    top: -1.25rem;
  }
  100% {
    top: 0;
  }
}

@keyframes camera-title-hide {
  0% {
    top: 0;
  }
  100% {
    top: -1.25rem;
  }
}

@keyframes camera-fs-btn-show {
  0% {
    top: .625rem;
    opacity: 0;
  }
  100% {
    top: 1.875rem;
    opacity: 1;
  }
}

@keyframes camera-fs-btn-hide {
  0% {
    top: 1.875rem;
    opacity: 1;
  }
  100% {
    top: .625rem;
    opacity: 0;
  }
}
