.ant-alert {
  padding-left: 32/@px;
  padding-right: @input-padding;
  font-size: @font-size-xs;
  color: @text-color-dark;

  &-icon {
    left: @input-padding;
    font-size: 18/@px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
}

.ant-message {
  top: 86/@px;

  .ant-message-notice {
    color: @text-color-dark;
  }
}
