.camera-card {
  padding: 3px;
  color: @muted-color;

  .ant-list-item & {

    &,
    .camera-card-link,
    .ant-card {
      width: 100%;
      height: 100%;
    }
  }

  .ant-card {
    transition: background-color 0.2s;
  }

  .ant-card-body {
    position: relative;
  }

  .ant-list-loading {
    width: 100%;
    height: 0;
    padding-bottom: 22.67%;

    .ant-spin-nested-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-link {
    display: block;
    overflow: hidden;
    color: inherit;
    border-radius: @border-radius-base;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;

    &,
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      color: inherit;
    }

    &:focus {
      box-shadow: 0 0 1px 3px @outline-color;
    }

    &:hover,
    &:active {
      box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.4);
    }

    &:active {

      .ant-card {
        background: @muted-light-color;
      }

    }

  }

  .camera-card-thumbnails {

    &-item {
      margin-bottom: 0;
    }
  }

}
