.login-form {

  .ant-input {
    background: @primary-dark-color;
  }

  &__error-wrapper {
    padding: 0.5rem;
    margin: 0 -0.5rem;
    border-radius: @border-radius-base;

    .ant-row:nth-child(2) {
      margin-bottom: .5rem;
    }
  }

  &.has-error {
    .login-form__error-wrapper {
      background: white;
    }
  }

  .ant-form-explain,
  .form-error {
    color: @error-color;
    font-size: 12/@px;
    font-weight: 400;
  }

  .ant-form-explain {
    margin-top: 0.25rem;
  }

  .form-error {

    &:before {
      content: ' ';
      background: url('../../images/icon_error.svg');
      height: 20px;
      width: 20px;
      display: inline-block;
      vertical-align: middle;
      margin: -0.25rem 0.25rem;
    }
  }

  .form-actions {
    margin-top: 1.25rem;
  }

}