#app {
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
  --camera-aspect-ratio: 0.715;
  background: url("../../images/background-lg.png") 50% 50% no-repeat;
  background-size: cover;
}

@media screen and (min-width: 2560px) {
  body {
    background-image: url("../../images/background-xl.png");
  }
}

.container {
  .container();
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  height: @page-header-height;
  margin-top: @content-margin-sm;
  margin-bottom: @content-margin-sm;
}

.site-layout {
  height: 100vh;
  min-height: 680/@px;
  overflow: hidden;
  display: flex;

  & > .ant-layout,
  & > .ant-layout-content {
    display: flex;
  }

  .ant-layout-header + .ant-layout-content {
    max-height: calc(~"100vh - " @layout-header-height);

    & > .ant-layout-content {
      width: 100%;
      margin: 0;
      > * {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    & > .ant-layout-content:not(.camera-view-page) {
      &,
      & > *,
      & > * > * {
        max-height: inherit;
      }
    }
  }

  & > .ant-layout-content {
    display: flex;
    flex-direction: column;

    .ant-layout-content {
      overflow-x: hidden;
      overflow-y: auto;

      & > .ant-spin {
        display: block;
        margin: 50/@px auto;
      }
    }

    .ant-layout-content {
      display: flex;
      flex-direction: column;
      flex: auto;

      & > .ant-layout {
        display: flex;
        flex: auto;
      }
    }
  }
}

.ant-layout-content {

  .ant-layout-has-sider > & {
    padding-left: @content-margin-sm;
    padding-right: @content-margin-sm;

    .container & {
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.ant-layout-sider-light {
  background: transparent;
}

.ant-row {
  display: flex;
  flex-wrap: wrap;

  &:before,
  &:after {
    display: none;
  }
}

.ant-spin-container {
  &:before,
  &:after {
    display: none;
  }
}

.full-height {
  display: flex;
  flex-direction: column;
}
