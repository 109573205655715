.camera-grid {

  .ant-row {
    display: flex;
    flex-wrap: wrap;
  }

  [class*="ant-col-"],
  .ant-list-item,
  .ant-list-item-content {
    display: flex;
  }

  .ant-list-item,
  .ant-list-item-content,
  .camera,
  .camera .btn-toggle-mode {
    width: 100%;
    height: 100%;
  }

  .ant-list-item {
    margin-bottom: 0;
  }

  [class*="ant-col-"] {
    margin-bottom: 10/@px;
  }

  &-editable {
    width: 588/@px;

    .camera {
      height: 217/@px;
    }
  }
}

// Grid column options
.column-options {
  display: flex;

  .ant-btn {
    display: block;
    padding: 0;
    margin-left: 9/@px;
    height: 28/@px;
  }

  .icon {
    font-size: 74/@px;
    line-height: 28/@px;

    &,
    &:before {
      display: block;
    }
  }
}

.site-layout .ant-layout-header + .ant-layout-content > .ant-layout-content {
  position: relative;
  & > header.page-header {
    background-color: @body-background;
    top: 0;
    position: sticky;
    margin: 0 -1.25rem;
    padding: 1.25rem;
    width: calc(100% + 2.5rem);
    z-index: 15;
    & + .ant-layout:not(.ant-layout-has-sider) {
      margin-bottom: 2rem;
      //height: calc(~"100% - " @page-header-height);
      //max-height: calc(~"100% - " @page-header-height);
      z-index: 8;
    }
    & + .ant-layout-has-sider > .ant-layout-sider {
      margin-bottom: 2rem;
      height: calc(~"100vh - 2rem - " (@layout-header-height + @page-header-height));
      max-height: calc(~"100vh - 2rem - " (@layout-header-height + @page-header-height));
      z-index: 8;
    }
  }
}

// Draggable Grid

.camera-draggable-grid {
  &[data-enabled="true"] &-item {
    .camera {
      padding: 3px;
      transform: scale(0.95);
    }
    &:hover .camera {
      transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1)
    }

    .camera-thumbnail-content {
      z-index: 2;
    }
    .camera-content {
      z-index: 3;
    }

    &.dragging,
    &:active {
      .camera {
        transform: scale(1.05);
      }
    }
  }
  &[data-enabled="true"]:not(:hover) &-item .camera {
    transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1)
  }
  &[data-enabled="false"] &-item {
    cursor: default;
    .camera {
      //animation-fill-mode: none;
      transform: scale(1);
      transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
    }
  }


  &-items {
    display: flex;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    padding: 0 2/@px;
    margin-bottom: 4/@px;
    width: 50%;
    cursor: grab;

    a {
      display: flex;
      width: 100%;
    }

    &.dragging,
    &:active {
      opacity: 0.7;
      z-index: 9;
      cursor: grabbing;
      & .camera {
        cursor: grabbing;
      }
    }

    .camera {
      width: 100%;
      height: 100%;
      //animation-duration: 125ms;
      //animation-timing-function: cubic-bezier(0, 0.25, 0.5, 1);

      &:hover {
        transition: transform 125ms cubic-bezier(0, 0.25, 0.5, 1);
      }
      & > .camera-thumbnail {
        color: #fff;
      }
    }

    .camera-draggable-grid--columns-4 & {
      width: 25%;
    }

    .camera-draggable-grid--columns-6 & {
      width: 16.66%;
    }
  }
}

body > .camera-draggable-grid-item.dragging {
  cursor: grabbing;
  .camera {
    transform: scale(1.05);
  }
}

@keyframes StartRearrange {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

@keyframes EndRearrange {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
