.ant-breadcrumb {
  line-height: 1.2;

  .page-header--title-visible & {
    width: 100%;
    margin-bottom: @content-margin-sm;
  }

  a {
    text-decoration: underline;
  }

  &,
  & > * {
    display: flex;
    align-items: center;
  }

  &-separator {
    font-size: @breadcrumb-icon-font-size;

    .icon {
      &,
      &:before {
        display: block;
      }
    }
  }
}
