.camera {
  width: 100%;
  color: @text-color-dark;
  position: relative;

  &__player-page {
    display: flex;
    flex-direction: column;

    .ant-layout-sider {
      overflow: scroll;
    }
  }

  &-thumbnail {
    background-color: rgba(19,33,58, 0.25);
    &-content {
      position: relative;
      .ant-btn.show-on-hover {
        transition: all 0.2s linear;
      }
    }
    &-error {
      text-align: center;
      width: 100%;
      padding-top: @camera-header-height;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 15;
    }
  }

  &-theme-dark {
    color: @text-color-dark;
  }

  &-thumbnail,
  &-image,
  &-canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &-canvas {
    position: relative;
  }

  &-image {
    position: relative;
    height: 0;
    padding-bottom: calc(~"var(--camera-aspect-ratio) * 100%");

    img {
      //object-fit: contain;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-has-video {

    .camera-canvas {
/*      padding-bottom: 0;
      height: unset;
      max-height: 100%;
      max-width: 100%;*/
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }

  .ant-spin-dot i {
    background-color: @muted-color;
  }

  &-content,
  &-content > .ant-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .ant-layout-sider & {
        &.btn-camera-added,
        &.btn-remove-camera {
        background-color: rgba(0,0,0,0.5)
      }
    }
  }

  &-content {
    padding-top: @camera-header-height;

    & > .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed rgba(250, 250, 250, 0.3);
      z-index: 16;

      &,
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }

      .btn-text {
        position: relative;
        text-shadow: 0 0 2px rgba(0,0,0,0.375);
      }

      .btn-text-added,
      .btn-text-remove {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 1.25rem);
        opacity: 0;
      }
      .btn-text-remove {
        opacity: 0;
        .icon.icon-close {
          padding-right: 1px;
          padding-top: 1px;
        }
      }
      .btn-text-added {
        opacity: 1;
      }

      &:hover {
        .btn-text-remove {
          opacity: 1;
        }
        .btn-text-added {
          opacity: 0;
        }
      }

      .icon,
      .anticon {
        display: block;
        font-size: 52/@px;
        margin: 0 auto 10/@px;

        svg {
          margin: 0 auto;
        }
      }

      &.ant-btn-sm {
        font-size: @font-size-xs;

        .icon,
        .anticon {
          font-size: 28/@px;
          margin-bottom: 5/@px;
        }
      }
    }
  }

  &-image + &-content {

    & > .ant-btn {
      border: 0;
      background: rgba(0, 0, 0, 0.8);
    }

  }

  &-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: @camera-header-height;
    padding: 0.2rem 0.3125rem;
    z-index: 1;
    font-size: @font-size-xs;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  &-name {
    margin: 0 0.5rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: inherit;
    font-weight: @font-weight-base;
  }

  &:hover &-thumbnail-content & {
    &-header {
      height: auto;
    }
    &-name {
      overflow: visible;
      white-space: normal;
    }
  }

  &-link {
    display: block;
    position: relative;
    padding: 3px;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: inherit;
    }

    &:focus {
      background: transparent;

      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        box-shadow: 0 0 1px 3px @outline-color;
      }
    }

    &:hover,
    &:active,
    &.active {
      background: @active-color;

      &:before {
        display: none;
      }
    }
  }

  // Dark Theme
  &-theme-dark {
    color: #fff;

    .ant-spin-dot i {
      background-color: #fff;
    }

    .camera-image {
      background: #000;
    }
  }

  &-list {

    .ant-list-item {
      padding: 0;
      border: 0;

      & + .ant-list-item {
        margin-top: 8/@px;
      }
    }

  }

  &-view-page {
    > .page-header {
      flex-wrap: nowrap;
    }
    &__header__sorting {
      .ant-btn {
        margin-left: 1rem;
      }
    }
    > .ant-layout > .ant-layout-content {
      overflow-y: hidden;
      flex: 1 0;
    }
  }
}
