.header {
  display: flex;
  align-items: center;

  .toolbar,
  &-extras {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 10/@px;

    & > * {
      margin-right: 10/@px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ant-btn,
    .dropdown-container,
    .confirm-modal,
    .ant-dropdown {
      font-size: @font-size-sm;

      &-sm {
        font-size: @font-size-xs;
      }
    }

    & > .ant-btn,
    .ant-dropdown-trigger,
    .btn-trigger-modal {
      height: 36/@px;
    }

    & > .ant-btn,
    .btn-trigger-modal {
      min-width: 123/@px;
    }

    .dropdown-container {
      width: 108/@px;

      .ant-dropdown-menu-item {

        a,
        .ant-btn {
          padding-top: 7/@px;
          padding-bottom: 7/@px;
        }
      }
    }

  }
}
