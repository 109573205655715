.ant-layout-sider {

  &-children,
  &-children .ant-collapse {
    max-height: inherit;
    .ant-list-header {
      max-width: calc(~"100% - 1px");
    }
  }

  // Panels
  .ant-collapse {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
  }

  .ant-collapse-item {
    width: 100%;
    background: #fff;
    border-top: 1px solid @primary-color;
    border-bottom: none;
    min-height: @collapse-header-height;
    flex: 0 1 3.25rem;

    &:first-child {
      border-top: none;
    }
  }

  .ant-collapse-header {
    height: @collapse-header-height;
    background: @collapse-header-bg;
  }

  .ant-collapse-item-active {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: auto;

    .ant-collapse-header {
      flex-shrink: 0;
    }

    .ant-collapse-content,
    .ant-collapse-content-box {
      display: flex;
      flex: 1 1 auto;

      & > .list-panel {
        overflow-y: auto;
      }
    }
  }

  .ant-collapse-item--invisible-header {
    background: transparent;
    &:not(.ant-collapse-item-active) {
      min-height: 0;
      height: 0;
      flex: 0 0 1px;
      border: none;
    }

    &.ant-collapse-item-active {
      .ant-collapse-content,
      .ant-collapse-content-box {
        display: block;
      }
    }
  }

  .ant-collapse-content {
    max-height: calc(~"100% - " @collapse-header-height);
    color: @text-color-dark;
  }

  // Help text
  .help-text {
    transition: opacity 0.2s;
  }

  .help-text--hidden {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .help-text--visible {
    opacity: 1;
    visibility: visible;
    margin: @content-margin-base 0;
    padding: 0 @content-margin-base 0 3.3125rem;
  }

}
