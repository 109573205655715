.logo {
  .img-replace(81, 50, '../../images/logo-dw.svg');
  display: block;

  &-size-lg {
    width: 190/@px;
    height: 115/@px;
  }

  .ant-layout-sider & {
    margin: 0 auto 15/@px;
  }

  &-app-name {
    .img-replace(177, 38, '../../images/app-name.svg');
  }

  &-company {
    .img-replace(253, 62, '../../images/logo-company.svg');
  }
}

.branding {

  .title {
    margin: 0 auto;
  }

}
