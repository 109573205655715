.ant-layout-header {
  display: flex;
  align-items: center;
  padding-left: 19/@px;
  padding-right: 19/@px;

  .site-title {
    margin: 0 19/@px 0 0;
  }

  .ant-menu {
    height: 100%;

    &.menu--primary,
    &.menu--secondary {

      & > .ant-menu-item {
        border: 0;
        background: transparent;
      }
    }

    &:before,
    &:after,
    .ant-menu-submenu {
      display: none;
    }

    a {
      text-decoration: none;
    }

    &,
    &-item {
      display: flex;
      background: transparent;
      border: 0;
    }

    &-item {
      padding: 0;

      & > a,
      & > button,
      & > form,
      & > .menu-link,
      & > .search-form-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: @content-margin-base;
        padding-right: @content-margin-base;
        line-height: 1.2;

      }

      & > a,
      & > button,
      & > .menu-link {
        transition: background 0.3s;

        &,
        &:hover,
        &:focus,
        &:active {
          color: #fff;
        }

        &:hover,
        &.active {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
            opacity: 0.5;
          }
        }

        &:hover {
          background: #192f53;
        }

        &.active {
          background: @body-background;
        }
      }

      & > a,
      & > .menu-link {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: auto;
          bottom: 0;
          width: 100%;
          height: 50%;
          background: transparent;
          opacity: 0.5;
          transition: background 0.3s;
        }

        &:hover,
        &.active {

          &:before {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
          }

        }
      }

      .form-item,
      .form-actions {
        margin: 0;
      }

    }

    .modal-wrapper {
      padding-left: 0;
      padding-right: 0;

      .btn-trigger-modal {
        height: 100%;
        padding-left: @content-margin-base;
        padding-right: @content-margin-base;
        min-width: 65/@px;
      }
    }

    .username {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 8/@px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: @font-size-xs;
      color: @active-color;
    }

  }

  .menu--primary {
    font-size: 22/@px;
    min-width: 28.75rem;
  }

  .menu--secondary {
    margin-left: auto;
    min-width: 30rem;
    transition: min-width 200ms ease;

    &.search-open {
      min-width: 46.125rem
    }
  }
}

.help-modal {
  min-width: 50rem;
  max-width: calc(~"1920px - 4rem");
  .ant-modal-body {
    padding: 0;
    padding-top: 2.5rem;
  }
  .ant-modal-footer {
    display: none;
  }
  iframe {
    display: block;
    border: none;
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
    width: 100%;
    height: calc(~"100vh - 8rem");
  }
}

.settings-popover {
  h2:not(:first-child) {
    margin-top: 1.5rem;
  }
}
