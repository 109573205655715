.views-list .list-panel {
  width: 100%;

  & .ant-list-item.ant-list-item--block.ant-list-item--active {
    background-color: @primary-light-color;
  }

  & .ant-list .ant-list-item-content.ant-list-item-content-single {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .ant-list-item-actions {
      margin-right: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.views-page {
  .ant-layout-content {
    .page-header--title-hidden {
      display: none;
    }
    .empty-state {
      flex: 1 0 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a.ant-btn {
        max-width: 20rem;
      }
    }
  }
}

.camera-thumbnail {
  .btn-remove-camera.btn-remove-camera--view {
    border: none;
    top: 1.25%;
    right: 1.25%;
    left: unset;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    > .btn-text {
      font-size: 0.75rem;
      & > .icon {
        font-size: 2rem;
        padding: 0.5rem;
        margin-bottom: 0;
        & + span {
          display: none;
        }
      }
    }
  }
  .btn-remove-camera.btn-remove-camera--view:hover,
  .btn-remove-camera.btn-remove-camera--view:focus {
    .icon + span {
      display: block
    }
  }
}
