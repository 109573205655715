.ant-calendar-picker {

  &,
  &:hover {

    .ant-calendar-picker-input {
      .ant-btn();
    }
  }
  &-icon {
    display: none;
  }
}

.ant-time-picker {
  &,
  &:hover {
    .ant-time-picker-input {
      .ant-btn();
    }
  }
  &-icon {
    display: none;
  }
}

.ant-time,
.ant-calendar {
  .form-dark();

  &-input {
    color: @text-color-dark;
  }

  &-date {
    padding: 3/@px;
    width: 25/@px;
    height: 25/@px;
    line-height: 1;
    color: inherit;
  }

  & &-ok-btn {
    line-height: 0;
    padding: .875em;
  }

  &-today-btn,
  &-time-picker-btn {
    color: @text-color-dark;
  }

  a {
    &,
    &:hover {
      color: @text-color-dark;
    }
  }
}
.ant-time {
  &-picker-panel-select li,
  &-picker-panel-input {
    color: @text-color-dark;
  }
}
