.ant-btn.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  padding-right: 13/@px;

  &.ant-btn-link {
    font-weight: @font-weight-medium;
  }

  .btn-text--open {
    display: none;
  }

  .dropdown-container.active &.ant-btn-dark {
    background: @primary-dark-color;
    color: #fff;
    box-shadow: none;

    .btn-text--open {
      display: block;
    }

    .btn-text--closed {
      display: none;
    }
  }

  .dropdown-container.active & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .icon-down-arrow:before {
      transform: rotate(-90deg);
    }
  }

  .icon-down-arrow {
    flex-shrink: 0;
    font-size: 1.5rem;
  }

  // Sizes
  &.ant-btn-sm {
    padding-right: 0;
    padding-left: 0.375rem;
  }
}

.ant-dropdown {

  &-menu {
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid @primary-dark-color;

    .dropdown-container & {
      top: -1px;
    }

    &-light {
      color: @primary-dark-color;

      .ant-dropdown-menu-item + .ant-dropdown-menu-item {
        border-top: 1px solid @primary-dark-color;
      }
    }

    &-item {
      padding: 0;
      font-size: inherit;
      color: inherit;

      .ant-btn-link:hover:not([disabled]), .ant-btn-link:focus:not([disabled]), .ant-btn-link:active:not([disabled]), .ant-btn-link.active:not([disabled]) {
        color: @primary-dark-color;
      }

      a,
      .ant-btn {
        display: block;
        min-width: 100%;
        height: auto;
        text-align: left;
      }
    }

  }

  &-sm {
    font-size: @font-size-xs;

    .ant-dropdown-menu-item {

      a,
      .ant-btn {
        padding: 0.125rem 0.375rem;
      }
    }
  }

}

.dropdown-container {
  position: relative;

  & > div {
    top: 100% !important;

    .ant-dropdown {
      top: 0 !important;
    }
  }

  .ant-dropdown {
    min-width: 100%;

    &-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .ant-dropdown-trigger {
    width: 100%;
  }
}
