.ant-list {
  color: inherit;
  
  [class*="ant-col-"] {

    &,
    .ant-list-item {
      display: flex;
    }

    .ant-list-item {
      width: 100%;
    }

    .ant-list-item-content {
      width: 100%;
      height: 100%;
    }
  }
  
  &-cards {
    .ant-list-item-content {
      display: flex;
    }
  }

  &-item {

    &-meta-title,
    &-meta-description {
      color: inherit;
    }

    &-meta-title {
      line-height: @heading-line-height;
    }

    &-meta-description {
      font-size: 0.6875rem;
      font-weight: @font-weight-medium;
    }

    &-link,
    &-button {
      display: block;
      text-align: left;
      color: inherit;
    }

    &-button {
      height: auto;
    }

    &-action {
      margin: 0 18/@px 0 0;

      & > li:last-child {
        padding-right: 0;
      }

      .dropdown-container {
        width: 83/@px;
      }
    }

    .ant-list &.ant-list-item--block {
      padding: 0;
      z-index: auto;

      &:hover,
      &:focus {
        background: @active-color;
      }

      &,
      &:last-child {
        border: 2px solid @muted-light-color;
        border-radius: @border-radius-base;
      }

      & + .ant-list-item--block {
        margin-top: 10/@px;
      }

      .ant-list-item-content {
        position: relative;
        width: 100%;
      }

      .ant-list-item-link {
        width: 100%;
        padding: 11/@px 11/@px 11/@px 18/@px;


        &,
        &:hover,
        &:focus,
        &:active {
          color: inherit;
          text-decoration: none;
          background: transparent;
        }
      }

      .dropdown-container.active {
        z-index: 9999;
      }

      .ant-list-item-meta-title {
        margin-bottom: 0;
      }
    }

    &-inactive {
      background: @muted-light-color;
    }

    &-active {
      background: @active-color;
    }
  }

  .ant-list-item {
    border: 0;
  }

  .ant-list-header {
    border: 0;
  }

  .list-panel-header + & {
    margin-top: 0.75rem;
  }

  &-empty-text {
    text-align: left;
    color: inherit;
  }
}
