.ant-popover {
  max-width: 20.125rem;
  color: @text-color-dark;
  min-width: 10.125rem;

  &-title {
    padding: 0.625rem 0.9375rem 0.3125rem;
    font-size: @font-size-h3;
    font-weight: bold;
    border-bottom: 0;
  }

  &-inner-content {
    padding: 15/@px;
    font-size: 15/@px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
  }

  .ant-menu {
    margin: -15/@px;
    overflow: hidden;
    font-size: inherit;
    border-radius: @border-radius-base;

    &.ant-menu-light {
      color: @text-color-dark;
    }

    &.ant-menu-vertical {
      border: 0;

      .ant-menu-item {
        margin: 0;
      }
    }

    &.ant-menu-pop-confirm {
      width: 162/@px;
    }

    &-item {
      padding: 0;
      margin: 0;
      height: auto;
      font-size: 0.889rem;
      line-height: 1.2;

      & > a,
      & > .ant-btn-link {
        display: block;
        margin: 0;
        padding: 0.75rem 0.625rem;
        width: 100%;
        text-align: left;
        border-radius: 0;
        color: @dropdown-menu-color;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }

        &:hover {
          background: @item-hover-bg;
        }
      }

      &-group-title {
        padding: 9/@px 14/@px;
        color: inherit;
        font-size: inherit;
        text-align: left;
      }

    }
  }

}
