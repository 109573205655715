body {
  font-weight: @font-weight-base;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: @heading-font-weight;
}

h1 {
  font-size: @font-size-h1;
}

h2 {
  font-size: @font-size-h2;
}

h3 {
  font-size: @font-size-base;
  text-transform: uppercase;
}

h4 {
  font-size: @font-size-h4;
}

h1, h2, h3, h4, h5, h6, ol, ul, p {

  &:last-child {
    margin-bottom: 0;
  }

}

.page-title {
  margin: 0;
  font-size: @font-size-h3;
  font-weight: @font-weight-base;
}

.copyright {
  font-size: @font-size-xs;
}

.disabled,
[disabled] {
  opacity: 0.3;
}

.error-message {
  font-size: @font-size-sm;
  &-text:not(:last-child) {
    margin-right: 0.25em;
  }
}
