.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;

  &:focus,
  &:hover,
  &:active {
    color: @primary-dark-color;
  }

  &:focus {
    box-shadow: 0 0 1px 2px @outline-color;
  }

  &:hover,
  &:active {
    background: @btn-primary-bg;
    border-color: @btn-primary-bg;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }

  &:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  }

  &[disabled] {
    opacity: 0.3;
  }

  // Button Group
  &-group {
    display: flex;
  }

  // Icons
  &-has-icon {
    display: inline-flex;
    align-items: center;
  }

  .icon {

    &:first-child {
      margin-right: 0.4167em;
    }

    &:last-child {
      margin-right: 0;

      &:not(:first-child) {
        margin-left: auto;
      }
    }

  }
}

// Button Sizes
.ant-btn-sm {
  font-size: @font-size-xs;
}

// Button Themes
.ant-btn-primary {

  &:focus {
    background: @btn-primary-bg;
  }

  &:hover,
  &:active {
    background: #fff;
    border-color: #fff;
  }

}

.ant-btn-dark {
  background: @btn-dark-bg;
  color: @btn-dark-color;
  border: 0;

  &:focus,
  &:hover,
  &:active {
    background: @btn-dark-bg;
    color: @btn-dark-color;
  }

}

.ant-btn-light,
.ant-btn-light-ghost,
.ant-btn-outline {

  &,
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border-width: 1px;
  }
}

.ant-btn-light,
.ant-btn-light-ghost {
  color: @btn-light-color;
  background: @btn-light-bg;

  &,
  &:hover,
  &:focus,
  &:active,
  &.active {
    box-shadow: none;
    border-color: @btn-light-border;
  }

  &:hover,
  &:focus {
    color: @btn-light-color;
    background: @active-color;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active {
      color: @btn-light-color;
      background: @btn-light-bg;
    }
  }
}

.ant-btn-light-ghost {
  background: transparent;

  &:hover,
  &:focus {
    color: #fff;
    background: @btn-light-color;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active {
      background: transparent;
    }
  }
}

.ant-btn-outline {
  color: @btn-outline-color;
  background: @btn-outline-bg;

  &,
  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: @btn-outline-border;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active {
      color: @btn-outline-color;
      background: @btn-outline-bg;
    }
  }

  &:hover,
  &:focus {
    color: @primary-dark-color;
    background: #fff;
  }
}

.ant-btn-progress {
  display: flex;
  justify-content: space-around;
  &.ant-btn-loading {
    flex-direction: row-reverse;
    & i {
      svg {
        width: 24/@px;
        height: 24/@px;
      }
    }
    &.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: 1rem;
      & i, & i + span {
        margin-left: 0;
      }
    }
  }
  .ant-progress-circle-trail {
    stroke: rgba(182, 218, 255, 0.25);
  }
}

.ant-btn-link,
.ant-btn-link-alt {
  font: inherit;

  &,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &[disabled] {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: inherit;

    &.ant-btn-sm {
      font-size: @font-size-xs;
    }
  }

}

.ant-btn-link {

  &:hover,
  &:focus,
  &:active,
  &.active {
    &:not([disabled]) {
      color: @active-color;
    }
  }

  &.ant-btn-background-ghost {
    border: 1px solid #fff;

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:not([disabled]) {
        color: @active-color;
        border-color: @active-color;
      }
    }
  }
}

.ant-btn-link-alt {

  &,
  &[disabled] {
    color: @active-color;
  }

  &:hover,
  &:focus,
  &:active {
    color: @text-color;
  }

  &.ant-btn-background-ghost {
    border: 1px solid @active-color;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      border-color: #fff;
    }
  }
}
