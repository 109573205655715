.form-title {
  margin-bottom: @content-margin-base;
  text-align: center;
}

.ant-form {

  label {
    display: block;
    font-size: @font-size-h3;
    font-weight: @font-weight-bold;
  }
  
  &-vertical {
    
    .ant-row {
      display: block;
    }
  }
}

.ant-form-item {
  margin-bottom: @content-margin-sm;
  color: inherit;

  &-control-wrapper {
    width: 100%;
  }

  &-label-hidden {
    & > .ant-form-item-label {
      .sr-only();
    }
  }
  
  &-control {
    &:before,
    &:after {
      display: none;
    }
  }
}

.form-actions {
  margin-top: @content-margin-base;
}

.@{input-class} {
  padding: @input-padding;

  // Placeholders
  &::-moz-placeholder {
    font-weight: @font-weight-base;
    font-style: italic;
  }
  // Internet Explorer 10+
  &:-ms-input-placeholder {
    font-weight: @font-weight-base;
    font-style: italic;
  }
  // Safari and Chrome
  &::-webkit-input-placeholder {
    font-weight: @font-weight-base;
    font-style: italic;
  }
}

.@{input-class}-affix-wrapper {

  &,
  .@{input-class}-prefix,
  .@{input-class}-suffix {
    color: inherit;
  }

  .@{input-class}-suffix {

    .ant-btn {

    }
  }
}

.ant-switch {

  &:after {
    top: 0.125rem;
    width: @switch-height - 0.375rem;
    height: @switch-height - 0.375rem;
  }

}

// Form Themes

.form-dark {
  color: @text-color-dark;



  .@{input-class} {
    color: @input-border-dark-color;
    border-color: @input-border-dark-color;

    &:hover,
    &:active,
    &:focus {
      border-color: #000;
    }
  }

  .@{input-class}-affix-wrapper {

    &:hover {
      .@{input-class}:not(.@{input-class}-disabled) {
        border-color: @input-border-dark-color;
      }
    }
  }
}
