// Rem Converter
.rem(@px) {
  @result: @px/16px * 1rem;
}

// Text Utilities
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.img-replace(@width, @height, @url) {
  .hide-text();
  width: @width/@px;
  height: @height/@px;
  background-image: url(@url);
  background-size: contain;
  background-repeat: no-repeat;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: block;
    margin: 0;
    padding: 0;
  }
}

// Layout

.container() {
  width: 90%;
  margin: 0 auto;
  padding-left: @content-margin-sm;
  padding-right: @content-margin-sm;
}

// Icons
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-outline(@width, @color, @blur) {
  text-shadow: -(@width) -(@width) @blur @color, @width -(@width) @blur @color, -(@width) @width @blur @color, @width @width @blur @color
}
