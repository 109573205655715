.ant-modal {
  color: @text-color-dark;

  form,
  &-header,
  &-body,
  &-footer {
    padding-left: @content-margin-base;
    padding-right: @content-margin-base;
  }

  &-header {
    padding-top: 11/@px;
    padding-bottom: 11/@px;
    color: inherit;
    text-align: center;
  }

  &-header,
  &-footer {
    border: 0;
  }

  &-title {
    font-weight: @font-weight-medium;
  }

  form,
  &-footer {
    padding-bottom: @content-margin-base;

    .ant-btn {
      min-width: 139/@px;
    }

  }

  &-body {
    padding-top: 24/@px;
    padding-bottom: 24/@px;

    form {
      margin: 0 -@content-margin-base -24/@px;



      .form-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 44/@px;

        .ant-btn {
          margin-right: 10/@px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

  }

  .ant-modal-close {
    top: 5/@px;
    right: 5/@px;
    color: @text-color-dark;

    &-x {
      .icon();
      .icon-close();
      width: 33/@px;
      height: 33/@px;
      padding: 5/@px;
    }

    &-icon {
      display: none;
    }

  }

}
