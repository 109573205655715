@font-face {
  font-family: '@{icomoon-font-family}';
  src:
          url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?ur8tdy') format('truetype'),
          url('@{icomoon-font-path}/@{icomoon-font-family}.woff?ur8tdy') format('woff'),
          url('@{icomoon-font-path}/@{icomoon-font-family}.svg?ur8tdy#@{icomoon-font-family}') format('svg');
  font-weight: @font-weight-base;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flip-icon {
  &:before {
    display: block;
    transform: rotate(180deg);
  }
}

.icon-minus-square {
  &:before {
    content: @icon-minus-square;
  }
}
.icon-plus-square {
  &:before {
    content: @icon-plus-square;
  }
}
.icon-arrow-thin {
  &:before {
    content: @icon-arrow-thin;
  }
}
.icon-fullscreen {
  &:before {
    content: @icon-fullscreen;
  }
}
.icon-plus-circle {
  &:before {
    content: @icon-plus;
  }
}
.icon-grid-2 {
  &:before {
    content: @icon-grid-2;
  }
}
.icon-grid-4 {
  &:before {
    content: @icon-grid-4;
  }
}
.icon-grid-6 {
  &:before {
    content: @icon-grid-6;
  }
}
.icon-camera {
  &:before {
    content: @icon-camera;
  }
}
.icon-exit {
  &:before {
    content: @icon-exit;
  }
}
.icon-movement-controls {
  &:before {
    content: @icon-movement-controls;
  }
}
.icon-play {
  &:before {
    content: @icon-play;
  }
}
.icon-live {
  &:before {
    content: @icon-live;
  }
}
.icon-next {
  &:before {
    content: @icon-next;
  }
}
.icon-pause {
  &:before {
    content: @icon-pause;
  }
}
.icon-skip {
  &:before {
    content: @icon-skip;
  }
}
.icon-arrow {
  &:before {
    content: @icon-arrow;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}

// Modified icons
.icon-reverse {
  .icon-play();
  .flip-icon();
}

.icon-prev {
  .icon-next();
  .flip-icon();
}

.icon-skip-back {
  .icon-skip();
  .flip-icon();
}

.icon-close {
  .icon-plus-circle();

  &:before {
    display: block;
    transform: rotate(45deg);
  }
}

.icon-plus {
  .icon-exit();

  &:before {
    display: block;
    transform: rotate(45deg);
  }
}

.icon-up-arrow,
.icon-down-arrow,
.icon-left-arrow,
.icon-right-arrow {
  .icon-arrow();

  &:before {
    display: block;
  }
}

.icon-up-arrow-thin,
.icon-down-arrow-thin,
.icon-left-arrow-thin,
.icon-right-arrow-thin {
  .icon-arrow-thin();

  &:before {
    display: block;
  }
}

.icon-up-arrow,
.icon-up-arrow-thin {
  &:before {
    transform: rotate(-90deg);
  }
}

.icon-down-arrow,
.icon-down-arrow-thin {
  &:before {
    transform: rotate(90deg);
  }
}

.icon-left-arrow,
.icon-left-arrow-thin {
  .flip-icon();
}
