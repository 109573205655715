.entity-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 410/@px;
  margin: 0 auto;
}

.views-page, .investigation-page {
  & #entity-save button > .btn-text:after {
    content: "*";
    color: inherit;
    font-size: 1em;
    display: inline-block;
    margin-left: 0.25rem;
    transform: scale(0);
    transform-origin: 30% center;
    transition: transform 150ms ease;
  }
  & > [data-modified="true"] #entity-save button > .btn-text:after {
    transform: scale(1);
  }
}

.ant-btn-progress.entity-saving {
  opacity: 1;
  &, &:hover {
    border-color: rgba(255, 255, 255, 0.3)
  }
}
