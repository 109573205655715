.page--home {

  .logo-company {
    margin: 0 auto @content-margin-sm;
    height: 3.875rem;
    flex: 0 1 3.875rem;
    min-height: 3.875rem;
  }

  .page-title {
    margin-bottom: @content-margin-base;
    font-size: @font-size-h1;
    text-align: center;
  }

  .site-layout > .ant-layout-content .ant-layout-content {
    max-height: 100vh;
  }
  .ant-layout-content {
    background-color: @body-background;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/loginbackgroundimage.png) 50% 50% no-repeat;
      background-size: cover;
      opacity: 0.3;
    }

    .main-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      max-height: 100vh;
      margin-top: 1rem;
      position: relative;
    }

    .promotions {
      margin: 0 auto;
    }
  }

  .ant-layout-sider {

    &-children {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
    }

    .form-container {
      margin-top: @content-margin-base;
      margin-bottom: @content-margin-base;
    }

    .sider-main {
      flex: 1 0 auto;
      width: 100%;
      max-width: 230/@px;
      margin: 7.5rem auto 0;
    }

    .sider-footer {
      margin-top: auto;
      flex-shrink: 0;
      padding: @content-margin-base 18/@px;
      text-align: center;
    }
  }

}

.login-sider {
  width: 25% !important;
  max-width: 30rem !important;
  min-width: 18rem !important;
  flex: 1 1 25% !important;
}

.error-modal .ant-modal-body {
  text-align: center;
}
