.camera-search {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 1px;

  &-scrolling-spinner {
    position: absolute;
  }

  &-items {

    &-content {
      margin-top: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &,
    .ant-list {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-height: 1px;
    }

    .ant-list-header {
      flex-shrink: 0;
    }

    .ant-spin-nested-loading {
      display: flex;
      flex: 1 1 auto;
      min-height: 1px;
    }

    .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .ant-list-item {
      width: 50%;

      &:nth-child(even) {
        padding-left: 5/@px;
      }
      &:nth-child(odd) {
        padding-right: 5/@px;
      }

      &:nth-child(2) {
        margin-top: 0;
      }
    }
    .ant-layout-sider .ant-collapse-item-active .ant-collapse-content-box & .ant-list-item-content-single, .ant-list-item-meta {
      flex: 1;
    }

  }

  &-row {
    padding-bottom: 10/@px;

    .ant-spin-container {
      width: @search-input-width;
    }

    .ant-list-item {
      margin-bottom: 0;
    }
  }

  .camera {
    position: relative;
    width: calc(~"50% - 0.5rem");
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    .camera-content {

      .btn-text {
        margin-top: @camera-header-height;
      }

    }

    &--active {
      background-color: rgba(0,0,0,0.375);
    }
    &:not(.camera--active) {
      &:hover,
      &:focus {

        .camera-content {
          opacity: 1;
        }
      }

      .camera-content {
        opacity: 0;
        transition: opacity 0.2s;
      }

    }
  }

}
