.ant-spin {

  .ant-spin-dot i {
    background-color: @muted-color;
  }

  &-theme-dark {

    .ant-spin-dot i {
      background-color: #fff;
    }

  }
}

.ant-spin-nested-loading > div > .ant-spin {

  &-theme-dark {

    .ant-spin-text {
      color: #fff;
      text-shadow: none;
    }

  }
}
